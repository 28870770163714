import React, { Component } from 'react';
import { getAccountFromLocalStorage, AccountResponse } from './helpers';
import { accountsApi } from '../../../utils/accounts-api';
import Modal, { STitle } from '../../../lib/modal/Modal';
import AccountForm from '../../../lib/modal/AccountForm';
import MessagesContext from '../../../utils/MessagesContext';
import { getWindowMainUrl } from '../../../utils/user-api';
import RemoveModalButton from './RemoveModalButton';

interface State {
  userAccount?: AccountResponse;
  accounts?: AccountResponse[];
  createAccountModalToggle?: boolean;
}

export default class AccountsTab extends Component<unknown, State> {
  static contextType = MessagesContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const accountResponse = getAccountFromLocalStorage();

    if (accountResponse) {
      this.setState({ userAccount: accountResponse });
      this.getCompanyAccounts(accountResponse.company.id);
    }
  }

  getCompanyAccounts = (companyId) => {
    accountsApi.getCompanyAccounts(companyId).then((accountsResponse) => {
      this.setState({ accounts: accountsResponse });
    });
  };

  removeUserAccount = (companyId, accountId) => {
    accountsApi
      .deleteUserAccount(companyId, accountId)
      .then(() => {
        this.context.setmessage('success', 'account removed successfully');
      })
      .catch(this.errorMessage);
  };

  onCreateAccountFormSubmit = (userAccount) => {
    const hostUrl = getWindowMainUrl();
    if (this.state.userAccount) {
      accountsApi
        .createCompanyUserAccount(this.state.userAccount.company.id, {
          ...userAccount,
          host: hostUrl,
        })
        .then((accountResponse) => {
          this.context.setmessage('success', 'account created successfully');
          this.getCompanyAccounts(accountResponse.company.id);
          this.toggleAccountModalModal();
        })
        .catch(this.errorMessage);
    } else {
      this.context.setmessage('error', 'error');
    }
  };

  // good error handler
  errorMessage = ({ response }) => {
    if (!response.data) {
      this.context.setmessage('error', 'server error');
    }
    if (response.data.errors) {
      for (const error of Object.values(response.data.errors)) {
        this.context.setmessage('error', error);
      }
    } else if (response.data.message) {
      this.context.setmessage('error', response.data.message);
    }
  };

  toggleAccountModalModal = () => {
    const toggle = this.state.createAccountModalToggle;
    this.setState({ createAccountModalToggle: !toggle });
  };

  render() {
    return (
      <div style={{ marginTop: '16px', padding: '15px' }}>
        <div className="d-flex justify-content-between">
          <h5 style={{ marginBottom: '16px' }}>Accounts</h5>
          <div>
            <button
              onClick={this.toggleAccountModalModal}
              type="button"
              className="btn btn-primary"
            >
              Create user
            </button>

            <Modal
              isVisible={this.state.createAccountModalToggle}
              hideModal={this.toggleAccountModalModal}
            >
              <STitle>Create user</STitle>
              <AccountForm
                onSubmit={this.onCreateAccountFormSubmit}
                hideModal={this.toggleAccountModalModal}
              ></AccountForm>
            </Modal>
          </div>
        </div>

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">First</th>
              <th scope="col">Last</th>
              <th scope="col">Email</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.accounts?.map((account, i) => (
              <tr key={i}>
                <th scope="row">1</th>
                <td>{account.user.first_name}</td>
                <td>{account.user.last_name}</td>
                <td>{account.user.email}</td>
                <td className="d-flex flex-row-reverse">
                  <RemoveModalButton
                    userNames={`${account.user.first_name} ${account.user.last_name}`}
                    accountId={account.id}
                    account={account}
                    removeUserAccount={this.removeUserAccount}
                  >
                    Remove
                  </RemoveModalButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
