import React, { ReactElement } from 'react';
import { PaginationResponse } from '../pages/assetPage/HousesTable';

interface Props {
  goToNext: (nextPage: number) => void;
  goToPrev: (prevPage: number) => void;
  changePageSize: (value: string) => void;

  pagination?: PaginationResponse;
}

export default function SimplePagination(props: Props): ReactElement {
  const nextPage = props.pagination?.nextPage || null;
  const prevPage = props.pagination?.prevPage || null;

  return (
    <nav aria-label="Page" className="d-flex">
      <div
        style={{
          marginLeft: 'auto',
          marginBottom: '1rem',
          marginRight: '1rem',
        }}
        className="d-flex form-group"
      >
        <select
          onChange={(event) => props.changePageSize(event.target.value)}
          className="align-self-end"
          value={props.pagination?.size}
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      <ul className="pagination align-self-end">
        {prevPage ? (
          <>
            <li
              onClick={() => props.goToPrev(prevPage)}
              style={{ padding: '0' }}
              className="page-item"
            >
              <div className="page-link">Previous</div>
            </li>
            <li
              onClick={() => props.goToPrev(prevPage)}
              style={{ padding: '0' }}
              className="page-item"
            >
              <div className="page-link">{props?.pagination?.prevPage}</div>
            </li>
          </>
        ) : null}
        <li style={{ padding: '0' }} className="page-item">
          <div className="page-link">{props?.pagination?.page}</div>
        </li>

        {nextPage ? (
          <>
            <li
              onClick={() => props.goToNext(nextPage)}
              style={{ padding: '0' }}
              className="page-item"
            >
              <span className="page-link">{props?.pagination?.nextPage}</span>
            </li>

            <li
              onClick={() => props.goToNext(nextPage)}
              style={{ padding: '0' }}
              className="page-item"
            >
              <div className="page-link">Next</div>
            </li>
          </>
        ) : null}
      </ul>
    </nav>
  );
}
