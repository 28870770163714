import React, { Component } from 'react';

export type MessageContent = {
  messages: any[];
  setmessage: (c: string) => void;
};

const MessagesContext = React.createContext<MessageContent | any>({});

class MessagesProvider extends Component {
  state = {
    messages: [],
  };

  setmessage = (type, message) => {
    this.setState((prevState: any) => {
      return {
        messages: [
          ...prevState.messages,
          {
            id: Math.floor(Math.random() * 20000 + 1),
            type: type,
            text: message,
          },
        ],
      };
    });
  };

  deleteMessage = (id) => {
    this.setState((prevState: any) => {
      return {
        messages: prevState.messages.filter((message) => message.id !== id),
      };
    });
  };

  render() {
    const { children } = this.props;
    const { messages } = this.state;
    const { setmessage, deleteMessage } = this;

    return (
      <MessagesContext.Provider
        value={{
          messages,
          setmessage,
          deleteMessage,
        }}
      >
        {children}
      </MessagesContext.Provider>
    );
  }
}

export default MessagesContext;

export { MessagesProvider };
