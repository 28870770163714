import React, { Component } from 'react';
import { UserRequest } from '../dashboard/pages/accountPage/helpers';

interface Props {
  formChanges: (formData: any) => void;
  userRequest?: UserRequest;
}

interface State {
  email?: string;
  password?: string;
  repeatPassword?: string;
  firstName?: string;
  lastName?: string;
}

export default class RegisterForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const form = event.target.name;
    const state = this.state;
    state[form] = event.target.value;

    this.setState(state);
    this.props.formChanges(state);
  }

  render() {
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="firstName">First Name</label>
            <input
              value={this.props.userRequest?.firstName}
              name="firstName"
              onChange={this.handleChange}
              type="text"
              className="form-control"
              id="firstName"
              placeholder="First Name"
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="lastName">Last Name</label>
            <input
              value={this.props.userRequest?.lastName}
              name="lastName"
              onChange={this.handleChange}
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Last Name"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            value={this.props.userRequest?.email}
            name="email"
            onChange={this.handleChange}
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter email"
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Password</label>
          <input
            value={this.props.userRequest?.password}
            name="password"
            onChange={this.handleChange}
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Password"
          />
        </div>
        <div className="form-group">
          <label htmlFor="repeatPassword">Repeat Password</label>
          <input
            value={this.props.userRequest?.repeatPassword}
            name="repeatPassword"
            onChange={this.handleChange}
            type="password"
            className="form-control"
            id="repeatPassword"
            placeholder="Password"
          />
        </div>
      </div>
    );
  }
}
