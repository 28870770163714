import React, { Component } from 'react';
import Alert from './Alert';
import MessagesContext from '../../../utils/MessagesContext';

export default class AlertContainer extends Component {
  static contextType = MessagesContext;

  render() {
    return (
      <div className="alert-wrapper">
        {this.context.messages?.map((message, i) => {
          return (
            <Alert
              key={i}
              deleteMessage={(id) => this.context.deleteMessage(id)}
              message={message}
            />
          );
        })}
      </div>
    );
  }
}
