import * as H from 'history'

import { ContractResponseV2, PaginationPage } from '../accountPage/helpers'
import { Link, useHistory } from 'react-router-dom'
import React, { ReactElement, useEffect, useState } from 'react'
import { StyledBreadcrumb, StyledPageNavBetween } from '../../../lib/styled'

import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'
import HouseContractTable from './HouseContractTable'
import { HouseResponse } from '../accountPage/helpers'
import PageSection from '../../../lib/dashboard-page/PageSection'
import { assetsApi } from '../../../utils/assets-api'
import { convertSearchToRouteParams } from '../accountPage/helpers'
import { PaginationUtil } from '../../shared/utils/PaginationUtil'

interface HouseContractsPageProps {
  match: any
}

export default function HouseContractsPage(props: HouseContractsPageProps): ReactElement {
  const [house, setHouse] = useState<HouseResponse>()
  const [contracts, setContracts] = useState<PaginationPage<ContractResponseV2>>()
  const history: H.History = useHistory()
  const paginationUtil = new PaginationUtil(history)

  useEffect(() => {
    fetchAllContracts(convertSearchToRouteParams(history.location.search))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search])

  useEffect(() => {
    findAssetHouse(JSON.parse(props.match.params.assetId), JSON.parse(props.match.params.houseId))
  }, [props.match.params.assetId, props.match.params.houseId])

  const findAssetHouse = (asset_id, house_id) => {
    assetsApi.findAssetHouse(asset_id, house_id).then((houseResponse: HouseResponse) => {
      setHouse(houseResponse)
    })
  }
  const fetchAllContracts = (paginationParams = {}) => {
    assetsApi.fetchAllContracts(paginationParams, props.match.params.houseId).then((contractResponse) => {
    // assetsApi.fetchHouseContracts(paginationParams, props.match.params.houseId, props.match.params.assetId).then((contractResponse) => {
      console.log('fetchAllContracts', contractResponse)
      setContracts(contractResponse)
    })
  }

  return (
    <DashboardPage2 hideTitle>
      <StyledPageNavBetween>
        <StyledBreadcrumb>
          <BreadcrumbItem url={'/assets'} title={'assets'} />
          <BreadcrumbItem url={`/asset/${JSON.parse(props.match.params.assetId)}`} title={house?.asset?.name || ''} />
          <BreadcrumbItem active title={house?.house_number || ''} />
        </StyledBreadcrumb>
        <Link
          data-cy="edit-house"
          className="btn btn-link"
          to={`/houses/${props.match.params.assetId}/${JSON.parse(props.match.params.houseId)}/edit`}
        >
          Edit house
        </Link>
      </StyledPageNavBetween>

      <PageSection>
        <HouseContractTable contracts={contracts} />
      </PageSection>
    </DashboardPage2>
  )
}
