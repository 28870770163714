import React, { Component } from 'react';
import { FormControl, Button } from 'react-bootstrap';
import { AssetResponse } from '../accountPage/helpers';

interface Props {
  onSubmit: (assetRequest: any) => void;
  asset?: AssetResponse;
  minDate?: Date;
}

interface State {
  name?: string;
  location?: string;
}

class AssetForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      location: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.asset) {
      this.setState({
        name: this.props.asset.name,
        location: this.props.asset.location,
      });
    }
  }

  handleChange(event) {
    const form = event.target.name;
    const state = {};
    state[form] = event.target.value;
    this.setState(state);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmit(this.state);
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>Name</label>
        <FormControl
          value={this.state.name}
          name="name"
          onChange={this.handleChange}
          type="text"
          data-cy="name"
        />

        <label>Location</label>
        <FormControl
          value={this.state.location}
          name="location"
          onChange={this.handleChange}
          type="text"
          data-cy="location"
        />

        <div className="shika-small-padding">
          <Button type="submit" data-cy="create-asset" variant="primary">
            {' '}
            Submit{' '}
          </Button>
        </div>
      </form>
    );
  }
}

export default AssetForm;
