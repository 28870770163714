import React, { Component } from 'react';
import RouteWithSubRoutes from '../RouteWithSubRoutes';
import './main.css';
import { Switch, withRouter } from 'react-router-dom';
import {
  getDashboardRoutes,
  CompanySummaryResponse,
} from './pages/accountPage/helpers';
import {accountsApi} from "../utils/accounts-api";

interface Props {
  history: any;
  location;
  match;
  routes: any[];
  companySummary?: CompanySummaryResponse;
}

interface State {
  user?: any;
  dashboardRoutes: any[];
}

class Main extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {dashboardRoutes: []};
    this.goHome = this.goHome.bind(this);
  }

  componentDidMount() {
    if (this.props.location.pathname === '/') {
      this.goHome();
    }



  }

  goHome() {
    this.props.history.push(this.props.routes[0].path);
  }

  render() {
    return (
      <Switch>
        {this.props.routes.map((route, i) => (
          // todo improve RouteWithSubRoutes
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    );
  }
}

export default withRouter(Main);
