import React, { Component } from 'react'
import { Page, Document, StyleSheet } from '@react-pdf/renderer'
import PdfItem from './PdfItem'

interface Props {
  receipts?: any[]
}

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px',
  },
})

class PdfDocument extends Component<Props> {
  render() {
    const { receipts } = this.props
    return (
      <Document>
        {receipts?.map((receiptsPage, key) => (
          <Page style={styles.page} size="A4" key={key}>
            {receiptsPage?.map((item, index) => (
              <PdfItem receipt={item} key={index} />
            ))}
          </Page>
        ))}
      </Document>
    )
  }
}

export default PdfDocument
