import React, { Component } from 'react'
import { accountsApi, ChangePasswordRequest } from '../../../utils/accounts-api'
import MessagesContext from '../../../utils/MessagesContext'
import KeyValueContainer, { KeyValueItem } from '../../../lib/KeyValuePair/KeyValueContainer'
import { ChangePasswordModal } from '../../shared/ConfirmationModal'
import { CompanySummaryResponse, getUserFromLocalStorage } from './helpers'
import { getCompanyDetailsFromLocalStorage } from './helpers'

interface State {
  user?: any
  company?: any
  companySummary?: CompanySummaryResponse
  showChangePasswordModal?: boolean
}

export default class ProfileTab extends Component<unknown, State> {
  static contextType = MessagesContext
  constructor(props) {
    super(props)
    this.state = {}

    this.onCloseConfirmationModal = this.onCloseConfirmationModal.bind(this)
  }

  componentDidMount() {
    const userResponse = getUserFromLocalStorage()
    const companyResponse = getCompanyDetailsFromLocalStorage()
    this.setState({ user: userResponse, company: companyResponse })

    accountsApi.getCompanySummary().then((companySummaryResponse) => {
      this.setState({ companySummary: companySummaryResponse })
    })
  }

  onCloseConfirmationModal(changePasswordRequest: ChangePasswordRequest) {
    if (changePasswordRequest) {
      if (changePasswordRequest.newPassword && changePasswordRequest.newPassword.length < 5) {
        this.context.setmessage('info', 'weak password')
        return
      }
      accountsApi
        .changePassword(changePasswordRequest)
        .then((changeResponse) => {
          this.context.setmessage('success', `Password changed successfully`)
          this.setState({ showChangePasswordModal: false })
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            for (const error of Object.values(response.data.errors)) {
              this.context.setmessage('error', error)
            }
          } else if (response.data.message) {
            this.context.setmessage('error', response.data.message)
          }
        })
    } else {
      this.setState({ showChangePasswordModal: false })
    }
  }

  render() {
    const changePasswordModal = this.state.showChangePasswordModal ? (
      <ChangePasswordModal onCloseConfirmationModal={this.onCloseConfirmationModal} />
    ) : undefined
    return (
      <div className="d-flex" style={{ marginTop: '16px' }}>
        {changePasswordModal}
        <KeyValueContainer
          title={'Details'}
          rightElement={
            <button
              onClick={() => this.setState({ showChangePasswordModal: true })}
              style={{ padding: '0' }}
              type="button"
              className="btn btn-link"
              data-cy="change-password"
            >
              change password
            </button>
          }
        >
          <KeyValueItem keyName="First name" valueName={this.state.user?.first_name} />
          <KeyValueItem keyName="Last name" valueName={this.state.user?.last_name} />
          <KeyValueItem keyName="Username" valueName={this.state.user?.username} />
          <KeyValueItem keyName="Email" valueName={this.state.user?.email} />
        </KeyValueContainer>

        <KeyValueContainer title={'Package'}>
          <KeyValueItem
            keyName="Houses"
            valueName={`${this.state.companySummary?.summary?.houses_count}/ ${this.state.companySummary?.companyPackage?.package?.max_houses}`}
          />
          <KeyValueItem
            keyName="Accounts"
            valueName={`${this.state.companySummary?.summary?.accounts_count} /${this.state.companySummary?.companyPackage?.package.max_accounts}`}
          />
          <KeyValueItem
            keyName="Sms quota"
            valueName={`${this.state.companySummary?.summary?.utilised_sms} /${this.state.companySummary?.companyPackage?.package.sms_quota}`}
          />
          <KeyValueItem keyName="Expiry date" valueName={this.state.companySummary?.companyPackage?.expiry_date} />
        </KeyValueContainer>
      </div>
    )
  }
}
