import HouseDetailPage from './pages/housesPage/HouseDetailPage'
import CreateAssetPage from './pages/assetPage/create-asset-page/CreateAssetPage'
import AssetContainer from './pages/assetPage/AssetContainer'
import AccountPage from './pages/accountPage/AccountPage'
import ProfileTab from './pages/accountPage/ProfileTab'
import AccountsTab from './pages/accountPage/AccountsTab'
import EditAssetPage from './pages/assetPage/edit-asset-page/EditAssetPage'
import CreateHousePage from './pages/housesPage/CreateHousePage'
import CompanyDetailPage from './pages/support/CompanyPage/CompanyDetailPage'
import CompanyContainer from './pages/support/CompanyPage/CompanyContainer'
import TokenContainer from './pages/support/TokenPage/TokenContainer'
import TokensPage from './pages/support/TokenPage/PackageTokenListPage'
import PackageContainer from './pages/support/packagePage/PackageContainer'
import PackageListPage from './pages/support/packagePage/PackageListPage'
import CompanyListPage from './pages/support/CompanyPage/CompanyListPage'
import PackageDetailPage from './pages/support/packagePage/PackageDetailPage'
import HouseListPage from './pages/housesPage/HouseListPage'
import HouseContainer from './pages/housesPage/HouseContainer'
import EditHousePage from './pages/housesPage/EditHousePage'
import CreatePaymentPage from './pages/housesPage/CreatePaymentPage'
import ReportsPage from './pages/reportsPage/ReportsPage'
import AssetDetail from './pages/assetPage/AssetDetail'
import AssetHousesTabPage from './pages/assetPage/AssetHousesTabPage'
import AssetReceiptTabsPage from './pages/assetPage/AssetReceiptTabsPage'
import AssetsPage from './pages/assetPage/AssetsPage'
import { FaBookOpen, FaBuilding, FaDoorClosed, FaFileInvoice, FaWhmcs } from 'react-icons/all'
import ExpensesPage from './pages/expensesMod/ExpensesPage'
import OverviewPage from './pages/overviewMod/OverviewPage'
import AssetExpensesTabsPage from './pages/assetPage/AssetExpensesTabsPage'
import HouseContractsPage from './pages/housesPage/HouseContractsPage'
import InvoicesPage from './pages/invoices/InvoicesPage'
import CreateInvoicePage from './pages/invoices/CreateInvoicePage'
import InvoicesContainer from './pages/invoices/InvoicesContainer'
import InvoiceDetailPage from './pages/invoices/InvoiceDetailPage'
import MissingInvoicesPage from './pages/invoices/MissingInvoicesPage'
import UploadInstancePage from './pages/uploadInstancesPage/UploadInstancePage'
import CreateUploadInstancePage from './pages/uploadInstancesPage/CreateUploadInstancePage'
import UploadInstanceDetailPage from './pages/uploadInstancesPage/UploadInstanceDetailPage'
import {AllFeatureFlags} from "./dashboard-constants";
import CreateExpensePage from './pages/expensesMod/CreateExpensePage'
import ExpenseDetailPage from './pages/expensesMod/ExpenseDetailPage'

export interface CustomRoute {
  path: string
  component: any
  sidebarName?: string
  subRouteName?: string
  icon?: string
  routes?: CustomRoute[]
  adminRequired?: boolean
  requiredFeatureFlags?: any[]
  IconComponent?: any
}

const dashboardRoutes: CustomRoute[] = [
  {
    path: '/overview',
    component: OverviewPage, // todo
    sidebarName: 'Overview',
    IconComponent: FaBuilding,
    routes: [],
  },
  {
    path: '/assets',
    component: AssetContainer, // Asset
    sidebarName: 'Assets',
    IconComponent: FaBuilding,
    routes: [
      {
        path: '/assets',
        component: AssetsPage,
      },
      {
        path: '/assets/create',
        component: CreateAssetPage,
        subRouteName: 'Create Asset',
      },
    ],
  },
  {
    path: '/houses',
    component: HouseContainer,
    sidebarName: 'Houses',
    IconComponent: FaDoorClosed,
    routes: [
      {
        path: '/houses/create',
        component: CreateHousePage,
        subRouteName: 'Create House',
      },
      {
        path: '/houses',
        component: HouseListPage,
      },
      {
        path: '/houses/:assetId/:houseId/edit',
        component: EditHousePage,
      },
      {
        path: '/houses/:assetId/:houseId/:contractId/create-payment',
        component: CreatePaymentPage,
      },
      {
        path: '/houses/:assetId/:houseId/:contractId',
        component: HouseDetailPage,
      },
      {
        path: '/houses/:assetId/:houseId',
        component: HouseContractsPage,
      },
    ],
  },
  {
    path: '/expenses',
    component: HouseContainer, // todo
    sidebarName: 'Expenses',
    IconComponent: FaBookOpen,
    requiredFeatureFlags: [AllFeatureFlags.Expense],
    routes: [
      {
        path: '/expenses',
        component: ExpensesPage,
      },
      {
        path: '/expenses/create',
        component: CreateExpensePage,
        subRouteName: 'Create Expense',
      },
      {
        path: '/expenses/:assetId/:expenseId',
        component: ExpenseDetailPage,
      },
    ],
  },
  {
    path: '/upload-instances',
    component: InvoicesContainer, // todo
    sidebarName: 'upload-instances',
    IconComponent: FaFileInvoice,
    requiredFeatureFlags: [AllFeatureFlags.Expense],
    routes: [
      {
        path: '/upload-instances',
        component: UploadInstancePage,
      },
      {
        path: '/upload-instances/create',
        component: CreateUploadInstancePage,
      },
      {
        path: '/upload-instances/:id',
        component: UploadInstanceDetailPage,
      },
    ],
  },
  {
    path: '/invoices',
    component: InvoicesContainer, // todo
    sidebarName: 'Invoices',
    IconComponent: FaFileInvoice,
    routes: [
      {
        path: '/invoices',
        component: InvoicesPage,
      },
      {
        path: '/invoices/create',
        component: CreateInvoicePage,
        subRouteName: 'Create Invoice',
      },
      {
        path: '/invoices/missing-invoices',
        component: MissingInvoicesPage,
      },
      {
        path: '/invoices/:id',
        component: InvoiceDetailPage,
      },
    ],
  },
  {
    path: '/asset/:id/edit',
    component: EditAssetPage,
  },
  {
    path: '/asset/:id',
    component: AssetDetail,
    routes: [
      {
        path: '/asset/:id/houses',
        component: AssetHousesTabPage,
      },
      {
        path: '/asset/:id/expenses',
        component: AssetExpensesTabsPage,
      },
      {
        path: '/asset/:id/receipts',
        component: AssetReceiptTabsPage,
      },
    ],
  },
  {
    path: '/reports',
    component: ReportsPage,
    sidebarName: 'Reports',
    IconComponent: FaFileInvoice,
  },
  {
    path: '/account',
    component: AccountPage,
    sidebarName: 'Account',
    IconComponent: FaWhmcs,
    routes: [
      {
        path: '/account/profile',
        component: ProfileTab,
      },
      {
        path: '/account/company/accounts',
        component: AccountsTab,
        adminRequired: true,
      },
    ],
  },
]

export const supportRoutes = [
  {
    path: '/companies',
    component: CompanyContainer,
    sidebarName: 'Cients',
    routes: [
      {
        path: '/companies',
        component: CompanyListPage,
      },
      {
        path: '/companies/:id',
        component: CompanyDetailPage,
      },
    ],
  },
  {
    path: '/tokens',
    component: TokenContainer,
    sidebarName: 'Tokens',
    routes: [
      {
        path: '/tokens',
        component: TokensPage,
      },
    ],
  },
  {
    path: '/packages',
    component: PackageContainer,
    sidebarName: 'Packages',
    routes: [
      {
        path: '/packages',
        component: PackageListPage,
      },
      {
        path: '/packages/:id',
        component: PackageDetailPage,
      },
    ],
  },
]

export default dashboardRoutes
