const windowsHostname = window.location.hostname

const develop = {
  BACKEND_SERVICE: 'https://pro-assets.herokuapp.com/',
}

const demo1 = {
  BACKEND_SERVICE: 'https://demo1.proassets.co.ke/api/',
}

const test = {
  BACKEND_SERVICE: 'https://test.agent.proassetsmgt.com/api/',
}

const local = {
  BACKEND_SERVICE: 'http://localhost:5000/',
}

const cluster = {
  BACKEND_SERVICE: 'api/',
}

const linodeCluster = {
  BACKEND_SERVICE: `http://${windowsHostname}:5000`,
}

const prod = {
  BACKEND_SERVICE: 'https://pro-assets.el.r.appspot.com/',
}

const choose = {
  develop: develop,
  local: local,
  cluster: cluster,
  prod: prod,
  linodeCluster: linodeCluster,
  test: test,
  demo1: demo1,
}

const config = process.env.REACT_APP_STAGE ? choose[process.env.REACT_APP_STAGE] : choose['cluster']

export default config

export function hasHostPort() {
  // todo check if localhost
  const hostPort = window.location.port
  return hostPort && !isNaN(hostPort)
}
