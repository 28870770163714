import { AxiosResponse, AxiosInstance } from 'axios'
import { Api } from './api'
import { baseAxios } from './api.config'

export class UserApi extends Api {
  public constructor(baseAxios?: AxiosInstance) {
    super(baseAxios)
    this.userLogin = this.userLogin.bind(this)
  }

  public userLogin(credentials: any): Promise<any> {
    return this.post<string, any, AxiosResponse<string>>('auth/get-user', credentials)
      .then(this.success)
      .catch(this.error)
  }

  public resetPassword(credentials: { password: string }, token): Promise<any> {
    this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return this.put<string, any, AxiosResponse<string>>('auth/forgot-password', credentials)
      .then(this.success)
      .catch(this.error)
  }

  public confirmUser(token): Promise<any> {
    this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return this.post<string, any, AxiosResponse<string>>('auth/confirm-user').then(this.success).catch(this.error)
  }

  public forgotPassword(payload: { email: string; host: string }): Promise<any> {
    return this.post<string, any, AxiosResponse<string>>('auth/forgot-password', payload)
      .then(this.success)
      .catch(this.error)
  }

  public registerUser(registerRequest: any): Promise<any> {
    return this.post<string, any, AxiosResponse<string>>('auth/signup', registerRequest)
      .then(this.success)
      .catch(this.error)
  }

  public accountLogin(formData) {
    return this.post('auth/login', formData).then(this.success).catch(this.error)
  }
}

export const userApi = new UserApi(baseAxios)

export const getWindowMainUrl = (): string | null => {
  let mainUrl
  if (window.location.href.split('://').length === 2) {
    const httpPrefix = window.location.href.split('://')[0]
    const rawUrl = window.location.href.split('://')[1]
    mainUrl = `${httpPrefix}://${rawUrl.split('/')[0]}`
  }

  return mainUrl
}
