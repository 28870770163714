import * as H from 'history'

import { PaginationUtil } from '../../shared/utils/PaginationUtil'
import React from 'react'
import { SECTION_PADDING } from '../../../lib/Table/cssVariables'
import TrWithLink from '../../../lib/Table/TrWithLink'
import { range } from '../overview/utils'
import { useHistory } from 'react-router-dom'

function HouseContractTable({ contracts }): React.ReactElement {
  const history: H.History = useHistory()
  const paginationUtil = new PaginationUtil(history)

  const updatePaginationParams = (paginationParams = {}) => {
    paginationUtil.updatePaginationParams(paginationParams)
  }

  const handlePageSizeChange = (event) => {
    paginationUtil.updatePaginationParams({
      size: parseInt(event.target.value),
    })
  }

  return (
    <div>
      <div
        style={{
          padding: SECTION_PADDING,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="d-flex justify-content-between"
      >
        <div style={{ display: 'flex' }}>
          <div>Show</div>
          <select
            value={contracts?.pagination.size}
            onChange={handlePageSizeChange}
            style={{ width: '80px', margin: '0 10px' }}
            className="custom-select custom-select-sm"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <div>records per page</div>
        </div>
        <div>
          <input type="text" className="form-control form-control-sm" />
        </div>
      </div>
      <table className="table thead-dark table-sm ">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Status</th>
            <th scope="col">tenant</th>
            <th scope="col">Contract amount</th>
            <th scope="col">House Number</th>
          </tr>
        </thead>
        <tbody>
          {contracts?.items?.map((contract, i) => (
            <TrWithLink key={i} link={`${contract.house.id}/${contract.id}`}>
              <th>{i + 1}</th>
              <td>{new Date(contract.start_date).toDateString()}</td>
              <td>{contract.end_date ? new Date(contract.end_date).toDateString() : null}</td>
              <td>{contract.status}</td>
              <td>{contract.tenant.full_names}</td>
              <td>{contract.amount}</td>
              <td>{contract.house.house_number}</td>
            </TrWithLink>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Showing 1 to {contracts?.pagination.size} of {contracts?.pagination.totalElements} entries
        </div>
        <ul className="pagination justify-content-end">
          <li className={`page-item ${!contracts?.pagination.hasPrev ? 'disabled' : ''}`}>
            <button
              className="page-link "
              onClick={() =>
                updatePaginationParams({
                  page: contracts?.pagination.prevPage,
                })
              }
            >
              Previous
            </button>
          </li>
          {[...range(1, contracts?.pagination.pages)].map((index) => (
            <div>
              {contracts?.pagination.page === index + 1 ? (
                <li className={`page-item active `} key={index}>
                  <button className="page-link">{index + 1}</button>
                </li>
              ) : (
                <li className={`page-item`} key={index}>
                  <button
                    className="page-link "
                    onClick={() =>
                      updatePaginationParams({
                        page: index + 1,
                      })
                    }
                  >
                    {index + 1}
                  </button>
                </li>
              )}
            </div>
          ))}
          <li className={`page-item ${!contracts?.pagination.hasNext ? 'disabled' : ''}`}>
            <button
              className="page-link "
              onClick={() =>
                updatePaginationParams({
                  page: contracts?.pagination.nextPage,
                })
              }
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default HouseContractTable
