import React, { Component } from 'react';
import './asset.css';
import { assetsApi } from '../../../utils/assets-api';
import { accountsApi } from '../../../utils/accounts-api';
import { AssetResponse, CompanySummaryResponse } from '../accountPage/helpers';
import PageSection from '../../../lib/dashboard-page/PageSection';
import { TdWithLink } from '../../../lib/Table/TrWithLink';
import Modal, { STitle } from '../../../lib/modal/Modal';
import { PaymentForm } from '../../../lib/modal/PaymentForm';
import { PaymentType } from '../../../lib/enum/PaymentType';
import { setGALocation } from '../overview/utils';
import { StyledPageNavRight } from '../../../lib/styled';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE = 1;

interface Props {
  loggedIn: () => void;
  history: any;
  location;
  match;
}

interface State {
  assets: AssetResponse[];
  companySummary?: CompanySummaryResponse;
  makePaymentModal: boolean;
}

class AssetsPage extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      makePaymentModal: false,
    };
    this.createAssetPage = this.createAssetPage.bind(this);
    this.createHousePage = this.createHousePage.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    setGALocation();
    assetsApi
      .getAssetsWithHousesAndSummaryAndContracts()
      .then((assets: any[]) => {
        this.setState({ assets });

        accountsApi.getCompanySummary().then((companySummaryResponse) => {
          this.setState({ companySummary: companySummaryResponse });
        });
      });
  }

  createAssetPage() {
    this.props.history.push('assets/create');
  }

  createHousePage(assetId) {
    this.props.history.push(`houses/create?asset=${assetId}`);
  }

  toggleModal() {
    const isVisible = this.state.makePaymentModal;
    this.setState({ makePaymentModal: !isVisible });
  }

  render() {
    const paymentFormRequest = { type: PaymentType.Mpesa };
    return (
      <>
        {/*------------------company details----------------- */}
        <PageSection>
          <Modal
            isVisible={this.state.makePaymentModal}
            hideModal={this.toggleModal}
          >
            <div className="align-self-start">
              <STitle>Modal</STitle>
            </div>
            <PaymentForm
              hideModal={this.toggleModal}
              paymentFormRequest={paymentFormRequest}
              assets={this.state.assets}
            />
          </Modal>

          <StyledPageNavRight>
            <button
              className="btn btn-primary"
              onClick={() => this.toggleModal()}
            >
              record payment
            </button>
          </StyledPageNavRight>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <h5>Properties</h5>
            </div>
            <button
              className="btn btn-link umami--click--create-asset-url"
              id="create-asset-button"
              data-cy="create-asset-button"
              onClick={() => this.createAssetPage()}
            >
              Create property
            </button>
          </div>

          <div style={{ overflow: 'scroll' }}>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Property</th>
                  <th>Location</th>
                  <th>occupied</th>
                  <th>all houses</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.assets.map((asset, index) => (
                  <tr key={index}>
                    <TdWithLink link={`asset/${asset.id}`}>
                      {1 + index}
                    </TdWithLink>
                    <TdWithLink link={`asset/${asset.id}`}>
                      {asset.name}
                    </TdWithLink>
                    <TdWithLink link={`asset/${asset.id}`}>
                      {asset.location}
                    </TdWithLink>
                    <TdWithLink link={`asset/${asset.id}`}>
                      {asset.occupied_houses}
                    </TdWithLink>
                    <TdWithLink link={`asset/${asset.id}`}>
                      {asset.all_houses}
                    </TdWithLink>
                    <td className="d-flex flex-row-reverse">
                      <button
                        className="btn btn-dark"
                        onClick={() => this.createHousePage(asset.id)}
                      >
                        create house
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </PageSection>
      </>
    );
  }
}

export default AssetsPage;
