import React, { ReactElement, useContext, useState } from 'react'
import { StyledBreadcrumb, StyledPageNav } from '../../../lib/styled'
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'
import PageSection from '../../../lib/dashboard-page/PageSection'
import { assetsApi } from '../../../utils/assets-api'
import MessagesContext from '../../../utils/MessagesContext'
import { useHistory } from 'react-router-dom'
import { convertSearchToRouteParams } from '../accountPage/helpers'
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import { FaAngleDown, FaCheck } from 'react-icons/fa'
import { convertCsvStringToArray } from './utils'
import { exportCSVFile } from '../../shared/helpers/helpers'

interface CreateInvoicePageUrlParams {
  asset?: string
  contract?: string
  selectedDate?: string
  selectedRent?: string
}

export default function CreateUploadInstancePage(): ReactElement {
  const [uploadFileJson, setUploadFileJson] = useState<any[]>()
  const { setmessage } = useContext(MessagesContext)
  const history = useHistory()

  const [selectedFile, setSelectedFileState] = useState<any>()

  const handleFileUploadChange = (event: any) => {
    console.log('handleChange', event.target.files[0])
    // this.setState({ selectedFile: event.target.files[0] });
    const reader = new FileReader()
    reader.onload = (event) => {
      const text = event.target.result
      // const json = csv2json(text); // find a lib, or write one
      console.log('handleChange.text', text)
      const jsonResults = convertCsvStringToArray(text)
      console.log('jsonResults', jsonResults)

      setUploadFileJson(jsonResults)
    }

    reader.readAsText(event.target.files[0])
    setSelectedFileState(event.target.files[0])
  }

  const createNewUploadInstance = (uploadPayload) => {
    console.log('uploadPayload', uploadPayload)
    assetsApi.createNewUploadInstance(uploadPayload).then(
      (response) => {
        console.log('uploadResponse', response)
        history.push(`/upload-instances/${response.id}`)
      },
      ({ response }) => {
        if (response.data.message) {
          setmessage(response.data.message)
        } else {
          console.error(response)
        }
      },
    )
  }

  const onSubmit = () => {
    createNewUploadInstance(uploadFileJson)
  }

  const downloadAssetsHousesCsvExample = () => {
    const headers = {
      assetName: 'assetName',
      houseNumber: 'houseNumber',
    }

    const fileTitle = 'example_house_upload'
    exportCSVFile(headers, [], fileTitle)
  }

  const downloadAssetsHousesWithContractCsvExample = () => {
    const headers = {
      assetName: 'assetName',
      houseNumber: 'houseNumber',
      startDate: 'startDate',
      amount: 'amount',
    }

    const fileTitle = `example_house_with_contract_upload_${new Date().toJSON().split('.')[0]}`
    exportCSVFile(headers, [], fileTitle)
  }

  return (
    <DashboardPage2 hideTitle={true}>
      <StyledPageNav>
        <StyledBreadcrumb>
          <BreadcrumbItem url={'/upload-instances'} title={'upload instances'} />
          <BreadcrumbItem active title={'create'} />
        </StyledBreadcrumb>
      </StyledPageNav>

      <PageSection
        rightHeader={
          <Menu
            menuButton={
              <MenuButton className="btn btn-primary">
                download template
                <FaAngleDown />
              </MenuButton>
            }
          >
            <MenuItem onClick={downloadAssetsHousesCsvExample}>assets and houses</MenuItem>
            <MenuItem onClick={downloadAssetsHousesWithContractCsvExample}>assets houses and contracts</MenuItem>
          </Menu>
        }
      >
        <div className="input-group mt-3  mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroupFileAddon01">
              Upload
            </span>
          </div>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              accept="application/csvm+json"
              onChange={handleFileUploadChange}
            />

            <label className="custom-file-label" htmlFor="inputGroupFile01">
              {selectedFile ? selectedFile.name : 'Choose file'}
            </label>
          </div>
          {selectedFile?.type === 'text/csv' ? <FaCheck style={{ margin: '8px 0 0 8px' }} /> : null}
        </div>

        <p> upload records {uploadFileJson ? uploadFileJson.length : null}</p>

        <button
          type="button"
          onClick={onSubmit}
          className="btn btn-primary"
          disabled={selectedFile?.type !== 'text/csv'}
        >
          upload file
        </button>
      </PageSection>
    </DashboardPage2>
  )
}
