import React, { Component } from 'react';
import AssetForm from '../AssetForm';
import { assetsApi } from '../../../../utils/assets-api';
import MessagesContext from '../../../../utils/MessagesContext';
import { setGALocation } from '../../overview/utils';
import { AssetResponse } from '../../accountPage/helpers';

interface Props {
  history: any;
}

const mypadding = { padding: '20px' };

class CreateAssetPage extends Component<Props, null> {
  static contextType = MessagesContext;
  constructor(props) {
    super(props);
    this.createAsset = this.createAsset.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    setGALocation();
  }

  onSubmit(assetRequest) {
    for (const key in assetRequest) {
      if (assetRequest[key] === '') {
        this.context.setmessage('info', `${key} missing`);
        return;
      }
    }

    this.createAsset(assetRequest);
  }

  createAsset(assetRequest) {
    assetsApi.createAsset(assetRequest).then((assetResponse: AssetResponse) => {
      console.log('assetResponse', assetResponse);
      this.context.setmessage(
        'success',
        `${assetResponse.name} asset created successfully`
      );
      this.props.history.push(`/asset/${assetResponse.id}/houses`);
    });
  }

  render() {
    return (
      <div style={mypadding}>
        <div className="Card-Title card-hed">Create Asset</div>
        <div
          style={{
            background: 'white',
            padding: '20px',
            boxShadow:
              '0 1px 5px 0px rgba(0,0,0,0.3), 0 1px 5px 0px rgba(0,0,0,0.15)',
          }}
        >
          <AssetForm onSubmit={this.onSubmit} />
        </div>
      </div>
    );
  }
}

export default CreateAssetPage;
