import React, { Component } from 'react';
import {
  CompanyRequest,
  UserRequest,
} from '../dashboard/pages/accountPage/helpers';
import RegisterForm from './RegisterForm';
import CompanyForm from './CompanyForm';
import { userApi } from '../utils/user-api';
import MessagesContext from '../utils/MessagesContext';
import { Link } from 'react-router-dom';
import { getCurrentHost } from './ForgotPassword';
import { setGAEvent, setGALocation } from '../dashboard/pages/overview/utils';

interface Props {
  history: any[];
}
interface State {
  currentStep: number;
  termsOfService?: boolean;
  userRequest?: UserRequest;
  companyRequest?: CompanyRequest;
}

export default class RegisterWizard extends Component<Props, State> {
  static contextType = MessagesContext;
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      termsOfService: false,
    };
  }

  componentDidMount() {
    setGALocation();
  }

  ifUserFormValid(userRequest?: UserRequest) {
    const valid =
      !!userRequest &&
      !!userRequest.firstName &&
      !!userRequest.lastName &&
      !!userRequest.email &&
      !!userRequest.password &&
      !!userRequest.repeatPassword &&
      userRequest.password === userRequest.repeatPassword;
    return !valid;
  }

  ifCompanyFormValid(companyRequest?: CompanyRequest) {
    const valid =
      !!companyRequest &&
      !!companyRequest.name &&
      !!companyRequest.location &&
      (!!companyRequest.token || companyRequest.freeVersion);
    return !valid;
  }

  handleTermsOfServiceChange(event) {
    this.setState({ termsOfService: event.target.checked });
  }

  handleUserFormSubmit(userRequest: UserRequest) {
    const newRequest = Object.assign(this.state.userRequest || {}, userRequest);
    this.setState({ userRequest: newRequest });
  }

  handleCompanyFormSubmit(companyRequest: CompanyRequest) {
    const newRequest = Object.assign(
      this.state.companyRequest || {},
      companyRequest
    );
    this.setState({ companyRequest: newRequest });
  }

  nextStep() {
    setGAEvent('User', `register wizard step ${this.state.currentStep}`);
    const currentStep = this.state.currentStep + 1;
    this.setState({ currentStep });
  }
  previousStep() {
    const currentStep = this.state.currentStep - 1;
    this.setState({ currentStep });
  }
  submitForm() {
    setGAEvent('User', `register wizard submit form`);
    const registerRequest = {
      ...this.state.userRequest,
      companyRequest: this.state.companyRequest,
      mail_host: getCurrentHost(),
    };

    userApi
      .registerUser(registerRequest)
      .then((userResponse) => {
        this.context.setmessage(
          'success',
          'You have been signed up successfully'
        );

        localStorage.setItem('token', userResponse.access_token);
        localStorage.setItem('user', JSON.stringify(userResponse));
        this.props.history.push('/');
      })
      .catch((error) => {
        const errors = error ? error.errors : [];
        for (const error of Object.values(errors)) {
          this.context.setmessage('error', error);
        }
      });
  }

  render() {
    const progressPercentage = `${(this.state.currentStep / 2) * 100}%`;
    return (
      <>
        <div className="register-header">Login to Pro Assets</div>
        <div
          style={{ minHeight: '100vh', background: '#f2f0f8' }}
          className="d-flex justify-content-center"
        >
          <div className="d-flex flex-column" style={{ padding: '0 20px' }}>
            <div className="progress" style={{ minWidth: '50vw' }}>
              <div
                className="progress-bar progress-bar-striped"
                role="progressbar"
                style={{ width: progressPercentage }}
              ></div>
            </div>

            <div
              style={{ padding: '20px 0' }}
              className="d-flex justify-content-between"
            >
              <div>User details</div>
              <div>Agency details</div>
              <div>Submit</div>
            </div>

            {this.state.currentStep === 0 && (
              <div className="login-body">
                <RegisterForm
                  userRequest={this.state.userRequest}
                  formChanges={this.handleUserFormSubmit.bind(this)}
                />
                <div className="d-flex justify-content-between">
                  <div></div>
                  <button
                    disabled={this.ifUserFormValid(this.state.userRequest)}
                    onClick={this.nextStep.bind(this)}
                    type="button"
                    className="btn btn-primary"
                  >
                    next
                  </button>
                </div>
              </div>
            )}

            {this.state.currentStep === 1 && (
              <div className="login-body">
                <CompanyForm
                  companyRequest={this.state.companyRequest}
                  formChanges={this.handleCompanyFormSubmit.bind(this)}
                />
                <div className="d-flex justify-content-between">
                  <button
                    onClick={this.previousStep.bind(this)}
                    type="button"
                    className="btn btn-primary"
                  >
                    previous
                  </button>
                  <button
                    disabled={this.ifCompanyFormValid(
                      this.state.companyRequest
                    )}
                    onClick={this.nextStep.bind(this)}
                    type="button"
                    className="btn btn-primary"
                    data-cy="companyNext"
                  >
                    next
                  </button>
                </div>
              </div>
            )}

            {this.state.currentStep === 2 && (
              <div className="login-body">
                <div className="d-md-flex">
                  <div className="col">
                    <p className="h5">User details</p>
                    <p>
                      <span className="text-label">First name: </span>
                      <span>{this.state.userRequest?.firstName}</span>
                    </p>
                    <p>
                      <span className="text-label">Last name: </span>
                      <span>{this.state.userRequest?.lastName}</span>
                    </p>
                    <p>
                      <span className="text-label">Email: </span>
                      <span>{this.state.userRequest?.email}</span>
                    </p>
                  </div>

                  <div className="col">
                    <p className="h5">Agency Details</p>
                    <p>
                      <span className="text-label">Name: </span>
                      <span id="asset-name">
                        {this.state.companyRequest?.name}
                      </span>
                    </p>
                    <p>
                      <span className="text-label">location: </span>
                      {this.state.companyRequest?.location}
                    </p>
                  </div>
                </div>

                <div className="form-group form-check">
                  <input
                    checked={this.state.termsOfService}
                    type="checkbox"
                    className="form-check-input"
                    onChange={this.handleTermsOfServiceChange.bind(this)}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    I have read and agree to the{' '}
                    <Link to="/tnc" target="_blank">
                      terms of service
                    </Link>{' '}
                  </label>
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    onClick={this.previousStep.bind(this)}
                    type="button"
                    className="btn btn-primary"
                  >
                    previous
                  </button>
                  <button
                    disabled={!this.state.termsOfService}
                    onClick={this.submitForm.bind(this)}
                    type="button"
                    data-cy="registerSubmit"
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
            <div className="login-request">
              <p> go to login page</p>
              <Link className="umami--click--login-link" to="/login">
                login
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
