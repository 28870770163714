import React from 'react';
import { StyledNoAuthPage } from '../styled';

interface Props {
  children: any;
}

const NoAuthPage = (props: Props) => {
  return (
    <StyledNoAuthPage className="d-flex justify-content-center align-items-center">
      {props.children}
    </StyledNoAuthPage>
  );
};

export default NoAuthPage;
