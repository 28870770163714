import React from 'react'

import './header.css'
import { FaAngleDown, FaBars, FaBell } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
import { Menu, MenuItem } from '@szhsin/react-menu'

export interface HeaderProps {
  user?: any
  onHome?: () => void
  onLogout: () => void
  daysToExpire?: number
  toggleSidebar?: () => void
}

export const Header: React.FC<HeaderProps> = ({ user, onHome, onLogout, daysToExpire, toggleSidebar }) => (
  <header>
    <ReactTooltip />
    <nav className="dashboard-nav d-flex justify-content-between">
      <div>
        <FaBars onClick={toggleSidebar} />
      </div>
      <div className="d-flex">
        {daysToExpire ? (
          <FaBell
            style={{ marginTop: '10px' }}
            data-tip={`${daysToExpire} days package to expire please reniew to avoid disabled features`}
          />
        ) : (
          <FaBell style={{ marginTop: '10px' }} data-tip="Account does not have an active package" />
        )}
        <Menu
          menuButton={
            <div className="btn btn-link" style={{ padding: '6px 12px' }}>
              {`${user?.first_name} ${user?.last_name}`}
              <FaAngleDown />
            </div>
          }
        >
          <MenuItem>Account</MenuItem>
          <MenuItem>upgrade</MenuItem>
          <MenuItem>downgrade</MenuItem>
          <MenuItem data-cy="logout-menu-item" onClick={onLogout}>
            logout
          </MenuItem>
        </Menu>
      </div>
    </nav>
  </header>
)
