import { Field, Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { required } from '../../../lib/forms/validators'
import { SFormErrors } from '../../../lib/forms/css/variables.styles'
import React from 'react'
import { InvoiceItemTypeEnum } from '../../../lib/enum/InvoiceItemTypeEnum'

interface ExpensesInvoiceItemTableFormProps {
  onSubmit: (value: any) => void
  onDeleteInvoiceItem: (value: any) => void
  tableInitialValues?: any[]
  expenses?: any[]
  formSubmitted?: boolean
  rentInvoiceItems?: any[]
}

export const ExpensesInvoiceItemTableForm = (props: ExpensesInvoiceItemTableFormProps) => {
  return (
    <Form
      onSubmit={props.onSubmit}
      mutators={{
        // potentially other mutators could be merged here
        ...arrayMutators,
      }}
      // validate={validate}
      initialValues={{ expenses: props.tableInitialValues }}
      render={({ handleSubmit, pristine, invalid }) => (
        <form className="form-group p-0 m-0" onSubmit={handleSubmit}>
          <FieldArray name="expenses">
            {({ fields, meta }) => (
              <>
                <div className="d-flex p-2 w-100 justify-content-between">
                  <h6 className="font-weight-bold text-justify">Invoice Items</h6>
                  {!props.formSubmitted ? (
                    <button className="btn btn-secondary" type="button" onClick={() => fields.push({ newItem: true })}>
                      Add
                    </button>
                  ) : null}
                </div>

                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      {/*<th scope="col">Date payed</th>*/}
                      <th scope="col">Type</th>
                      {/*<th scope="col">Payment code</th>*/}
                      <th scope="col">Amount</th>
                      <th scope="col" className="text-right">
                        actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((name, index) => (
                      <tr key={index}>
                        <td>
                          <Field
                            name={`${name}.expense_id`}
                            component="select"
                            className={'form-control form-control-sm'}
                            validate={required}
                          >
                            {({ input, meta }) => (
                              <>
                                <select
                                  {...input}
                                  disabled={!(!props.formSubmitted && fields.value[index].newItem)}
                                  className={'form-control form-control-sm'}
                                >
                                  <option />
                                  <option value={InvoiceItemTypeEnum.RENT}>Rent</option>
                                  {props.expenses
                                    ? props.expenses.map((expense, index) => (
                                        <option value={expense.id} key={index}>
                                          {expense.name}
                                        </option>
                                      ))
                                    : null}
                                </select>
                                <SFormErrors>{meta.error && meta.touched && <span>{meta.error}</span>}</SFormErrors>
                              </>
                            )}
                          </Field>
                        </td>

                        <td>
                          <Field name={`${name}.amount`} validate={required} type="number">
                            {({ input, meta }) => (
                              <>
                                <input
                                  {...input}
                                  disabled={!(!props.formSubmitted && fields.value[index].newItem)}
                                  className={`form-control form-control-sm border ${
                                    meta.dirty && 'rounded border-success'
                                  }`}
                                  onChange={(event) => {
                                    fields.update(index, {
                                      ...fields.value[index],
                                      edited: true,
                                    })
                                    input.onChange(event)
                                    console.log('meta', meta)
                                  }}
                                />
                                <SFormErrors>{meta.error && meta.touched && <span>{meta.error}</span>}</SFormErrors>
                              </>
                            )}
                          </Field>
                        </td>
                        <td className="d-flex flex-row-reverse">
                          {!props.formSubmitted ? (
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={() => fields.remove(index) && props.onDeleteInvoiceItem(fields.value[index])}
                            >
                              Remove
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!props.formSubmitted && !!fields.value.find((item) => item.newItem) ? (
                  <button className="btn btn-secondary" type="submit">
                    Update
                  </button>
                ) : null}
              </>
            )}
          </FieldArray>
        </form>
      )}
    />
  )
}
