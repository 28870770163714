import React, { ReactElement, useEffect, useState } from 'react'
import { StyledBreadcrumb, StyledPageFilterNav, StyledPageNav, StyledPageNavRight } from '../../../lib/styled'
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'
import PageSection from '../../../lib/dashboard-page/PageSection'
import { Link, useHistory } from 'react-router-dom'
import { SECTION_PADDING } from '../../../lib/Table/cssVariables'
import { assetsApi } from '../../../utils/assets-api'
import { convertSearchToRouteParams } from '../accountPage/helpers'
import * as H from 'history'
import { PaginationUtil } from '../../shared/utils/PaginationUtil'
import OneDateRange from '../../components/OneDateRange'
import { dateToString } from '../../shared/utils/dateUtils'
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import { FaBook } from 'react-icons/fa'
import InvoiceTable from './InvoiceTable'
import DatatablePagination from './DatatablePagination'

export default function InvoicesPage(): ReactElement {
  const history: H.History = useHistory()
  const paginationUtil = new PaginationUtil(history)
  const [invoicesPage, setInvoicesPage] = useState<any>()

  useEffect(() => {
    fetchAllInvoices(convertSearchToRouteParams(history.location.search))
  }, [history.location.search])

  const fetchAllInvoices = (paginationParams = {}) => {
    assetsApi.fetchAllInvoices(paginationParams).then((invoicesPageResponse) => {
      setInvoicesPage(invoicesPageResponse)
    })
  }

  const updatePaginationParams = (paginationParams = {}) => {
    paginationUtil.updatePaginationParams(paginationParams)
  }

  const handlePageSizeChange = (event) => {
    paginationUtil.updatePaginationParams({
      size: parseInt(event.target.value),
    })
  }

  const handleDateRange = (dateRange: [any, any]) => {
    console.log('handleDateRange', dateRange)
    const dateRangeParams = { startDate: null, endDate: null }
    if (dateRange.length) {
      const [startDate, endDate] = dateRange
      if (startDate) {
        dateRangeParams['startDate'] = dateToString(startDate)
      }
      if (endDate) {
        dateRangeParams['endDate'] = dateToString(endDate)
      }
      console.log('dateRangeParams.handleDateRange', dateRangeParams)

      paginationUtil.updatePageParams(dateRangeParams)
    }
  }

  return (
    <DashboardPage2 hideTitle={true}>
      <StyledPageNav>
        <StyledBreadcrumb>
          <BreadcrumbItem
            active
            title={'invoices'}
            iconComponent={
              <a target="_blank" href={'https://docs.proassets.co.ke/blog/2021/09/18/invoice'} rel="noreferrer">
                <FaBook />
              </a>
            }
          />
        </StyledBreadcrumb>
        <StyledPageNavRight>
          <Menu menuButton={<MenuButton data-type="open-menu" className="btn btn-primary">Open menu</MenuButton>}>
            <MenuItem data-cy="create-invoice" href={'#/invoices/create'}>
              Create Invoice
            </MenuItem>
            <MenuItem href={'#/invoices/missing-invoices'}>Missing Invoices</MenuItem>
          </Menu>

        </StyledPageNavRight>
      </StyledPageNav>

      <StyledPageFilterNav>
        <OneDateRange onChange={handleDateRange} />
      </StyledPageFilterNav>

      <PageSection>
        <div>
          <div
            style={{
              padding: SECTION_PADDING,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className="d-flex justify-content-between"
          >
            <div style={{ display: 'flex' }}>
              <div>Show</div>
              <select
                value={invoicesPage?.pagination.size}
                onChange={handlePageSizeChange}
                style={{ width: '80px', margin: '0 10px' }}
                className="custom-select custom-select-sm"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <div>records per page</div>
            </div>
            <div>
              <input type="text" className="form-control form-control-sm" />
            </div>
          </div>

          <InvoiceTable invoices={invoicesPage?.items} />
          {invoicesPage ? <DatatablePagination pagination={invoicesPage?.pagination} /> : null}
        </div>
      </PageSection>
    </DashboardPage2>
  )
}
