import React, { Component } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment'
interface Props {
  receipt?: any
}

const styles = StyleSheet.create({
  section: {
    borderWidth: 2,
    borderColor: '#112131',
    borderStyle: 'solid',
    margin: '14px',
    padding: '16px',
    fontSize: 16,
  },
  table: {
    fontSize: 14,
  },
  td: {},
  th: {},
  tbody: {},
  thead: {
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
  },
  header: {
    display: 'flex',
  },
  th_header: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  tr: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: '8px',
  },
})

class PdfItem extends Component<Props> {
  render() {
    return (
      this.props.receipt && (
        <View style={styles.section}>
          <View style={styles.header}>
            <Text style={styles.th_header}>{this.props.receipt.house.asset.name}</Text>
            <Text style={styles.th_header}>{this.props.receipt.house.asset.location}</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.thead}>
              <View style={styles.tr}>
                <Text style={styles.th}>House</Text>
                <Text style={styles.th}>For Date</Text>
                <Text style={styles.th}>Rent</Text>
              </View>
            </View>

            <View style={styles.tbody}>
              <View style={styles.tr}>
                <Text style={styles.td}>{this.props.receipt.house.house_number}</Text>
                <Text style={styles.td}>{moment(`${this.props.receipt.payment_date}`).format('DD-MM-YYYY')}</Text>
                <Text style={styles.td}>{this.props.receipt.rent}</Text>
              </View>
            </View>
          </View>
        </View>
      )
    )
  }
}

export default PdfItem
