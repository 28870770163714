import React, { createContext, useReducer } from 'react';
import storeReducer from './storeReducer';
import { CLEAR_ERRORS, LOAD_PACKAGE, PACKAGE_ERROR } from '../types';
import { accountsApi } from '../../utils/accounts-api';

interface Props {
  children: any;
}

const initialState = {
  package: null,
  error: null,
  loading: false,
};

export const StoreContext = createContext({});

// TODO work in progress
const StoreState = (props: Props) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  // actions go here
  const getPackage = async (packageId) => {
    try {
      accountsApi.getCompanySummary();
      const res = await accountsApi.getCompanySummary();
      // dispatch to the reducer to change the state
      dispatch({
        type: LOAD_PACKAGE,
        payload: res,
      });
    } catch (error) {
      dispatch({
        type: PACKAGE_ERROR,
        payload: 'something went wrong',
      });
    }
  };

  // clear errors
  const clearErrors = () => {
    dispatch({
      type: CLEAR_ERRORS,
    });
  };

  return (
    <StoreContext.Provider
      value={{
        package: state.package,
        error: state.error,
        loading: state.loading,
        getPackage,
        clearErrors,
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};

export default StoreState;
