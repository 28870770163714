import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { TenantRequest } from '../accountPage/helpers';

interface Props {
  tenantFormChange: (event: any) => void;
  tenantRequest?: TenantRequest;
}

interface State {
  tenantRequest?: TenantRequest;
}

export default class TenantForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleTenantFormChange = this.handleTenantFormChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleTenantFormChange(event) {
    const form = event.target.name;
    const state = Object.assign({}, this.props.tenantRequest);
    state[form] = event.target.value;
    this.setState({ tenantRequest: state });
    this.props.tenantFormChange(state);
  }

  handleDateChange(date) {
    const state = Object.assign({}, this.props.tenantRequest);
    state.start_date = date;
    this.setState({ tenantRequest: state });
    this.props.tenantFormChange(state);
  }

  render() {
    const tenantRequest = this.props.tenantRequest;
    return (
      <div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="tenantNames">Tenant Names</label>
            <input
              type="text"
              className="form-control"
              id="tenantNames"
              value={tenantRequest?.full_names}
              name="full_names"
              onChange={this.handleTenantFormChange}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="startDate">start Date</label>
            <DatePicker
              id="startDate"
              className="form-control"
              selected={tenantRequest?.start_date}
              onChange={this.handleDateChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="rentAmount">Rent amount</label>
            <input
              className="form-control"
              id="rentAmount"
              value={tenantRequest?.rent}
              name="rent"
              onChange={this.handleTenantFormChange}
              type="number"
              placeholder="KES"
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="startDate">Frequency</label>
            <input
              className="form-control"
              id="frequency"
              value={tenantRequest?.period}
              name="period"
              onChange={this.handleTenantFormChange}
              type="text"
              disabled
            />
          </div>
        </div>

        <div className="form-row">
          {/*<div className="form-group col-md-6">*/}
          {/*  <label htmlFor="phone_number">Phone number</label>*/}
          {/*  <div className="input-group">*/}
          {/*    <input*/}
          {/*      className="form-control"*/}
          {/*      id="phone_number"*/}
          {/*      value={tenantRequest?.phone_number}*/}
          {/*      name="phone_number"*/}
          {/*      onChange={this.handleTenantFormChange}*/}
          {/*      type="number"*/}
          {/*      placeholder="254 *** *** ***"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }
}
