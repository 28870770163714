import React, { Component } from 'react';
import TrWithLink from '../../../lib/Table/TrWithLink';
import dateFormat from '../../shared/helpers/dateFormat';
import { Table } from 'react-bootstrap';
import SimplePagination from '../../components/SimplePagination';
import { PaPageRoute } from './PaPageRoute';
import {
  HouseResponse,
  PageParams,
  PaginationPage,
} from '../accountPage/helpers';
import { assetsApi } from '../../../utils/assets-api';
import { setGALocation } from '../overview/utils';
import PageSection from '../../../lib/dashboard-page/PageSection';

interface Props {
  history: any[];
  location;
}

interface State {
  asset_id: number;
  housesPage?: PaginationPage<HouseResponse>;
}

export default class AssetHousesTabPage extends Component<Props, State> {
  customPageRoute: PaPageRoute;

  constructor(props) {
    super(props);
    this.state = {
      asset_id: JSON.parse(props.match.params.id),
    };
    this.customPageRoute = new PaPageRoute(this.props);
  }
  componentDidMount() {
    setGALocation();
    this.customPageRoute.resetPaginationParams();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const queries: PageParams = this.customPageRoute.getPageParams(
        this.props.location
      );
      this.fetchAssetHousesPage(queries.page, queries.size);
    }
  }

  fetchAssetHousesPage(page, size) {
    assetsApi
      .getAssetHouses(this.state.asset_id, page, size)
      .then((housesPage) => {
        this.setState({ housesPage });
      });
  }

  updatePageSize(size) {
    const previousPageParams = this.customPageRoute.getPageParams(
      this.props.location
    );
    const newPage = {
      page: previousPageParams.page,
      size: size,
    };
    this.customPageRoute.updatePageParams(newPage);
  }

  updatePage(page) {
    const previousPageParams = this.customPageRoute.getPageParams2(
      this.props.location
    );

    const newPage = {
      page: page,
      size: previousPageParams.size,
    };
    this.customPageRoute.updatePageParams(newPage);
  }

  render() {
    return (
      <PageSection>
        <div className="asset-houses-stats" style={{ overflow: 'scroll' }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="th-slim" scope="col">
                  #
                </th>
                <th className="th-slim" scope="col">
                  House
                </th>
                <th className="th-slim" scope="col">
                  Status
                </th>
                <th scope="col">Tenant</th>
                <th className="th-slim" scope="col">
                  Rent
                </th>
                <th className="th-slim" scope="col">
                  Start date
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.housesPage?.items?.map((house, index) => (
                <TrWithLink
                  link={
                    house.currentContract
                      ? `/houses/${house.asset.id}/${house.id}/${house.currentContract.id}`
                      : `/houses/${house.asset.id}/${house.id}`
                  }
                  key={index}
                >
                  <td id={`row-${index}`}>{index + 1}</td>
                  <td className="houseNumber">{house.house_number}</td>
                  <td>{house.occupancy}</td>

                  <td>{house.currentContract?.tenant?.full_names}</td>
                  <td>{house.currentContract?.rent}</td>
                  <td>{dateFormat(house.currentContract?.start_date)}</td>
                </TrWithLink>
              ))}
            </tbody>
          </Table>
          <SimplePagination
            goToNext={(nextPage) => this.updatePage(nextPage)}
            goToPrev={(prevPage) => this.updatePage(prevPage)}
            changePageSize={(size) => this.updatePageSize(size)}
            pagination={this.state.housesPage?.pagination}
          />
        </div>
      </PageSection>
    );
  }
}
