import React, { useContext, useEffect } from 'react'
import NoAuthPage from '../lib/page2/NoAuthPage'
import { StyledNoAuthPageCard } from '../lib/styled'
import { Button } from '../stories/Button'
import { userApi } from '../utils/user-api'
import MessagesContext from '../utils/MessagesContext'
import { useHistory } from 'react-router-dom'
import { convertSearchToRouteParams } from '../dashboard/pages/accountPage/helpers'

const CompleteRegistration = () => {
  const { setmessage } = useContext(MessagesContext)
  const history = useHistory()

  useEffect(() => {
    const searchHistory = convertSearchToRouteParams(history.location.search)
    completeRegistration(searchHistory)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search])

  useEffect(() => {
    // todo bug here why complete registration on mount
    completeRegistration('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const completeRegistration = (payload) => {
    userApi
      .confirmUser(payload.token)
      .then((response) => {
        // setmessage(
        //   'success',
        //   'Password reset successfully, check email for further instructions.'
        // );
      })
      .catch((error) => {
        if (error.message) {
          setmessage('error', error.message)
        } else {
          setmessage('error', 'Error reseting password')
        }
      })
  }
  return (
    <NoAuthPage>
      <StyledNoAuthPageCard>
        <h2>Complete</h2>
        <p>Registration completed successfully</p>
        <Button label="Go to dashboard" />
      </StyledNoAuthPageCard>
    </NoAuthPage>
  )
}

export default CompleteRegistration
