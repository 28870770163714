import React, { Component } from 'react';
import Modal, { ModalActions, STitle } from '../../../lib/modal/Modal';
import { AccountResponse } from './helpers';

interface Props {
  accountId: number;
  userNames: string;
  account: AccountResponse;
  removeUserAccount: (accountId: number, companyId: number) => void;
}

interface State {
  deleteAccountModalToggle?: boolean;
}

export default class RemoveModalButton extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleAccountModalModal = () => {
    const toggle = this.state.deleteAccountModalToggle;
    this.setState({ deleteAccountModalToggle: !toggle });
  };

  render() {
    console.log(this.props.account);
    return (
      <>
        <button
          onClick={this.toggleAccountModalModal}
          className="btn btn-danger"
        >
          {this.props.children}
        </button>

        <Modal
          isVisible={this.state.deleteAccountModalToggle}
          hideModal={this.toggleAccountModalModal}
        >
          <STitle>Remove user</STitle>
          Are you sure you want to remove {this.props.userNames}
          <ModalActions className="justify-content-between">
            <button
              style={{ marginRight: '10px' }}
              onClick={this.toggleAccountModalModal}
              className="btn btn-secondary mr-auto"
            >
              close
            </button>
            <button
              onClick={() =>
                this.props.removeUserAccount(
                  this.props.account.company.id,
                  this.props.accountId
                )
              }
              className="btn btn-danger"
              type="submit"
            >
              remove user
            </button>
          </ModalActions>
        </Modal>
      </>
    );
  }
}
