import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import ChangePasswordForm from './ChangePasswordForm'
import { ChangePasswordRequest } from '../../utils/accounts-api'
import MessagesContext from '../../utils/MessagesContext'

interface Props {
  onCloseConfirmationModal: (boolean) => void
}

export default class ConfirmationModal extends Component<Props, never> {
  render() {
    return (
      <div>
        <Modal.Dialog>
          <div style={{ padding: '20px' }} className="d-flex justify-content-between">
            <div>Create Token</div>
            <div>
              <button className="btn btn-secondary" onClick={() => this.props.onCloseConfirmationModal(false)}>
                {' '}
                Close{' '}
              </button>
            </div>
          </div>
          <Modal.Body>
            <p>Are you sure you want to create new package token</p>
          </Modal.Body>

          <Modal.Footer>
            <button className="btn btn-secondary" onClick={() => this.props.onCloseConfirmationModal(false)}>
              Close
            </button>
            <button className="btn btn-primary" onClick={() => this.props.onCloseConfirmationModal(true)}>
              Understood
            </button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    )
  }
}

interface ChangePasswordModalProps {
  onCloseConfirmationModal: (boolean) => void
}

interface ChangePasswordModalState {
  changePasswordRequest?: ChangePasswordRequest
}

export class ChangePasswordModal extends Component<ChangePasswordModalProps, ChangePasswordModalState> {
  static contextType = MessagesContext
  constructor(props) {
    super(props)
    this.state = {}

    this.handleChange = this.handleChange.bind(this)
    this.submitChangePassword = this.submitChangePassword.bind(this)
  }

  handleChange(changePasswordRequest: ChangePasswordRequest) {
    this.setState({ changePasswordRequest })
  }

  submitChangePassword() {
    if (
      !this.state.changePasswordRequest?.newPassword ||
      !this.state.changePasswordRequest?.newPasswordRepeat ||
      !this.state.changePasswordRequest?.oldPassword
    ) {
      this.context.setmessage('info', `all fields must not be empty`)
      return
    } else {
      if (this.state.changePasswordRequest.newPassword !== this.state.changePasswordRequest.newPasswordRepeat) {
        this.context.setmessage('info', `passwords do not match`)
      } else {
        this.props.onCloseConfirmationModal(this.state.changePasswordRequest)
      }
    }
  }

  render() {
    return (
      <Modal.Dialog>
        <div style={{ padding: '20px' }} className="d-flex justify-content-between">
          <div>Change password</div>
          <div>
            <button className="btn btn-secondary" onClick={() => this.props.onCloseConfirmationModal(false)}>
              close
            </button>
          </div>
        </div>
        <Modal.Body>
          <ChangePasswordForm handleChange={this.handleChange} />
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.submitChangePassword}>
            update
          </button>
        </Modal.Footer>
      </Modal.Dialog>
    )
  }
}
