import React, { ReactElement, useState } from 'react'
import DatePicker from 'react-datepicker'
import { endOfMonth } from '../../utils/dates'

interface Props {
  onChange?: (dateRange: any) => void
}

export default function OneDateRange(props: Props): ReactElement {
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const onChange = (dates) => {
    setDateRange([dates[0], dates[1] ? endOfMonth(dates[1]) : dates[1]])
    props.onChange([dates[0], dates[1] ? endOfMonth(dates[1]) : dates[1]])
  }

  return (
    <nav aria-label="Page" className="d-flex">
      <DatePicker
        className="form-control"
        dateFormat="MM/yyyy"
        showMonthYearPicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          onChange(update)
        }}
        isClearable={true}
      />
    </nav>
  )
}
