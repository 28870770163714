import { PageParams, getAllRouteParams, updatePageParams } from '../accountPage/helpers'
import React, { Component } from 'react'
import { StyledBreadcrumb, StyledPageFilterNav, StyledPageNav, StyledPageNavRight } from '../../../lib/styled'

import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'
import { Link } from 'react-router-dom'
import PageSection from '../../../lib/dashboard-page/PageSection'
import { PaginationUtil } from '../../shared/utils/PaginationUtil'
import { SECTION_PADDING } from '../../../lib/Table/cssVariables'
import SimplePagination from '../../components/SimplePagination'
import TrWithLink from '../../../lib/Table/TrWithLink'
import { assetsApi } from '../../../utils/assets-api'
import qs from 'qs'
import { range } from '../overview/utils'
import { setGALocation } from '../overview/utils'

interface Props {
  location
  history
}
interface State {
  houses?: any[]
  searchInput?: string
  pagination?: any
}

export default class HouseListPage extends Component<Props, State> {
  paginationUtil: any
  constructor(props) {
    super(props)
    this.state = {
      searchInput: '',
    }
    this.paginationUtil = new PaginationUtil(props.history)
    this.updatePaginationParams = this.updatePaginationParams.bind(this)
    this.findHouses = this.findHouses.bind(this)
  }

  componentDidMount() {
    setGALocation()
    const queries: PageParams = getAllRouteParams(this.props.location)
    if (!queries.page || !queries.size) {
      const newQueryString = qs.stringify(updatePageParams(this.props.location, { page: 1, size: 10 }))
      this.props.history.push({ search: newQueryString })
    } else {
      this.findHouses(queries.page, queries.size)
    }
  }

  findHouses(page, size) {
    assetsApi.getAssets().then((assets) => {
      if (assets.length) {
        assetsApi
          .findAllHouses(
            assets.map((asset) => asset.id),
            page,
            size,
          )
          .then(({ items, pagination }: any) => {
            console.log(JSON.stringify(pagination, null, 2))
            this.setState({ houses: items, pagination: pagination })
          })
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const queries: PageParams = getAllRouteParams(this.props.location)
      if (queries.page && queries.size) {
        this.findHouses(queries.page, queries.size)
      }
    }
  }

  handleChange(event) {
    const form = event.target.name
    const state = {}
    state[form] = event.target.value

    this.setState(state)
  }

  handleSubmit(event) {
    event.preventDefault()
  }

  updatePaginationParams(queryParams: any) {
    const newQueryString = qs.stringify(updatePageParams(this.props.location, queryParams))
    this.props.history.push({ search: newQueryString })
  }

  handlePageSizeChange = (event) => {
    this.updatePaginationParams({
      size: parseInt(event.target.value),
    })
  }

  render() {
    return (
      <DashboardPage2 hideTitle={true}>
        <StyledPageNav>
          <StyledBreadcrumb>
            <BreadcrumbItem active title={'houses'} />
          </StyledBreadcrumb>
        </StyledPageNav>

        <PageSection>
          <div style={{ overflow: 'scroll' }}>
            <div
              style={{
                padding: SECTION_PADDING,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="d-flex justify-content-between"
            >
              <div style={{ display: 'flex' }}>
                <div>Show</div>
                <select
                  value={this.state.pagination?.size}
                  onChange={this.handlePageSizeChange}
                  style={{ width: '80px', margin: '0 10px' }}
                  className="custom-select custom-select-sm"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <div>records per page</div>
              </div>
              <div>
                <input type="text" className="form-control form-control-sm" />
              </div>
            </div>
            <table className="table thead-dark table-sm ">
              <thead className="thead-dark">
                <tr>
                  <th>#</th>
                  <th>Asset</th>
                  <th>House</th>
                  <th>Status</th>
                  <th>Tenant</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.houses?.map((house, index) => (
                  <TrWithLink
                    link={
                      house.currentContract
                        ? `houses/${house.asset.id}/${house.id}/${house.currentContract.id}`
                        : `houses/${house.asset.id}/${house.id}`
                    }
                    key={index}
                  >
                    <td>{index + 1}</td>
                    <td>{house.asset.name}</td>
                    <td>{house.house_number}</td>
                    <td>{house.occupancy}</td>
                    <td>{house.currentContract?.tenant?.full_names}</td>
                  </TrWithLink>
                ))}
              </tbody>
            </table>
            {this.state.pagination && (
              <div className="d-flex justify-content-between">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  Showing 1 to {this.state?.pagination?.size} of {this.state?.pagination?.totalElements} entries
                </div>
                <ul className="pagination justify-content-end">
                  <li className={`page-item ${!this.state.pagination?.hasPrev ? 'disabled' : ''}`}>
                    <button
                      className="page-link "
                      onClick={() =>
                        this.updatePaginationParams({
                          page: this.state?.pagination.prevPage,
                        })
                      }
                    >
                      Previous
                    </button>
                  </li>
                  {[...range(1, this.state?.pagination.pages)].map((index) => (
                    <div key={index}>
                      {this.state?.pagination.page === index + 1 ? (
                        <li className={`page-item active `} key={index}>
                          <button className="page-link">{index + 1}</button>
                        </li>
                      ) : (
                        <li className={`page-item`} key={index}>
                          <button
                            className="page-link "
                            onClick={() =>
                              this.updatePaginationParams({
                                page: index + 1,
                              })
                            }
                          >
                            {index + 1}
                          </button>
                        </li>
                      )}
                    </div>
                  ))}
                  <li className={`page-item ${!this.state?.pagination.hasNext ? 'disabled' : ''}`}>
                    <button
                      className="page-link "
                      onClick={() =>
                        this.updatePaginationParams({
                          page: this.state?.pagination.nextPage,
                        })
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </PageSection>
      </DashboardPage2>
    )
  }
}
