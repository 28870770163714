import { AuthApi } from './api';
import { AxiosInstance } from 'axios';
import { baseAxios } from './api.config';
import { MissingPaymentsMap } from '../dashboard/pages/accountPage/helpers';

export class HousesApi extends AuthApi {
  public constructor(baseAxios?: AxiosInstance) {
    super(baseAxios);

    this.findAllHouses = this.findAllHouses.bind(this);
    this.createHouse = this.createHouse.bind(this);
    this.getHouseDetails = this.getHouseDetails.bind(this);
    this.findAssetHouse = this.findAssetHouse.bind(this);
  }

  public findAllHouses(assetIds) {
    return this.get(`/v1/houses`, { params: { assets: assetIds } }).then(
      this.success
    );
  }

  public getAllHouseMissingPayments(
    assetId: number,
    houseId: number,
    contractId,
    endDate: string
  ): Promise<MissingPaymentsMap> {
    return this.get<MissingPaymentsMap>(
      `/v1/assets/${assetId}/houses/${houseId}/contract/${contractId}/missing-payments?endDate=${endDate}`
    ).then(this.success);
  }

  public createHouse(contract_id, paymentResponse) {
    return this.post(
      `/v1/contracts/${contract_id}/payments`,
      paymentResponse
    ).then(this.success);
  }

  public getHouseDetails(house_id) {
    return this.get(`/v1/houses/${house_id}`).then(this.success);
  }

  public findAssetHouse(asset_id, house_id) {
    return this.get(`/v1/assets/${asset_id}/houses/${house_id}`).then(
      this.success
    );
  }

  /**
   * createContract
   *    */
  public createContract(tenant, house_id) {
    return this.post(`/v1/houses/${house_id}/tenant`, tenant).then(
      this.success
    );
  }
}

export const housesApi = new HousesApi(baseAxios);
