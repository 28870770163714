import React, { Component } from 'react';
import DashboardPage from '../../../lib/dashboard-page/DashboardPage';
import TenantForm from './TenantForm';
import { FormControl, Button } from 'react-bootstrap';
import {
  AssetResponse,
  HouseRequest,
  TenantRequest,
  HouseResponse,
} from '../accountPage/helpers';
import MessagesContext from '../../../utils/MessagesContext';
import { assetsApi } from '../../../utils/assets-api';
import 'react-datepicker/dist/react-datepicker.css';
import { validatePhoneNumber } from '../../shared/helpers/helpers';
import { setGALocation } from '../overview/utils';
interface Props {
  match: any;
  history: any;
}

interface State {
  houseId: number;
  assetId: number;
  addTenant?: boolean;
  houseRequest?: HouseRequest;
  tenantRequest?: TenantRequest;
  house_number?: string;
  toggleTenantForm?: string;
  asset?: AssetResponse;
}

export default class EditHousePage extends Component<Props, State> {
  static contextType = MessagesContext;
  constructor(props) {
    super(props);
    this.state = {
      houseId: JSON.parse(props.match.params.houseId),
      assetId: JSON.parse(props.match.params.assetId),
      addTenant: false,
    };
    this.getAssetHouseDetails = this.getAssetHouseDetails.bind(this);
    this.handleHouseNumberChange = this.handleHouseNumberChange.bind(this);
    this.handleAddTenantChange = this.handleAddTenantChange.bind(this);
    this.handleTenantFormChange = this.handleTenantFormChange.bind(this);
    this.handleHouseSubmit = this.handleHouseSubmit.bind(this);
    this.goToHouseDetailPage = this.goToHouseDetailPage.bind(this);
  }

  componentDidMount() {
    setGALocation();
    this.getAssetHouseDetails(this.state.assetId, this.state.houseId);
  }
  handleAddTenantChange(event) {
    this.setState({ addTenant: event.target.checked });
  }

  handleTenantFormChange(tenantRequest) {
    this.setState({ tenantRequest });
  }
  handleHouseNumberChange(event) {
    this.setState({ house_number: event.target.value });
  }

  getAssetHouseDetails(asset_id, house_id) {
    assetsApi
      .findAssetHouse(asset_id, house_id)
      .then((houseResponse: HouseResponse) => {
        if (
          houseResponse.currentContract &&
          houseResponse.currentContract.tenant
        ) {
          const tenantRequest: TenantRequest = {
            id: houseResponse.currentContract.tenant.id,
            period: houseResponse.currentContract.period,
            rent: houseResponse.currentContract.rent,
            start_date: new Date(houseResponse.currentContract.start_date),
            full_names: houseResponse.currentContract.tenant.full_names,
            email: houseResponse.currentContract.tenant.email,
            phone_number: houseResponse.currentContract.tenant.phone_number,
          };
          this.setState({
            asset: houseResponse.asset,
            tenantRequest: tenantRequest,
            house_number: houseResponse.house_number,
            addTenant: true,
          });
        } else {
          this.setState({
            asset: houseResponse.asset,
            house_number: houseResponse.house_number,
          });
        }
      });
  }

  handleHouseSubmit(event) {
    event.preventDefault();
    if (!this.state.house_number) {
      this.context.setmessage('error', 'house number must not be empty');
      return;
    }
    const houseRequest: { house_number; tenant?: TenantRequest } = {
      house_number: this.state.house_number,
    };

    const tenantRequest = this.state.tenantRequest;
    if (this.state.addTenant) {
      // if there is a phone number but the number is not valid phone number
      if (
        this.state.tenantRequest?.phone_number &&
        !validatePhoneNumber(this.state.tenantRequest.phone_number)
      ) {
        this.context.setmessage('info', 'invalid phone number');
        return;
      }
      if (tenantRequest?.phone_number === '') {
        delete tenantRequest.phone_number;
      }
    }

    houseRequest.tenant = tenantRequest;

    assetsApi
      .updateHouse(this.state.assetId, this.state.houseId, houseRequest)
      .then((houseResponse) => {
        this.context.setmessage('success', 'house created successfully');
        if (houseResponse.currentContract) {
          this.goToHouseContractDetailPage(
            houseResponse.asset.id,
            houseResponse.id,
            houseResponse.currentContract.id
          );
        } else {
          this.goToHouseDetailPage(houseResponse.asset.id, houseResponse.id);
        }
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          for (const error of Object.values(response.data.errors)) {
            this.context.setmessage('error', error);
          }
        } else if (response.data.message) {
          this.context.setmessage('error', response.data.message);
        }
      });
  }

  goToHouseDetailPage(assetId: number, houseId: number) {
    this.props.history.push(`/houses/${assetId}/${houseId}`);
  }

  goToHouseContractDetailPage(
    assetId: number,
    houseId: number,
    contractId: number
  ) {
    this.props.history.push(`/houses/${assetId}/${houseId}/${contractId}`);
  }

  render() {
    return (
      <DashboardPage>
        <form style={{ padding: '0' }} onSubmit={this.handleHouseSubmit}>
          <div className="d-flex">
            <div className="flex-fill">
              <label htmlFor="type">Property</label>
              <select
                disabled
                id="type"
                className="form-control"
                value={this.state.asset?.id}
                name="assetId"
              >
                <option value={this.state.asset?.id}>
                  {this.state.asset?.name}
                </option>
              </select>
            </div>

            <div className=" flex-fill">
              <label>House Number</label>
              <FormControl
                value={this.state.house_number}
                name="house_number"
                onChange={this.handleHouseNumberChange}
                type="text"
              />
            </div>
          </div>

          <div className="form-group form-check" style={{ marginTop: '1rem' }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              onChange={this.handleAddTenantChange}
            />
            <label className="form-check-label" htmlFor="exampleCheck1">
              Add tenant
            </label>
          </div>

          {/* extra form for tenant */}
          {this.state.addTenant ? (
            <TenantForm
              tenantRequest={this.state.tenantRequest}
              tenantFormChange={this.handleTenantFormChange}
            />
          ) : null}

          <div className="shika-small-padding">
            <Button
              className="umami--click--edit-house-button"
              data-cy="submit-house"
              type="submit"
              variant="primary"
            >
              Edit
            </Button>
          </div>
        </form>
      </DashboardPage>
    );
  }
}
