export function convertJsonToCSV(objArray) {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
  let str = ''

  for (let i = 0; i < array.length; i++) {
    let line = ''
    for (const index in array[i]) {
      if (line !== '') line += ','

      line += array[i][index]
    }

    str += line + '\r\n'
  }

  return str
}

export function convertCsvStringToArray(csvString) {
  const eachRow = csvString.split('\n')
  const headerColumns = eachRow[0].split(',')

  const rowsString = eachRow.slice(1)

  const completeArray = []

  for (const row of rowsString) {
    const tempObj = {}
    // tempObj[headerColumn] =
    const splitRow = row.split(',')

    console.log('splitRow', splitRow)

    if (splitRow.length < headerColumns.length) {
      continue
    }
    for (let i = 0; i < headerColumns.length; i++) {
      const headerColumn = headerColumns[i]
      tempObj[headerColumn] = splitRow[i]
    }
    completeArray.push(tempObj)
  }
  // debugger
  return completeArray
}

export function fromJsonApiToUploadInstance(jsonApiarray: any[]) {
  return jsonApiarray.map((uploadInstance) => ({
    id: uploadInstance.id,
    status: uploadInstance.attributes.status,
    uploaded_date: uploadInstance.attributes.uploaded_date,
    raw_upload_data: uploadInstance.attributes.raw_upload_data,
  }))
}
