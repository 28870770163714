import * as qs from 'qs';
import { PathLike } from 'fs';
import config from '../config';
import axios from 'axios';

export const apiConfig = {
  timeout: 30000,
  baseURL: config.BACKEND_SERVICE,
  validateStatus: function (status) {
    return status.toString().includes('20');
  },
  paramsSerializer: (params: PathLike) =>
    qs.stringify(params, { indices: false }),
};

export const baseAxios = axios.create(apiConfig); // export this and use it in all your components
