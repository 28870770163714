import React, { Component } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  submitForm: (state) => any;
}

interface State {
  email: string;
  password?: string;
}

export default class LoginForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const form = event.target.name;
    const state = {};
    state[form] = event.target.value;

    this.setState(state);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.submitForm(this.state);
  }

  render() {
    return (
      <div className="login-body">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email address</label>
            <input
              value={this.state.email}
              name="email"
              data-cy="email-input"
              onChange={this.handleChange}
              type="email"
              className="form-control"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Password</label>
            <input
              value={this.state.password}
              name="password"
              onChange={this.handleChange}
              type="password"
              className="form-control"
              data-cy="login-pass-input"
              placeholder="Password"
            />
          </div>

          {this.props.children}
          <div className="d-flex justify-content-between">
            <button
              type="submit"
              className="btn btn-primary umami--click--login-button"
            >
              Submit
            </button>
            <Link
              className="umami--click--forgot-password-link"
              to="/forgot-password"
            >
              forgot password
            </Link>
          </div>
        </form>
      </div>
    );
  }
}
