import React, { Component } from 'react';
import { Field } from 'react-final-form';
import DatePicker from 'react-datepicker';
import { required } from '../forms/validators';
import { SFormErrors } from '../forms/css/variables.styles';
import { PaymentDateTypeEnum } from '../enum/PaymentDateType.enum';

interface PaymentModalFormProps {
  initialData: any;
}

interface PaymentModalFormState {
  selectedPaymentDate?: Date;
  selectedDatePayed?: Date;
}

export enum PaymentType {
  Mpesa = 'MPESA',
  Bank = 'Bank',
  Other = 'Other',
}

export default class PaymentModalForm extends Component<
  PaymentModalFormProps,
  PaymentModalFormState
> {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(format, date) {
    if (format === PaymentDateTypeEnum.StartDate) {
      this.setState({
        selectedPaymentDate: date,
      });
    } else if (format === PaymentDateTypeEnum.DatePayed) {
      this.setState({
        selectedDatePayed: date,
      });
    }
    return true;
  }

  render() {
    return (
      <div>
        <div className="form-row">
          <Field
            initialValue={this.props.initialData?.startDate}
            name="startDate"
          >
            {({ input, meta }) => (
              <div className="form-group col-md-6">
                <div>Payment date</div>
                <DatePicker
                  {...input}
                  id="startDate"
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  className="form-control"
                  minDate={new Date(this.props.initialData.contractDate)}
                  onChange={(date) =>
                    this.handleDateChange('startDate', date) &&
                    input.onChange(
                      date ? date.toISOString().split('T')[0] : null
                    )
                  }
                />
                <SFormErrors>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </SFormErrors>
              </div>
            )}
          </Field>
          <Field
            name="datePayed"
            initialValue={this.props.initialData?.startDate}
          >
            {({ input, meta }) => (
              <div className="form-group col-md-6">
                <div>Date payed</div>
                <DatePicker
                  {...input}
                  id="datePayed"
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  className="form-control"
                  onChange={(date) =>
                    this.handleDateChange('datePayed', date) &&
                    input.onChange(
                      date ? date.toISOString().split('T')[0] : null
                    )
                  }
                />
                <SFormErrors>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </SFormErrors>
              </div>
            )}
          </Field>
        </div>
        <div className="form-row">
          <Field
            name="amount"
            validate={required}
            initialValue={this.props.initialData.amount}
          >
            {({ input, meta }) => (
              <div className="form-group col-md-4">
                <label htmlFor="type">Amount</label>
                <input
                  {...input}
                  type="text"
                  className="form-control"
                  placeholder="Amount"
                />
                <SFormErrors>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </SFormErrors>
              </div>
            )}
          </Field>
          <Field name="type" validate={required}>
            {({ input, meta }) => (
              <div className="form-group  col-md-4">
                <label htmlFor="type">type</label>

                <select {...input} id="type" className="form-control">
                  <option></option>
                  {[PaymentType.Mpesa, PaymentType.Bank, PaymentType.Other].map(
                    (type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    )
                  )}
                </select>
                <SFormErrors>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </SFormErrors>
              </div>
            )}
          </Field>
          <Field name="paymentCode" validate={required}>
            {({ input, meta }) => (
              <div className="form-group  col-md-4">
                <label htmlFor="paymentCode">Reference</label>
                <input {...input} type="text" className="form-control" />
                <SFormErrors>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </SFormErrors>
              </div>
            )}
          </Field>
        </div>
      </div>
    );
  }
}
