import './alert.css';
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class Alert extends Component {
  componentDidMount() {
    setTimeout(
      ((n) => () => {
        this.props.deleteMessage(this.props.message.id);
      })(2),
      7000
    );
  }

  render() {
    return (
      <div className={`alert ${this.props.message.type}`}>
        <span
          onClick={() => this.props.deleteMessage(this.props.message.id)}
          className="closebtn"
        >
          ×
        </span>
        <strong>{this.props.message.type}!</strong> {this.props.message.text}
      </div>
    );
  }
}

Alert.propTypes = {
  deleteMessage: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
};

export default Alert;
