// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { EnvironmentInterface } from './environment.interface'

export const environment: EnvironmentInterface = {
  production: false,
  gAnalyticsTag: '291050377',
  sentryEnvironment: 'local',
  environment: 'local',
}
