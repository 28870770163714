import React, { ReactElement } from 'react';
import RouteWithSubRoutes from '../../../RouteWithSubRoutes';
import { Redirect, Switch } from 'react-router-dom';

interface Props {
  routes: any[];
}

export default function InvoicesContainer({ routes }: Props): ReactElement {
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} exact />
      ))}
      <Redirect from="/" to={routes[0].path} />
    </Switch>
  );
}
