import React, { Component } from 'react';
import MessagesContext from '../../../utils/MessagesContext';
import { FormControl, Button } from 'react-bootstrap';
import { assetsApi } from '../../../utils/assets-api';
import {
  AssetResponse,
  HouseRequest,
  TenantRequest,
} from '../accountPage/helpers';
import TenantForm from './TenantForm';
import { validatePhoneNumber } from '../../shared/helpers/helpers';
import { PaPageRoute } from '../assetPage/PaPageRoute';
import { setGALocation } from '../overview/utils';

interface Props {
  history: any;
}
interface State {
  addTenant: boolean;
  assets?: AssetResponse[];
  houseRequest?: HouseRequest;
  tenantRequest?: TenantRequest;
  selectedAssetId?: number;
  houseNumber?: string;
  toggleTenantForm?: string;
}

class CreateHousePage extends Component<Props, State> {
  static contextType = MessagesContext;
  customPageRoute: any;
  constructor(props) {
    super(props);
    this.state = {
      addTenant: true,
    };
    this.customPageRoute = new PaPageRoute(this.props);

    this.handleHouseSubmit = this.handleHouseSubmit.bind(this);
    this.selectAsset = this.selectAsset.bind(this);
    this.handleHouseNumberChange = this.handleHouseNumberChange.bind(this);
    this.handleAddTenantChange = this.handleAddTenantChange.bind(this);
    this.handleTenantFormChange = this.handleTenantFormChange.bind(this);
    this.goToHouseDetailPage = this.goToHouseDetailPage.bind(this);
  }

  componentDidMount() {
    setGALocation();
    const pageParams = this.customPageRoute.getAllRouteParams();

    assetsApi.getAssets().then((assets) => {
      this.setState({ assets: assets });

      if (pageParams.asset) {
        const selectedAsset = assets.find(
          (asset) => asset.id === parseInt(pageParams.asset)
        );
        if (selectedAsset) {
          this.setState({ selectedAssetId: selectedAsset.id });
          return;
        } else {
          this.context.setmessage('info', 'asset not found');
        }
      }
      if (assets.length > 0) {
        this.setState({ selectedAssetId: assets[0].id });
      }
    });

    this.setState({ tenantRequest: { period: 'monthly' } });
  }

  selectAsset(event) {
    this.setState({ selectedAssetId: event.target.value });
  }

  handleAddTenantChange(event) {
    this.setState({ addTenant: event.target.checked });
  }

  handleTenantFormChange(tenantRequest) {
    this.setState({ tenantRequest });
  }

  handleHouseNumberChange(event) {
    this.setState({ houseNumber: event.target.value });
  }

  handleHouseSubmit(event) {
    event.preventDefault();
    if (!this.state.selectedAssetId) {
      this.context.setmessage('info', 'asset must not be empty');
      return;
    }

    if (!this.state.houseNumber) {
      this.context.setmessage('info', 'house number must not be empty');
      return;
    }

    const houseRequest: { house_number; tenant?: TenantRequest } = {
      house_number: this.state.houseNumber,
    };
    if (this.state.addTenant) {
      // if there is a phone number but the number is not valid phone number
      if (
        this.state.tenantRequest?.phone_number &&
        !validatePhoneNumber(this.state.tenantRequest.phone_number)
      ) {
        this.context.setmessage('info', 'invalid phone number');
        return;
      } else if (
        this.state.tenantRequest?.phone_number &&
        validatePhoneNumber(this.state.tenantRequest.phone_number)
      ) {
        houseRequest.tenant = { ...this.state.tenantRequest };
      } else {
        houseRequest.tenant = this.state.tenantRequest;
      }
    }

    assetsApi
      .createHouseDetail(this.state.selectedAssetId, houseRequest)
      .then((houseResponse) => {
        if (houseResponse.occupancy === 'occupied') {
          this.context.setmessage(
            'success',
            'house  and tenant created successfully'
          );
          this.goToHouseDetailPage(houseResponse.asset.id, houseResponse.id);
          return;
        }
        this.context.setmessage('success', 'house created successfully');
        this.goToHouseDetailPage(houseResponse.asset.id, houseResponse.id);
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          for (const error of Object.values(response.data.errors)) {
            this.context.setmessage('error', error);
          }
        } else if (response.data.message) {
          this.context.setmessage('error', response.data.message);
        }
      });
  }

  goToHouseDetailPage(assetId: number, houseId: number) {
    this.props.history.push(`/houses/${assetId}/${houseId}`);
  }

  render() {
    return (
      <div style={{ padding: '20px' }}>
        <div className="Card-Title card-hed" data-cy="create-house-title">
          Create House
        </div>
        <div
          style={{
            background: 'white',
            padding: '20px',
            boxShadow:
              '0 1px 5px 0px rgba(0,0,0,0.3), 0 1px 5px 0px rgba(0,0,0,0.15)',
          }}
        >
          <form style={{ padding: '0' }} onSubmit={this.handleHouseSubmit}>
            <div className="d-flex">
              <div className="flex-fill">
                <label htmlFor="type">Property</label>
                <select
                  id="type"
                  className="form-control"
                  value={this.state.selectedAssetId}
                  name="assetId"
                  onChange={this.selectAsset}
                >
                  {this.state.assets?.map((asset, i) => (
                    <option key={i} value={asset.id}>
                      {asset.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" flex-fill">
                <label>House Number</label>
                <FormControl
                  value={this.state.houseNumber}
                  name="house_number"
                  onChange={this.handleHouseNumberChange}
                  type="text"
                  data-cy="house-number-input"
                />
              </div>
            </div>

            <div
              className="form-group form-check"
              style={{ marginTop: '1rem' }}
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                onChange={this.handleAddTenantChange}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Add tenant
              </label>
            </div>
            {/* extra form for tenant */}
            {this.state.addTenant ? (
              <TenantForm
                tenantRequest={this.state.tenantRequest}
                tenantFormChange={this.handleTenantFormChange}
              />
            ) : null}

            <div className="shika-small-padding">
              <Button
                className="umami--click--create-house-button"
                type="submit"
                variant="primary"
                data-cy="create-house-button"
              >
                Create House
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CreateHousePage;
