import { Field, Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { required } from '../../../lib/forms/validators'
import { SFormErrors } from '../../../lib/forms/css/variables.styles'
import React from 'react'
import { PaymentTdWithDatePickerFFInput, PaymentTdWithFFInput } from '../../../lib/PageLayouts/ExampleFinalFormArray'
import { PaymentType } from '../../../lib/enum/PaymentType'

interface PaymentsInvoiceItemTableFormProps {
  onSubmit: (value: any) => void
  onDeleteInvoicePayment: (value: any) => void
  formDisabled?: boolean
  formSubmitted?: boolean
  tableInitialValues: any[]
}

export const PaymentsInvoiceItemTableForm = (props: PaymentsInvoiceItemTableFormProps) => {
  return (
    <Form
      onSubmit={props.onSubmit}
      mutators={{
        // potentially other mutators could be merged here
        ...arrayMutators,
      }}
      // validate={validate}
      initialValues={{ payments: props.tableInitialValues }}
      render={({ handleSubmit, pristine, invalid }) => (
        <form className="form-group p-0" onSubmit={handleSubmit}>
          <FieldArray name="payments">
            {({ fields, meta }) => (
              <>
                <div className="d-flex p-2 w-100 justify-content-between">
                  <h6 className="font-weight-bold text-justify">Payments</h6>
                  {!props.formSubmitted ? (
                    <button className="btn btn-secondary" type="button" onClick={() => fields.push({ newItem: true })}>
                      Add
                    </button>
                  ) : null}
                </div>
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th scope="col">Date payed</th>
                      <th scope="col">Type</th>
                      <th scope="col">Payment code</th>
                      <th scope="col">Amount</th>
                      <th scope="col" className="text-right">
                        actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((name, index) => (
                      <tr key={index}>
                        <PaymentTdWithDatePickerFFInput
                          name={`${name}.date_payed`}
                          formSubmitted={!(!props.formSubmitted && fields.value[index].newItem)}
                          inputOnChange={() => {
                            fields.update(index, {
                              ...fields.value[index],
                              edited: true,
                            })
                          }}
                        />

                        <td>
                          <Field
                            name={`${name}.type`}
                            component="select"
                            className={'form-control form-control-sm'}
                            validate={required}
                          >
                            {({ input, meta }) => (
                              <>
                                <select
                                  {...input}
                                  disabled={!(!props.formSubmitted && fields.value[index].newItem)}
                                  className={'form-control form-control-sm'}
                                >
                                  <option />
                                  <option value={PaymentType.Mpesa}> Mpesa </option>
                                  <option value={PaymentType.Bank}> Bank </option>
                                  <option value={PaymentType.Other}> Cash </option>
                                </select>
                                <SFormErrors>{meta.error && meta.touched && <span>{meta.error}</span>}</SFormErrors>
                              </>
                            )}
                          </Field>
                        </td>

                        <PaymentTdWithFFInput
                          name={`${name}.payment_code`}
                          formSubmitted={!(!props.formSubmitted && fields.value[index].newItem)}
                          inputOnChange={() => {
                            fields.update(index, {
                              ...fields.value[index],
                              edited: true,
                            })
                          }}
                        />
                        <td>
                          <Field name={`${name}.amount`} type="number" validate={required}>
                            {({ input, meta }) => (
                              <>
                                <input
                                  {...input}
                                  disabled={!(!props.formSubmitted && fields.value[index].newItem)}
                                  className={`form-control form-control-sm border ${
                                    meta.dirty && 'rounded border-success'
                                  }`}
                                  onChange={(event) => {
                                    fields.update(index, {
                                      ...fields.value[index],
                                      edited: true,
                                    })
                                    input.onChange(event)
                                    console.log('meta', meta)
                                  }}
                                />
                                <SFormErrors>{meta.error && meta.touched && <span>{meta.error}</span>}</SFormErrors>
                              </>
                            )}
                          </Field>
                        </td>
                        <td className="d-flex flex-row-reverse">
                          {!props.formSubmitted ? (
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={() => fields.remove(index) && props.onDeleteInvoicePayment(fields.value[index])}
                            >
                              Remove
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!props.formSubmitted && !!fields.value.find((item) => item.newItem) ? (
                  <button className="btn btn-secondary" type="submit">
                    update
                  </button>
                ) : null}
              </>
            )}
          </FieldArray>
        </form>
      )}
    />
  )
}
