import React from 'react';
import { SECTION_PADDING } from '../../../lib/Table/cssVariables';
import DatatablePagination from '../invoices/DatatablePagination';
import * as H from 'history';
import { useHistory } from 'react-router-dom';
import { PaginationUtil } from '../../shared/utils/PaginationUtil';
import { TdWithLink } from '../../../lib/Table/TrWithLink';

export const ExpensesDatatable = (props) => {
  const history: H.History = useHistory();
  const paginationUtil = new PaginationUtil(history);
  const handlePageSizeChange = (event) => {
    paginationUtil.updatePaginationParams({
      size: parseInt(event.target.value),
    });
  };

  return (
    <div>
      <div
        style={{
          padding: SECTION_PADDING,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="d-flex justify-content-between"
      >
        <div style={{ display: 'flex' }}>
          <div>Show</div>
          <select
            value={props.expensesPage.pagination.size}
            onChange={handlePageSizeChange}
            style={{ width: '80px', margin: '0 10px' }}
            className="custom-select custom-select-sm"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <div>records per page</div>
        </div>
        <div>
          <input type="text" className="form-control form-control-sm" />
        </div>
      </div>
      {props.expensesPage ? (
        <div className="table-responsive-md">
          <table className="table thead-dark table-sm">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Recurring</th>
                <th scope="col">Required</th>
                <th scope="col">Amount (KSH)</th>
                <th scope="col">Asset</th>
              </tr>
            </thead>
            <tbody>
              {props.expensesPage.items.map((expense, i) => (
                <tr key={i}>
                 <th>{i + 1}</th>
                 <TdWithLink link={`expenses/${expense.asset_id}/${expense.id}`}>{expense.name}</TdWithLink>
                 <TdWithLink link={`expenses/${expense.asset_id}/${expense.id}`}>{expense.status}</TdWithLink>
                 <TdWithLink link={`expenses/${expense.asset_id}/${expense.id}`}>{expense.is_recurring ? 'Yes' : 'No'}</TdWithLink>
                 <TdWithLink link={`expenses/${expense.asset_id}/${expense.id}`}>{expense.is_required ? 'Yes' : 'No'}</TdWithLink>
                 <TdWithLink link={`expenses/${expense.asset_id}/${expense.id}`}>{expense.expected_unit_amount}</TdWithLink>
                 <TdWithLink link={`expenses/${expense.asset_id}/${expense.id}`}>{expense.asset?.name}</TdWithLink>
                </tr>
              ))}
            </tbody>
          </table>
          <DatatablePagination pagination={props.expensesPage.pagination} />
        </div>
      ) : null}
    </div>
  );
};
