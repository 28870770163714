import styled from 'styled-components';
import React from 'react';
import { createPortal } from 'react-dom';

const SModalOverlay = styled.div`
  background-color: #999999;
  height: 100vh;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 500;
`;

const SModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 5%;
  width: 100%;
  z-index: 1000;
`;

const SModal = styled.div`
  align-items: center;
  background: white;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin: 1.875rem;
  min-width: 500px;
  position: relative;
  z-index: 100;
  padding: 2rem 2rem 2rem 2rem;
`;

export const STitle = styled.h5`
  margin-bottom: 0.3125rem;
  color: black;
`;

export const ModalActions = styled.div`
  display: flex;
  width: 100%;
`;

const Modal = ({ isVisible, hideModal, children }) => {
  return isVisible
    ? createPortal(
        <React.Fragment>
          <SModalOverlay />
          <SModalWrapper
            aria-modal={true}
            aria-hidden={true}
            tabIndex={-1}
            role="dialog"
          >
            <SModal>{children}</SModal>
          </SModalWrapper>
        </React.Fragment>,
        document.body
      )
    : null;
};

export default Modal;
