import {
  AssetResponse,
  ContractResponse,
  ContractResponseV2,
  HouseResponse,
  InvoiceDetailsResponse,
  InvoiceResponse,
  PaginationPage,
  PaymentFormRequest,
  PaymentResponse,
  expenseResponse,
} from '../dashboard/pages/accountPage/helpers'
import { AxiosInstance, AxiosResponse } from 'axios'

import { AuthApi } from './api'
import { ExpensesResponse } from '../dashboard/pages/assetPage/AssetExpensesTabsPage'
import { PaginationParamsInterface } from '../dashboard/shared/interface'
import { baseAxios } from './api.config'

export class AssetsApi extends AuthApi {
  public constructor(baseAxios?: AxiosInstance) {
    super(baseAxios)

    this.findAllHouses = this.findAllHouses.bind(this)
    this.getAssets = this.getAssets.bind(this)
    this.getAsset = this.getAsset.bind(this)
    this.createHouse = this.createHouse.bind(this)
    this.fetchAssetsGraphData = this.fetchAssetsGraphData.bind(this)
  }

  public findAllHouses(assetIds, page, size) {
    const params = { assets: assetIds }
    return this.get(`/v1/houses?size=${size}&page=${page}`, {
      params,
    }).then(this.success)
  }

  public fetchAssetsGraphData(assetIds, date) {
    const params = { assets: assetIds, selectedDate: date }
    return this.get(`/v1/assets/graphs`, { params }).then(this.sucess)
  }

  public fetchIncomeReport(assetIds, startDate, endDate) {
    const params = { startDate: startDate, endDate: endDate }
    return this.get(`/reports/reports`, { params }).then(this.sucess)
  }

  public findPayments(assetIds, startDate, page, size) {
    const params = {
      assets: assetIds,
      startDate: startDate,
    }
    return this.get(`/v1/reports?size=${size}&page=${page}`, {
      params,
    }).then(this.success)
  }

  public getAssets(): Promise<AssetResponse[]> {
    return this.get<AssetResponse[]>(`/v1/assets`).then(this.success)
  }

  public getAssetsWithSummary(): Promise<AssetResponse[]> {
    return this.get<AssetResponse[]>(`/v1/assets`, {
      headers: { 'summary-json': 1 },
    }).then(this.success)
  }

  public getAssetsWithSummaryAndContracts(): Promise<AssetResponse[]> {
    return this.get<AssetResponse[]>(`/v1/assets`, {
      headers: { 'minimal-with-houses-and-contract-json': 1 },
    }).then(this.success)
  }
  public getAssetsWithHousesAndSummaryAndContracts(): Promise<AssetResponse[]> {
    return this.get<AssetResponse[]>(`/v1/assets`, {
      headers: {
        'minimal-with-houses-and-houses-aggregate-and-contract-json': 1,
      },
    }).then(this.success)
  }

  public getAsset(assetId): Promise<AssetResponse> {
    return this.get<AssetResponse>(`/v1/assets/${assetId}`).then(this.success)
  }

  public getAssetWithSummary(assetId): Promise<AssetResponse> {
    return this.get<AssetResponse>(`/v1/assets/${assetId}`, {
      headers: { 'summary-json': 1 },
    }).then(this.success)
  }

  public getAssetHouses(assetId, page, size): Promise<PaginationPage<HouseResponse>> {
    return this.get<PaginationPage<HouseResponse>>(`/v1/assets/${assetId}/houses?size=${size}&page=${page}`).then(
      this.success,
    )
  }

  public createHouse(houseRequest: any): Promise<any> {
    return this.post<string, any, AxiosResponse<string>>(`/v1/houses`, houseRequest)
      .then(this.success)
      .catch(this.error)
  }

  public createHouseDetail(assetId, houseRequest: any): Promise<HouseResponse> {
    return this.post<string, any, AxiosResponse<HouseResponse>>(`/v1/assets/${assetId}/houses`, houseRequest).then(
      this.success,
    )
  }

  public updateHouse(assetId, houseId, houseRequest: any): Promise<HouseResponse> {
    return this.put<string, any, AxiosResponse<HouseResponse>>(
      `/v1/assets/${assetId}/houses/${houseId}`,
      houseRequest,
    ).then(this.success)
  }

  public findAssetHouse(asset_id, house_id): Promise<HouseResponse> {
    return this.get<HouseResponse>(`/v1/assets/${asset_id}/houses/${house_id}`).then(this.success)
  }
  public findContractMissingPayments(assetId, houseId, contractId): Promise<any> {
    return this.get<any[]>(`/v1/assets/${assetId}/houses/${houseId}/contract/${contractId}/missing-payments`).then(
      this.success,
    )
  }

  public getPayments(
    assetId: number,
    houseId: number,
    contractId: number,
    page,
    size,
  ): Promise<PaginationPage<PaymentResponse>> {
    return this.get<PaginationPage<PaymentResponse>>(
      `/v1/assets/${assetId}/houses/${houseId}/contract/${contractId}/payments?size=${size}&page=${page}`,
    ).then(this.success)
  }

  public createAsset(assetRequest: any): Promise<AssetResponse> {
    return this.post<string, any, AxiosResponse<AssetResponse>>(`/v1/assets`, assetRequest).then(this.success)
  }

  public createPayment(
    assetId: number,
    houseId: number,
    contractId: number,
    paymentRequest: PaymentFormRequest,
  ): Promise<PaymentResponse> {
    return this.post<string, PaymentFormRequest, AxiosResponse<PaymentResponse>>(
      `/v1/assets/${assetId}/houses/${houseId}/contract/${contractId}/payments`,
      paymentRequest,
    ).then(this.success)
  }

  public updateAsset(asset_id, assetRequest: any): Promise<any> {
    return this.put<string, any, AxiosResponse<string>>(`/v1/assets/${asset_id}`, assetRequest).then(this.success)
  }

  public sendSms(contractId: number, sms_string: string): Promise<any> {
    const smsRequest = {
      contract_id: contractId,
      sms_payload: sms_string,
    }
    return this.post<string, any, AxiosResponse<any>>(`/notifications/sms-events`, smsRequest).then(this.success)
  }

  /**
   * getHousePayments
   */
  public getHousePayments(assetId, date) {
    return this.get(`/v1/reports/${assetId}?date=${date}`).then(this.success)
  }

  public deleteHouse(assetId, houseId, tenantId): Promise<any> {
    return this.delete<any>(`/v1/assets/${assetId}/houses/${houseId}/tenant/${tenantId}`).then(this.success)
  }

  public fetchInvoicesDateRangeSummary(assetIds: number[], startDate, endDate): Promise<any[]> {
    const params = {
      assets: assetIds,
      startDate: startDate.toLocaleDateString(),
      endDate: endDate.toLocaleDateString(),
    }
    return this.get<any[]>(`/invoices/date-range-summary`, {
      params,
    }).then(this.success)
  }

  public v2GetAssets(): Promise<PaginationPage<AssetResponse>> {
    return this.get<PaginationPage<AssetResponse>>(`/v2/assets?size=100`, {
      headers: { 'minimal-with-houses-json': 1 },
    }).then(this.success)
  }

  public getInvoiceDetails(invoiceId): Promise<InvoiceDetailsResponse> {
    return this.get<InvoiceDetailsResponse>(`/invoices/invoices/${invoiceId}`).then(this.success)
  }

  public fetchAllInvoices(paginationParams: PaginationParamsInterface): Promise<PaginationPage<AssetResponse>> {
    return this.get<PaginationPage<AssetResponse>>(`/invoices/invoices`, {
      params: paginationParams,
    }).then(this.success)
  }

  public fetchInvoicesByContract(contract_id): Promise<PaginationPage<AssetResponse>> {
    return this.get<PaginationPage<AssetResponse>>(`/invoices/invoices`, {
      params: { contracts: contract_id },
    }).then(this.success)
  }

  public fetchAllMissingInvoices(startDate: any, endDate: any): Promise<PaginationPage<AssetResponse>> {
    return this.get<PaginationPage<AssetResponse>>(`/invoices/missing_invoices`, {
      params: { startDate: startDate, endDate: endDate },
    }).then(this.success)
  }

  public fetchAllContracts(
    paginationParams: PaginationParamsInterface,
    houseId: number,
  ): Promise<PaginationPage<ContractResponseV2>> {
    return this.get<PaginationPage<ContractResponseV2>>(`/v2/contracts`, {
      params: paginationParams,
    }).then(this.success)
  }

  public fetchHouseContracts(
    paginationParams: PaginationParamsInterface,
    houseId: number,
    assetId: number,
  ): Promise<PaginationPage<ContractResponseV2>> {
    return this.get<PaginationPage<ContractResponseV2>>(`/v1/assets/${assetId}/houses/${houseId}/contracts`, {
    // return this.get<PaginationPage<ContractResponseV2>>(`/v2/contracts`, {
      params: paginationParams,
    }).then(this.success)
  }

  public createNewInvoice(contractId, invoiceDate): Promise<InvoiceResponse> {
    return this.post<InvoiceResponse, any>(`/invoices/contracts/${contractId}/invoices`, {
      invoice_date: invoiceDate,
    }).then(this.success)
  }

  public v2GetContractsByAsset(assetId: number): Promise<PaginationPage<ContractResponse>> {
    return this.get<PaginationPage<ContractResponse>>(`/v2/contracts?assets=${assetId}`).then(this.success)
  }

  public getExpensesByAssetId(assetId: number): Promise<PaginationPage<expenseResponse>> {
    return this.get<PaginationPage<expenseResponse>>(`/expenses/expenses?assets=${assetId}`).then(this.success)
  }

  /**
   * Get Expenses
   * @returns list of expenses
   */
  public getExpenses(assetList: number[], paginationParams): Promise<ExpensesResponse> {
    return this.get<ExpensesResponse>(`/expenses/expenses`, {
      params: { assets: assetList, ...paginationParams },
    }).then(this.success)
  }

  /**
   * Get Upload Instances
   * @returns list of expenses
   */
  public fetchAllUploadInstances(paginationParams): Promise<ExpensesResponse> {
    return this.get<ExpensesResponse>(`/agent/upload-instances`, {
      params: { ...paginationParams },
    })

      .then(this.success)

      .then((response) => {
        return response
      })
  }

  /**
   * Get Upload Instances
   */
  public fetchUploadInstanceById(uploadInstanceId): Promise<ExpensesResponse> {
    return this.get<any>(`/agent/upload-instances/${uploadInstanceId}`).then(this.success)
  }

  /**
   * Get Upload Instances
   */
  public initializeBulkSave(uploadInstanceId): Promise<any> {
    return this.post<string, any>(`/v2/houses-bulk/${uploadInstanceId}/start`, {})
  }

  public createNewUploadInstance(uploadPayload): Promise<InvoiceResponse> {
    return this.post<InvoiceResponse, any>(`/v2/houses-bulk`, uploadPayload).then(this.success)
  }
  /**
   * Get Expenses
   * @returns list of expenses
   */
  public getExpenseDetails(assetId: number, expenseId: number): Promise<ExpensesResponse> {
    return this.get<ExpensesResponse>(`/v1/assets/${assetId}/expenses/${expenseId}`).then(this.success)
  }
}

export const assetsApi = new AssetsApi(baseAxios)
