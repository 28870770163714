import React, { Component } from 'react';
import dateFormat from '../../shared/helpers/dateFormat';
import SimplePagination from '../../components/SimplePagination';
import { PaPageRoute } from './PaPageRoute';
import {
  PageParams,
  PaginationPage,
  getAllRouteParams,
  PaymentResponse,
} from '../accountPage/helpers';
import { assetsApi } from '../../../utils/assets-api';
import DatePicker from 'react-datepicker';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocument from './PdfDocument';
import { setGALocation } from '../overview/utils';
import PageSection from '../../../lib/dashboard-page/PageSection';

interface Props {
  history: any[];
  location;
}

interface State {
  asset_id: number;
  payments?: PaginationPage<PaymentResponse>;
  startDate: Date;
  show?: boolean;
  receiptsPages?: any[];
}

export default class AssetReceiptTabsPage extends Component<Props, State> {
  customPageRoute: PaPageRoute;

  constructor(props) {
    super(props);
    this.state = {
      asset_id: JSON.parse(props.match.params.id),
      startDate: new Date(),
      show: false,
    };
    this.customPageRoute = new PaPageRoute(this.props);
  }
  componentDidMount() {
    setGALocation();
    this.customPageRoute.resetPaginationParams();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const queries: PageParams = this.customPageRoute.getPageParams(
        this.props.location
      );

      this.fetchPayments(this.state.startDate, queries.page, queries.size);
    }
  }

  updatePageSize(size) {
    const previousPageParams = this.customPageRoute.getPageParams(
      this.props.location
    );
    const newPage = {
      page: previousPageParams.page,
      size: size,
    };
    this.customPageRoute.updatePageParams(newPage);
  }

  updatePage(page) {
    const previousPageParams = this.customPageRoute.getPageParams2(
      this.props.location
    );

    const newPage = {
      page: page,
      size: previousPageParams.size,
    };
    this.customPageRoute.updatePageParams(newPage);
  }

  fetchPayments = (startDate, page, size) => {
    assetsApi
      .findPayments([this.state.asset_id], startDate, page, size)
      .then((paymentsPage: any) => {
        this.setState({
          receiptsPages: this.paymentsToPdfReceipts(paymentsPage.items),
        });
        if (paymentsPage.items.length > 0) {
          this.setState({
            show: true,
            payments: paymentsPage,
          });
        } else {
          this.setState({
            payments: paymentsPage,
            show: false,
          });
        }
      });
  };

  // todo needs tests
  paymentsToPdfReceipts(payments: any[]): any[][] {
    const pageObjects = new Map();

    for (let index = 0; index < payments.length; index++) {
      const element = payments[index];
      const currentPage = Math.trunc(index / 5);
      if (pageObjects.has(currentPage)) {
        pageObjects.get(currentPage).push(element);
      } else {
        pageObjects.set(currentPage, [element]);
      }
    }

    return Array.from(pageObjects, ([key, value]) => value);
  }

  handleDateChange = (date: Date) => {
    this.setState({ startDate: date });
    const queries: PageParams = getAllRouteParams(this.props.location);

    if (this.state.asset_id) {
      this.fetchPayments(date, queries.page, queries.size);
    }
  };

  render() {
    return (
      <PageSection>
        <div className="d-flex justify-content-between mt-3">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
            className="form-group pr-2"
          >
            <label>
              {this.state.show
                ? 'Click to download'
                : 'No payments found, please select a date'}
            </label>
            {this.state.show && (
              <PDFDownloadLink
                document={<PdfDocument receipts={this.state.receiptsPages} />}
                fileName={`Payments-${this.state.startDate}-${this.state.asset_id}.pdf`}
                className="btn btn-primary"
              >
                {({ blob, url, loading, error }) =>
                  loading ? 'Loading document...' : 'Download Pdf'
                }
              </PDFDownloadLink>
            )}
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column' }}
            className="form-group report-datepicker"
          >
            <label>start date</label>
            <DatePicker
              id="startDate"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="form-control"
              selected={this.state.startDate}
              onChange={(date) => this.handleDateChange(date)}
            />
          </div>
        </div>

        <div
          className="d-flex justify-content-between"
          style={{ padding: '1em 1rem 0 1rem' }}
        >
          <div className="h5 font-weight-bold"></div>
        </div>
        <div className="asset-houses-stats" style={{ overflow: 'scroll' }}>
          <table className="table">
            <thead>
              <tr>
                <th className="th-slim" scope="col">
                  #
                </th>
                <th className="th-slim" scope="col">
                  House Number
                </th>
                <th className="th-slim" scope="col">
                  Full Names
                </th>
                <th className="th-slim" scope="col">
                  For Date
                </th>
                <th className="th-slim" scope="col">
                  Rent
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.payments?.items?.map((item, index) => (
                <tr key={index}>
                  <td id={`row-${index}`}>{index + 1}</td>
                  <td className="houseNumber">{item.house?.house_number}</td>
                  <td>{item.house?.tenant?.tenant?.full_names}</td>
                  <td>{dateFormat(item.payment_date)}</td>
                  <td>{item.rent}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <SimplePagination
            goToNext={(nextPage) => this.updatePage(nextPage)}
            goToPrev={(prevPage) => this.updatePage(prevPage)}
            changePageSize={(size) => this.updatePageSize(size)}
            pagination={this.state.payments?.pagination}
          />
        </div>
      </PageSection>
    );
  }
}
