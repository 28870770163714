import React, { ReactElement, useEffect, useState } from 'react'
import { StyledBreadcrumb, StyledPageNav, StyledPageNavRight } from '../../../lib/styled'
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'
import PageSection from '../../../lib/dashboard-page/PageSection'
import { Link, useHistory } from 'react-router-dom'
import { SECTION_PADDING } from '../../../lib/Table/cssVariables'
import { assetsApi } from '../../../utils/assets-api'
import { convertSearchToRouteParams } from '../accountPage/helpers'
import * as H from 'history'
import { PaginationUtil } from '../../shared/utils/PaginationUtil'
import { FaBook } from 'react-icons/fa'
import UploadInstanceTable from './UploadInstanceTable'
import { fromJsonApiToUploadInstance } from './utils'

export default function UploadInstancePage(): ReactElement {
  const history: H.History = useHistory()
  const paginationUtil = new PaginationUtil(history)
  const [uploadInstancesPage, setUploadInstancesPage] = useState<any>()
  const [uploadInstancesResponse, setUploadInstancesResponsePage] = useState<any>()

  useEffect(() => {
    fetchAllUploadInstances(convertSearchToRouteParams(history.location.search))
  }, [history.location.search])

  const fetchAllUploadInstances = (paginationParams = {}) => {
    assetsApi.fetchAllUploadInstances(paginationParams).then((uploadInstances: any) => {
      setUploadInstancesPage(fromJsonApiToUploadInstance(uploadInstances.data))
      setUploadInstancesResponsePage(uploadInstances)
    })
  }

  const handlePageSizeChange = (event) => {
    paginationUtil.updatePaginationParams({
      size: parseInt(event.target.value),
    })
  }

  return (
    <DashboardPage2 hideTitle={true}>
      <StyledPageNav>
        <StyledBreadcrumb>
          <BreadcrumbItem
            active
            title={'upload instances'}
            iconComponent={
              <a target="_blank" href={'https://docs.proassets.co.ke/blog/2021/09/18/invoice'} rel="noreferrer">
                <FaBook />
              </a>
            }
          />
        </StyledBreadcrumb>
        <StyledPageNavRight>
          <Link data-cy="create-upload" className="btn btn-primary" to={`/upload-instances/create`}>
            start upload
          </Link>
        </StyledPageNavRight>
      </StyledPageNav>

      <PageSection>
        <div>
          <div
            style={{
              padding: SECTION_PADDING,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className="d-flex justify-content-between"
          >
            <div style={{ display: 'flex' }}>
              <div>Show</div>
              <select
                value={uploadInstancesResponse?.meta.count}
                onChange={handlePageSizeChange}
                style={{ width: '80px', margin: '0 10px' }}
                className="custom-select custom-select-sm"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <div>records per page</div>
            </div>
            <div>
              <input type="text" className="form-control form-control-sm" />
            </div>
          </div>

          <UploadInstanceTable items={uploadInstancesPage} />
          {/*{invoicesPage ? <DatatablePagination pagination={invoicesPage?.pagination} /> : null}*/}

          {/*invoicesPage? <DatatablePagination pagination={invoicesPage.pagination} /> : null*/}
        </div>
      </PageSection>
    </DashboardPage2>
  )
}
