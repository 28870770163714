import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './login.css';
import ResetPasswordForm from './ResetPasswordForm';
import { userApi } from '../utils/user-api';
import MessagesContext from '../utils/MessagesContext';
import qs from 'qs';

export interface ResetPasswordInterface {
  password: string;
  repeatPassword: string;
}

interface Props {
  location: any;
  history: any[];
}

export default class ResetPassword extends Component<Props, unknown> {
  static contextType = MessagesContext;
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    localStorage.clear();
  }

  handleFormSubmit(formData: ResetPasswordInterface) {
    const token = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).token;
    if (formData.password !== formData.repeatPassword) {
      this.context.setmessage('info', 'passwords do not match');
      return;
    }

    if (formData.password.length < 5) {
      this.context.setmessage('info', 'weak password');
    }

    userApi
      .resetPassword({ password: formData.password }, token)
      .then(
        (userPayload: { access_token: string; accounts: any[]; user: any }) => {
          this.setState({ userPayload: userPayload });
          this.context.setmessage('success', 'Reset successfully');
          this.props.history.push('/login');
        }
      );
  }

  render() {
    return (
      <div>
        <div className="login">
          <div className="login-card">
            <div className="register-header">Reset password</div>
            <ResetPasswordForm
              submitForm={this.handleFormSubmit}
            ></ResetPasswordForm>

            <div className="login-request">
              <p> go to login? </p>
              <Link className="umami--click--login-link" to="/login">
                login
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
