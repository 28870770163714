import React, { Component } from 'react'
import { calculateActualExpectedTotals } from './utils'
import { ActualExpenseChartDatasetInterface } from './interfaces'
import ActualExpectedIncomeChart from './ActualExpectedIncomeChart'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  BarChart,
  Bar,
  Line,
  ResponsiveContainer,
} from 'recharts'


interface Props {
  assetsIncomeSummaries: any[]
}

interface State {
  assetsIncomeSummaries: ActualExpenseChartDatasetInterface[]
  expectedIncome?: number
  actualIncome?: number
}

export class AssestIncomeBarGraph extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      assetsIncomeSummaries: [],
    }
  }

  componentDidMount() {
    this.setState({
      ...calculateActualExpectedTotals(this.props.assetsIncomeSummaries),
    })

    this.mapDataToChart(this.props.assetsIncomeSummaries)
  }

  componentDidUpdate(prevProps) {
    if (this.props.assetsIncomeSummaries !== prevProps.assetsIncomeSummaries) {
      this.setState({
        ...calculateActualExpectedTotals(this.props.assetsIncomeSummaries),
      })
      this.mapDataToChart(this.props.assetsIncomeSummaries)
    }
  }

  mapDataToChart = (assetsIncomeSummaries) => {
    const newData = [...assetsIncomeSummaries]
    const mappedData: ActualExpenseChartDatasetInterface[] = newData.map((asset) => {
      const dateMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      const date = new Date(asset.selected_month)
      const expected = `${dateMonths[date.getUTCMonth()]} ${date.getFullYear()}`
      return {
        name: expected,
        expectedIncome: asset.expected_income,
        actualIncome: asset.actual_income,
      }
    })
    this.setState({ assetsIncomeSummaries: mappedData })
  }

  render() {
    return (
      <div className="d-md-flex flex-row-reverse">
        <div style={{ textAlign: 'center', width: '25%', height: 300 }}>
          <div id="actual-income">
            <label>Actual Income</label>
            <h4>
              {this.state.actualIncome?.toLocaleString('en-KE', {
                style: 'currency',
                currency: 'ksh',
              })}{' '}
            </h4>
          </div>
          <div id="expected-income">
            <label>Expected Income</label>
            <h4>
              {this.state.expectedIncome?.toLocaleString('en-KE', {
                style: 'currency',
                currency: 'ksh',
              })}{' '}
            </h4>
          </div>
        </div>



        <div className="w-75" style={{ textAlign: 'center', height: 300 }}>
          <ResponsiveContainer>
            <BarChart
              width={800}
              height={300}
              data={this.state.assetsIncomeSummaries}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="actualIncome" fill="#8884d8" />
              <Bar dataKey="expectedIncome" fill="#82ca9d" />
              {/*<Line type="monotone" dataKey="expectedIncome" stroke="#8884d8" />*/}
              {/*<Line type="monotone" dataKey="actualIncome" stroke="#82ca9d" />*/}
            </BarChart>
          </ResponsiveContainer>
        </div>


      </div>
    )
  }
}

export default AssestIncomeBarGraph
