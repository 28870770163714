import dashboardRoutes, { CustomRoute, supportRoutes } from '../../dashboard-routes'

import { PaginationResponse } from '../assetPage/HousesTable'
import { Role } from '../../../lib/enum/Role.enum'
import qs from 'qs'

export interface UserResponse {
  id: number
  first_name: string
  last_name: string
  email: string
  internal_user?: boolean
}

export interface AccountResponse {
  id: number
  account_roles: any[]
  company: CompanyResponse
  user: UserResponse
}

export interface AssetResponse {
  company_ref: number
  id: number
  location: string
  name: string
  occupied_houses?: number
  all_houses?: number
  houses: HouseMinimumContractResponse[]
}

export interface InvoiceResponse {
  id: number
  invoice_date: string
  status: string
  contract_id?: number
}

export interface expenseResponse {
  id: number
  name: string
  status: string
  expected_unit_amount: number
  asset_id: number
  is_recurring: boolean
  is_required: boolean
  asset: AssetMinimalResponse
}

export interface InvoiceDetailsResponse {
  id: number
  invoice_date: string
  status: string
  contract_id: number
  contract: ContractWithHouseResponse
  invoiceItems: any[]
}

export interface HouseMinimumContractResponse {
  id: number
  house_number: string
  currentContract?: ContractResponse
}

export interface HouseResponse {
  asset: AssetResponse
  id: number
  house_number: string
  occupancy: string
  currentContract?: ContractResponse
  tenant: any
}

export interface MissingPaymentsMap {
  [date: string]: MissingPaymentResponse
}

export interface MissingPaymentResponse {
  expectedAmount: number
  paidAmount: number
  remainingAmount: number
  paymentDate: string
}

export interface PaymentResponse {
  rent: number
  type: string
  payment_date: string
  date_payed: string
  payment_code: string
  house_id: number
  contract_id: number
  date_created: string
  house?: HouseResponse
  sms_event_id?: number
}

export interface PaginationPage<T> {
  items: T[]
  pagination: PaginationResponse
}

export interface PaymentFormRequest {
  smsNotification?: boolean
  rent: number
  type: string
  paymentDate: string
  datePayed: string
  paymentCode: string
}

export interface ContractResponseV2 {
  id: number
  end_date?: string
  amount: number
  start_date: string
  status: string
  house: HouseResponseMinimal
  tenant: TenantRequest
}

export interface ContractResponse {
  id: number
  end_date?: string
  rent: number
  start_date: string
  period: string
  tenant: TenantResponse
}

export interface ContractWithHouseResponse {
  id: number
  end_date?: string
  rent: number
  start_date: string
  period: string
  tenant: TenantResponse
  house: HouseWithAssetResponse
}

export interface HouseWithAssetResponse {
  company_ref: number
  id: number
  location: string
  name: string
  asset: AssetMinimalResponse
}

export interface AssetMinimalResponse {
  company_ref: number
  id: number
  location: string
  name: string
}

export interface HouseRequest {
  company_ref: number
  id: number
  location: string
  name: string
  house_number?: string
}

export interface HouseResponseMinimal {
  id: number
  house_number: string
}

export interface TenantResponse {
  company_ref: number
  id: number
  location: string
  full_names: string
  email?: string
  phone_number?: string
}
export interface TenantRequest {
  full_names?: string
  start_date?: any
  rent?: number
  period?: string
  email?: string
  phone_number?: string
  id?: number
}

export interface CompanyResponse {
  id: number
  location: string
  name: string
}

export interface PackageTokenResponse {
  id: number
  package: PackageResponse
  status: string
  token_expiry_date: string
}

export interface PackageTokenRequest {
  package_id: number
}

export interface CompanyRequest {
  location: string
  name: string
  token?: number
  freeVersion?: boolean
}

export interface UserRequest {
  firstName: string
  lastName: string
  email: string
  password: string
  repeatPassword: string
}

export interface PackageResponse {
  id: number
  name: string
  max_accounts: number
  max_houses: number
  sms_quota: number
  price: string
  status: string
}

export interface CompanySummaryResponse {
  company: CompanyResponse
  companyUtilization?: CompanyPackageResponse
  currentPackage: PackageResponse
  summary?: any // todo remove
  companyPackage?: any // todo remove
}

export interface CompanyPackageResponse {
  package: PackageResponse
  expiry_date: string
}

export interface CompanyPackage {
  expiry_date: string
  summary: any
  package?: PackageResponse
  company_id: number
}

export interface CompanyDetailResponse {
  company: CompanyResponse
  summary: any
  companyPackage: CompanyPackage
}

// todo depricate
export function getUserFromLocalStorage(): UserResponse | null {
  const userData = JSON.parse(localStorage.getItem('user') || '{}')

  if (userData && userData.user) {
    return userData.user
  }
  return null
}

export function getUserFromLocalStorage2(): UserResponse | null {
  return JSON.parse(localStorage.getItem('user') || '{}')
}

export function getCompanyDetailsFromLocalStorage(): UserResponse | null {
  const companyData = JSON.parse(localStorage.getItem('user') || '{}')

  if (companyData && companyData.account) {
    return companyData.account.company
  }
  return null
}

export function supportRender(): boolean {
  const userResponse = getUserFromLocalStorage()
  return localStorage.getItem('supportRender') === 'true' && !!userResponse && !!userResponse.internal_user
}

export function getDashboardRoutes(): any[] {
  if (supportRender()) {
    return supportRoutes
  } else {
    return dashboardRoutes
  }
}

export function getAccountFromLocalStorage(): AccountResponse | null {
  const userData = JSON.parse(localStorage.getItem('user') || '{}')

  if (userData && userData.user) {
    return userData.account
  }
  return null
}

export interface NavLinkInterface {
  to: string
  title: string
  adminRequired?: boolean
}

export const navLinks: NavLinkInterface[] = [
  {
    to: '/account/profile',
    title: 'Profile',
  },
  {
    to: '/account/company/accounts',
    title: 'Users',
    adminRequired: true,
  },
]

//function to check if logged in user is admin
export const isAdmin = () => {
  const currentUser = localStorage.getItem('user')
  if (!currentUser) {
    return false
  } else {
    const userObject = JSON.parse(currentUser)
    const userRoles = userObject.account.account_roles
    return !!userRoles.find((userRole) => userRole.role.name === Role.Admin)
  }
}

export interface PageParams {
  page: number
  size: number
  asset?: number
}

export interface PaginationParamsInterface {
  page: number
  size: number
}

export function getAllRouteParams(location) {
  return qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as unknown as PageParams
}
export function convertSearchToRouteParams(search) {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as unknown as PageParams
}

export function updatePageParams(location: any, queryParams: PageParams): PageParams {
  const queries: PageParams = getAllRouteParams(location)
  return { ...queries, ...queryParams }
}

export function filterCorrectTabLinks(navLinks: NavLinkInterface[]) {
  return isAdmin() ? navLinks : navLinks.filter((navLink) => !navLink.adminRequired)
}

export function filterRoutesUserHasAccessTo(AllRoutes: CustomRoute[]) {
  return isAdmin() ? AllRoutes : AllRoutes.filter((route) => !route.adminRequired)
}

export const assetDetailNavLinks: NavLinkInterface[] = [
  {
    to: 'houses',
    title: 'Houses',
  },
  {
    to: 'expenses',
    title: 'Expenses',
  },
  {
    to: 'receipts',
    title: 'Receipts',
  },
]
