import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { required } from '../forms/validators'
import { SFormErrors } from '../forms/css/variables.styles'
import DatePicker from 'react-datepicker'

const exampleTableInitialValues = [
  {
    id: 2000,
    datePayed: 'Marley',
    paymentType: 'mpesa',
    paymentCode: 'dfgdrtfgertg',
    amount: '200 kes',
    edited: false,
  },
]

interface PaymentCellsStateInterface {
  columnName: boolean
  rowId: number
  newValue: any
}

export const ExampleFinalFormArray = () => {
  const onSubmit = (event) => {
    console.log('event', event)
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        // potentially other mutators could be merged here
        ...arrayMutators,
      }}
      // validate={validate}
      initialValues={{ customers: exampleTableInitialValues }}
      render={({ handleSubmit, pristine, invalid }) => (
        <form className="form-group" onSubmit={handleSubmit}>
          <h2>Work in progress !!!</h2>
          <FieldArray name="customers">
            {({ fields, meta }) => (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Date payed</th>
                      <th scope="col">Type</th>
                      <th scope="col">Payment code</th>
                      <th scope="col">Amount</th>
                      <th scope="col">actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((name, index) => (
                      <tr key={index}>
                        <PaymentTdWithFFInput
                          name={`${name}.datePayed`}
                          inputOnChange={() => {
                            fields.update(index, {
                              ...fields.value[index],
                              edited: true,
                            })
                          }}
                        />

                        <PaymentTdWithFFInput
                          name={`${name}.paymentType`}
                          inputOnChange={() => {
                            fields.update(index, {
                              ...fields.value[index],
                              edited: true,
                            })
                          }}
                        />
                        <PaymentTdWithFFInput
                          name={`${name}.paymentCode`}
                          inputOnChange={() => {
                            fields.update(index, {
                              ...fields.value[index],
                              edited: true,
                            })
                          }}
                        />
                        <td>
                          <Field name={`${name}.amount`} validate={required}>
                            {({ input, meta }) => (
                              <>
                                <input
                                  {...input}
                                  className={`form-control form-control-sm border ${
                                    meta.dirty && 'rounded border-success'
                                  }`}
                                  onChange={(event) => {
                                    fields.update(index, {
                                      ...fields.value[index],
                                      edited: true,
                                    })
                                    input.onChange(event)
                                    console.log('meta', meta)
                                  }}
                                />
                                <SFormErrors>{meta.error && meta.touched && <span>{meta.error}</span>}</SFormErrors>
                              </>
                            )}
                          </Field>
                        </td>
                        <td>
                          <button className="btn btn-primary" type="button" onClick={() => fields.remove(index)}>
                            Remove
                          </button>
                          {}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className="btn btn-primary mr-3"
                  type="button"
                  onClick={() => fields.push({ firstName: '', lastName: '' })}
                >
                  Add
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => console.log('todo update to backend server')}
                >
                  update
                </button>
              </>
            )}
          </FieldArray>
        </form>
      )}
    />
  )
}

interface PaymentTdWithFFInputProps {
  name: string
  inputIndex?: string | number
  formSubmitted?: boolean
  inputOnChange: (event: any) => void
}

// fields.update(index, {
// 	...fields.value[index],
// 	edited: true,
// });

export const PaymentTdWithFFInput = (props: PaymentTdWithFFInputProps) => {
  return (
    <td>
      <Field name={props.name} validate={required}>
        {({ input, meta }) => (
          <>
            <input
              {...input}
              disabled={props.formSubmitted}
              className={`form-control form-control-sm border ${meta.dirty && 'rounded border-success'}`}
              onChange={(event) => {
                props.inputOnChange(event)
                input.onChange(event)
              }}
            />
            <SFormErrors>{meta.error && meta.touched && <span>{meta.error}</span>}</SFormErrors>
          </>
        )}
      </Field>
    </td>
  )
}

export const PaymentTdWithDatePickerFFInput = (props: PaymentTdWithFFInputProps) => {
  return (
    <td>
      <Field name={props.name} validate={required}>
        {({ input, meta }) => (
          <>
            <DatePicker
              {...input}
              disabled={props.formSubmitted}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="form-control form-control-sm"
              onChange={(date) => {
                props.inputOnChange(date)
                input.onChange(date ? date.toISOString().split('T')[0] : null)
              }}
            />
            <SFormErrors>{meta.error && meta.touched && <span>{meta.error}</span>}</SFormErrors>
          </>
        )}
      </Field>
    </td>
  )
}
