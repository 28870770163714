import styled, { css } from 'styled-components'
import { SIDEBAR_ACTIVE_BACKGROUND_COLOR, SIDEBAR_WIDTH } from './variables'
const dashboardBackgroundColor = '#f2f0f8'

export const StyledPageNav = styled.div`
  display: flex;
  align-items: center;
  > * {
    width: 50%;
  }
`

export const StyledPageNavBetween = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledBreadcrumb = styled.ul`
  display: flex;
  padding: 0;
  margin: 0 !important;
`

export const StyledPageNavRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

export const StyledNoAuthPage = styled.div`
  background: ${dashboardBackgroundColor};
  min-height: 100vh;
`

export const StyledBackgroundPage = styled.div`
  background: ${dashboardBackgroundColor};
`

export const StyledNoAuthPageCard = styled.div`
  min-height: 50vh;
`

export const StyledDashboardSection = styled.div`
  background: #f2f0f8;
`
export const StyledDashboardMain = styled.div`
  background: #f2f0f8;
`

export const StyledSidebarItem: React.FC<any> = styled.div`
  display: flex;
  padding: 0 16px;

  ${(props: any) =>
    props.active &&
    css`
      background: ${SIDEBAR_ACTIVE_BACKGROUND_COLOR};
    `}
`

export const StyledSidebarItemIcon = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  margin: 8px 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledSidebarItemName = styled.div`
  display: flex;
  margin: 8px 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledSidebar = styled.nav`
  top: 0;
  width: ${SIDEBAR_WIDTH}px;
  height: 100%;
  background-color: peru;
  transition: left 300ms ease-out;

  &.active {
    left: 0;
    width: 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  .sidebar li {
    list-style: none;
  }

  li a {
    font-size: 18px;
    text-decoration: none;
    padding: 10px;
    display: block;
    color: #fff;
  }
`

export const StyledWhiteContentSection = styled.div`
  background: white;
`

export const StyledPageFilterNav = styled.div`
  display: flex;
  align-items: center;
`
