import { AxiosInstance, AxiosResponse } from 'axios';
import { AuthApi } from './api';
import { baseAxios } from './api.config';
import {
  AccountResponse,
  CompanySummaryResponse,
} from '../dashboard/pages/accountPage/helpers';
import { UserAccountRequest } from '../lib/interface/UserAccount.interface';

export interface ChangePasswordRequest {
  oldPassword?: string;
  newPassword?: string;
  newPasswordRepeat?: string;
}

export class AccountsApi extends AuthApi {
  public constructor(baseAxios?: AxiosInstance) {
    super(baseAxios);
    this.getCompanyAccounts = this.getCompanyAccounts.bind(this);
  }

  public getCompanyAccounts(companyId): Promise<AccountResponse[]> {
    return this.get<AccountResponse[]>(
      `/auth/companies/${companyId}/accounts`
    ).then(this.success);
  }

  public getCompanySummary(): Promise<CompanySummaryResponse> {
    return this.get<CompanySummaryResponse>(`/auth/company/summary`).then(
      this.success
    );
  }

  public changePassword(
    changePasswordRequest: ChangePasswordRequest
  ): Promise<any> {
    return this.post<string, ChangePasswordRequest, AxiosResponse<any>>(
      `/auth/change-password`,
      changePasswordRequest
    ).then(this.success);
  }

  public createCompanyUserAccount(
    companyId: number,
    userAccountRequest: UserAccountRequest
  ): Promise<any> {
    return this.post<string, UserAccountRequest, AxiosResponse<any>>(
      `/auth/companies/${companyId}/accounts`,
      userAccountRequest
    ).then(this.success);
  }

  public deleteUserAccount(
    companyId: number,
    accountId: number
  ): Promise<AccountResponse> {
    return this.delete<string, AxiosResponse<AccountResponse>>(
      `/auth/companies/${companyId}/accounts/${accountId}`
    ).then(this.success);
  }
}

export const accountsApi = new AccountsApi(baseAxios);
