import React, { ReactElement, useEffect, useState } from 'react'
import { StyledBreadcrumb, StyledPageNav } from '../../../lib/styled'
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'
import PageSection from '../../../lib/dashboard-page/PageSection'
import { assetsApi } from '../../../utils/assets-api'
import { fromJsonApiToUploadInstance } from './utils'

export default function UploadInstanceDetailPage(props): ReactElement {
  const [uploadInstance, setUploadInstance] = useState<any>()
  const uploadInstanceId = JSON.parse(props.match.params.id)
  useEffect(() => {
    assetsApi.fetchUploadInstanceById(uploadInstanceId).then((uploadInstanceResponse: any) => {
      const selectedInstance = fromJsonApiToUploadInstance([uploadInstanceResponse.data])[0]
      setUploadInstance(selectedInstance)
    })
  }, [uploadInstanceId])
  const fetchAllUploadInstance = () => {
    assetsApi.fetchUploadInstanceById(uploadInstanceId).then((uploadInstanceResponse: any) => {
      const selectedInstance = fromJsonApiToUploadInstance([uploadInstanceResponse.data])[0]
      setUploadInstance(selectedInstance)
    })
  }

  const initializeBulkSave = () => {
    assetsApi.initializeBulkSave(uploadInstanceId).then((uploadInstanceResponse: any) => {
      fetchAllUploadInstance()
    })
  }

  return (
    <DashboardPage2 hideTitle={true}>
      <StyledPageNav>
        <StyledBreadcrumb>
          <BreadcrumbItem url={'/upload-instances'} title={'upload instances'} />
          <BreadcrumbItem active title={'create'} />
        </StyledBreadcrumb>
      </StyledPageNav>

      <PageSection rightHeader={<button onClick={initializeBulkSave}>Save changes</button>}>
        <div className="d-flexd">
          <div>Upload status: {uploadInstance?.status}</div>
          <div>Upload date: {uploadInstance?.uploaded_date}</div>
        </div>
      </PageSection>
    </DashboardPage2>
  )
}
