import React, { ReactElement } from 'react';

export interface DashboardPageProps
  extends React.ButtonHTMLAttributes<HTMLElement> {
  children: any;
  pageName?: string;
  pageButtons?: any;
  noPadding?: boolean;
}

export default function DashboardPage({
  children,
  pageName,
  pageButtons,
  noPadding,
}: DashboardPageProps): ReactElement {
  return (
    <div
      style={{
        margin: '20px',
        boxShadow:
          '0 1px 5px 0px rgba(0,0,0,0.3), 0 1px 5px 0px rgba(0,0,0,0.15)',
      }}
    >
      <div style={cardHeader} className="d-md-flex justify-content-between">
        <div className="align-self-center font-weight-bold">{pageName}</div>
        <div>{pageButtons}</div>
      </div>
      <div style={{ background: 'white', padding: noPadding ? '0' : '20px' }}>
        {children}
      </div>
    </div>
  );
}

export const cardHeader = {
  background: '#6e7c95',
  padding: '0.5em',
  fontSize: '18px',
  color: 'white',
  borderRadius: '2px 2px 0 0',
  minHeight: '51px',
};
