import React, { ReactElement } from 'react';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from '../../../../RouteWithSubRoutes';

interface Props {
  routes: any[];
}

export default function PackageContainer({ routes }: Props): ReactElement {
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} exact />
      ))}
    </Switch>
  );
}
