import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PageSection from '../../../lib/dashboard-page/PageSection'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'

import { StyledBreadcrumb, StyledPageNav, StyledPageNavRight } from '../../../lib/styled'
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'
import { assetsApi } from '../../../utils/assets-api'
import KeyValueContainer, { KeyValueItem } from '../../../lib/KeyValuePair/KeyValueContainer'

type Props = {
  match: any
  history: any
}
const ExpenseDetailPage = (props: Props) => {
  const [expenseDetail, setExpenseDetail] = useState<any>()
  const expenseId = JSON.parse(props.match.params.expenseId)
  const assetId = JSON.parse(props.match.params.assetId)

  const getInvoiceDetails = () => {
    assetsApi.getExpenseDetails(assetId, expenseId).then((expenseDetailResponse) => {
      setExpenseDetail(expenseDetailResponse)
    })
  }

  useEffect(() => {
    getInvoiceDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DashboardPage2 hideTitle={true}>
      <StyledPageNav>
        <StyledBreadcrumb>
          <BreadcrumbItem url={'/expenses'} title={'expenses'} />
          <BreadcrumbItem active title={'create'} />
        </StyledBreadcrumb>

        <StyledPageNavRight>
          <Link className="btn btn-primary btn-sm" to="/expenses/create">
            Create Expense
          </Link>
        </StyledPageNavRight>
      </StyledPageNav>

      <PageSection>
        <p style={{ fontSize: '18px' }} className="pl-3 pt-3">
          <strong>{expenseDetail?.name}</strong>
        </p>
        <KeyValueContainer>
          <KeyValueItem
            keyName="Asset Name"
            valueName={<Link to={`/asset/${expenseDetail?.asset.id}`}>{expenseDetail?.asset?.name}</Link>}
            dataCy="assetName"
          />

          <KeyValueItem keyName="Recurring" valueName={expenseDetail?.is_recurring ? 'Yes' : 'No'} />
          <KeyValueItem keyName="Expected Amount" valueName={expenseDetail?.expected_unit_amount} />
          <KeyValueItem keyName="Required" valueName={expenseDetail?.is_required ? 'Yes' : 'No'} />
          <KeyValueItem keyName="Created Time" valueName={expenseDetail?.created_at} />
        </KeyValueContainer>
      </PageSection>
    </DashboardPage2>
  )
}

export default ExpenseDetailPage
