import React from 'react';
import {
  navLinks,
  getUserFromLocalStorage,
  filterCorrectTabLinks,
  filterRoutesUserHasAccessTo,
} from './helpers';
import { TabsContainer } from '../../../lib/tabs/tabs.stories';
import DashboardPage2 from '../../../lib/page2/DashboardPage2';
import PageSection from '../../../lib/dashboard-page/PageSection';
import { setGALocation } from '../overview/utils';

export const AccountPage = ({ routes }) => {
  const userResponse = getUserFromLocalStorage();
  setGALocation();
  return (
    <DashboardPage2
      pageName={userResponse?.first_name + ' ' + userResponse?.last_name}
    >
      <PageSection>
        <TabsContainer
          navLinks={filterCorrectTabLinks(navLinks)}
          routes={filterCorrectTabLinks(routes)}
          defaultRedirect={filterRoutesUserHasAccessTo(routes)[0].path}
        />
      </PageSection>
    </DashboardPage2>
  );
};

export default AccountPage;
