import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import '@szhsin/react-menu/dist/index.css'
import App from './app/App'
import StoreState from './app/context/store/storeState'

ReactDOM.render(
  <StoreState>
    <HashRouter>
      <App />
    </HashRouter>
  </StoreState>,
  document.getElementById('root'),
)
// registerServiceWorker();
