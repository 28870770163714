import ReactGA from 'react-ga'
import { AssetsIncomeDurationSummary } from '../../../lib/charts/testAssetsAnalyticsData'

export function assetsMonthlyIncomeSummaryToBarGraph(assetsIncomeSummaries: AssetMonthlyIncomeSummary[]) {
  return assetsIncomeSummaries.map((asset) => {
    return {
      name: asset['asset'].name,
      expectedIncome: asset.expectedTotal,
      actualIncome: asset.total,
    }
  })
}

export function assetsIncomeSummaryToBarGraph(assetsIncomeSummaries: AssetsIncomeDurationSummary[]) {
  return assetsIncomeSummaries.map((asset) => {
    return {
      date: asset.date,
      expectedIncome: asset.expectedTotal,
      actualIncome: asset.total,
    }
  })
}

export function calculateActualExpectedTotals(assetsIncomeSummaries: any[]): {
  actualIncome: any
  expectedIncome: any
} {
  return assetsIncomeSummaries.reduce(
    (totals, current) => {
      return {
        actualIncome: totals.actualIncome + current.actual_income,
        expectedIncome: totals.expectedIncome + current.expected_income,
      }
    },
    { actualIncome: 0, expectedIncome: 0 },
  )
}

export function calculateInvoiceSummary(invoicesSummary: any[]) {
  let paymentSum = 0
  let expensesSum = 200
  invoicesSummary.forEach((summary) => {
    paymentSum = paymentSum + summary.payments_sum
    expensesSum = expensesSum + summary.expenses_sum
  })
  return [
    { name: 'total payments', value: paymentSum, color: '#8884d8' },
    { name: 'total expenses', value: expensesSum, color: '#FF8042' },
  ]
}

export interface AssetMonthlyIncomeSummary {
  asset: any
  date: string
  expectedTotal: number
  total: number
}

export function setGALocation() {
  ReactGA.pageview(window.location.href)
}

export function setGAEvent(category, action) {
  ReactGA.event({
    category: category,
    action: action,
  })
}

export function getPreviousYear(date: Date) {
  date.setFullYear(date.getFullYear() - 1)
  return date
}

export const dateMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

export function range(start, end) {
  return Array.from({ length: end - start + 1 }, (_, i) => i)
}
