import React, { ReactElement } from 'react';
import { range } from '../overview/utils';
import { PaginationUtil } from '../../shared/utils/PaginationUtil';
import * as H from 'history';
import { useHistory } from 'react-router-dom';

export default function DatatablePagination(props): ReactElement {
  const history: H.History = useHistory();
  const paginationUtil = new PaginationUtil(history);

  const updatePaginationParams = (paginationParams = {}) => {
    paginationUtil.updatePaginationParams(paginationParams);
  };

  return (
    <div className="d-flex justify-content-between">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Showing 1 to {props.pagination.size} of{' '}
        {props?.pagination.totalElements} entries
      </div>
      <ul className="pagination justify-content-end">
        <li
          className={`page-item ${
            !props?.pagination.hasPrev ? 'disabled' : ''
          }`}
        >
          <button
            className="page-link "
            onClick={() =>
              updatePaginationParams({
                page: props?.pagination.prevPage,
              })
            }
          >
            Previous
          </button>
        </li>
        {[...range(1, props?.pagination.pages)].map((index) => (
          <div key={index}>
            {props?.pagination.page === index + 1 ? (
              <li className={`page-item active `} key={index}>
                <button className="page-link">{index + 1}</button>
              </li>
            ) : (
              <li className={`page-item`} key={index}>
                <button
                  className="page-link "
                  onClick={() =>
                    updatePaginationParams({
                      page: index + 1,
                    })
                  }
                >
                  {index + 1}
                </button>
              </li>
            )}
          </div>
        ))}
        <li
          className={`page-item ${
            !props?.pagination.hasNext ? 'disabled' : ''
          }`}
        >
          <button
            className="page-link "
            onClick={() =>
              updatePaginationParams({
                page: props?.pagination.nextPage,
              })
            }
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
}
