import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { required } from '../../../lib/forms/validators'
import { SFormErrors } from '../../../lib/forms/css/variables.styles'
import React from 'react'
import { InvoiceItemTypeEnum } from '../../../lib/enum/InvoiceItemTypeEnum'
import { FaTrashAlt } from 'react-icons/fa'

interface ExpensesInvoiceItemTableFormProps {
  onSubmit: (value: any) => void
  tableInitialValues?: any[]
  expenses?: any[]
  rentInvoiceItems?: any[]
}

export const CreateExpensesInvoiceItemTableForm = (props: ExpensesInvoiceItemTableFormProps) => {
  return (
    <FieldArray className="form-group p-0 m-0" name="expenses">
      {({ fields }) => (
        <>
          <div className="d-flex p-2 w-100 justify-content-between">
            <h6 className="font-weight-bold text-justify">Invoice Items</h6>
            <button className="btn btn-secondary" type="button" onClick={() => fields.push({ newItem: true })}>
              Add
              {/*<FaTrashAlt />*/}
            </button>
          </div>

          <table className="table table-responsive-md">
            <thead>
              <tr>
                {/*<th scope="col">Date payed</th>*/}
                <th scope="col">Type</th>
                {/*<th scope="col">Payment code</th>*/}
                <th scope="col">Amount</th>
                <th scope="col">actions</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((name, index) => (
                <tr key={index}>
                  <td>
                    <Field
                      name={`${name}.expense_id`}
                      component="select"
                      className={'form-control form-control-sm'}
                      validate={required}
                    >
                      {({ input, meta }) => (
                        <>
                          <select {...input} className={'form-control form-control-sm'}>
                            <option />
                            <option value={InvoiceItemTypeEnum.RENT}>Rent</option>
                            {props.expenses
                              ? props.expenses.map((expense, index) => (
                                  <option value={expense.id} key={index}>
                                    {expense.name}
                                  </option>
                                ))
                              : null}
                          </select>
                          <SFormErrors>{meta.error && meta.touched && <span>{meta.error}</span>}</SFormErrors>
                        </>
                      )}
                    </Field>
                  </td>

                  <td>
                    <Field name={`${name}.amount`} validate={required} type="number">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            className={`form-control form-control-sm border ${meta.dirty && 'rounded border-success'}`}
                            onChange={(event) => {
                              fields.update(index, {
                                ...fields.value[index],
                                edited: true,
                              })
                              input.onChange(event)
                              console.log('meta', meta)
                            }}
                          />
                          <SFormErrors>{meta.error && meta.touched && <span>{meta.error}</span>}</SFormErrors>
                        </>
                      )}
                    </Field>
                  </td>
                  <td className="d-flex flex-row-reverse">
                    <div className="btn">
                      <FaTrashAlt  onClick={() => fields.remove(index)} />
                    </div>
                    {/*<button className="btn btn-secondary" type="button" onClick={() => fields.remove(index)}>*/}
                    {/*  Remove*/}
                    {/*</button>*/}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </FieldArray>
  )
}
