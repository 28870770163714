import React from 'react';
import { Link, Redirect, Switch } from 'react-router-dom';
import RouteWithSubRoutes from '../../RouteWithSubRoutes';
import { useLocation } from 'react-router-dom';
import { storiesOf } from '@storybook/react';
// import StoryRouter from 'storybook-react-router';
import { mockNavLinks, mockRoutes } from './mock-utils';
import styled from 'styled-components';

export const StyledTabHeader = styled.div`
  background: #f2f0f8 !important;
  > a {
    background-color: transparent !important;
  }
  > .active {
    border-bottom: 3px solid #2575d0 !important;
    border: 0;
  }
`;

export const TabHeader = ({ to, title }) => {
  const location = useLocation();
  return (
    <StyledTabHeader className="nav-item">
      <Link
        className={`nav-link ${location.pathname === to ? 'active' : ''}`}
        to={to}
      >
        {title}
      </Link>
    </StyledTabHeader>
  );
};

export const TabsContainer = ({
  navLinks,
  routes,
  defaultRedirect,
  style,
}: {
  navLinks: any[];
  routes: any[];
  defaultRedirect?: string;
  style?: any;
}) => {
  return (
    <div>
      <ul className="nav nav-tabs" style={{ margin: '0', ...style }}>
        {navLinks.map((navLink, i) => (
          <TabHeader title={navLink.title} to={navLink.to} key={i} />
        ))}
      </ul>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} exact />
        ))}
        {defaultRedirect ? <Redirect from="/" to={defaultRedirect} /> : null}
      </Switch>
    </div>
  );
};

// storiesOf('Example/Tabs', module)
//   .addDecorator(StoryRouter())
//   .add('Default', () => (
//     <TabsContainer navLinks={mockNavLinks} routes={mockRoutes} />
//   ));
