import React, { Component } from 'react';
import DashboardPage from '../../../../lib/dashboard-page/DashboardPage';
import { supportApi } from '../../../../utils/support-api';
import { PackageResponse } from '../../accountPage/helpers';
import TrWithLink from '../../../../lib/Table/TrWithLink';

interface Props {
  history: any;
}
interface State {
  packages?: PackageResponse[];
}

export default class PackageListPage extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};

    this.goToPackageDetail = this.goToPackageDetail.bind(this);
  }

  componentDidMount() {
    supportApi.getAllPackages().then((packages: PackageResponse[]) => {
      this.setState({ packages: packages });
    });
  }

  goToPackageDetail(packageId: number) {
    this.props.history.push(`packages/${packageId}`);
  }

  render() {
    return (
      <DashboardPage pageName={'Package List'}>
        <div style={{ overflow: 'scroll' }}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">houses</th>
                <th scope="col">sms</th>
                <th scope="col">accounts</th>
                <th scope="col">status</th>
                <th scope="col">price</th>
              </tr>
            </thead>
            <tbody>
              {this.state.packages?.map((subscriptionPackage, index) => (
                <TrWithLink
                  link={`packages/${subscriptionPackage.id}`}
                  key={index}
                >
                  <th scope="row">{index + 1}</th>
                  <td>{subscriptionPackage.name}</td>
                  <td>{subscriptionPackage.max_houses}</td>
                  <td>{subscriptionPackage.sms_quota}</td>
                  <td>{subscriptionPackage.max_accounts}</td>
                  <td>{subscriptionPackage.status}</td>
                  <td>{subscriptionPackage.price}</td>
                </TrWithLink>
              ))}
            </tbody>
          </table>
        </div>
      </DashboardPage>
    );
  }
}
