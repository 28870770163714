import TrWithLink from "../../../../lib/Table/TrWithLink";
import React from "react";


export const MissingInvoicesTable = ({invoicesPage}) => {
  return (
    <table className="table thead-dark table-sm table-responsive-md">
      <thead className="thead-dark">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Invoice Date</th>
        <th scope="col">House</th>
        <th scope="col">Tenant</th>
        <th scope="col">Contract amount</th>
        <th scope="col">Rent</th>
        <th scope="col">Missing rent</th>
      </tr>
      </thead>
      <tbody>
      {invoicesPage?.map((invoice, i) => (
        <TrWithLink
          key={i}
          link={`/invoices/create?selectedDate=${new Date(invoice.month_date)}&asset=${
            invoice.asset_id
          }&selectedRent=${invoice.expenses}&contract=${invoice.contract_id}`}
        >
          <th>{i + 1}</th>
          <td>{new Date(invoice.month_date).toDateString()}</td>
          <td>{invoice.house_number}</td>
          <td>{invoice.full_names}</td>
          <td>{invoice.amount}</td>
          <td>{invoice.rentAmount}</td>
          <td>{invoice.expenses}</td>
        </TrWithLink>
      ))}
      </tbody>
    </table>
  )
}
