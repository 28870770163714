import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MessagesContext from '../utils/MessagesContext';
import { userApi } from '../utils/user-api';

interface Props {
  history: any;
}
interface State {
  email?: string;
}

export default class ForgotPassword extends Component<Props, State> {
  static contextType = MessagesContext;
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleFormSubmit(event) {
    event.preventDefault();
    if (window.location.href.split('://').length === 2) {
      const httpPrefix = window.location.href.split('://')[0];
      const rawUrl = window.location.href.split('://')[1];
      const mainUrl = `${httpPrefix}://${rawUrl.split('/')[0]}`;

      if (this.state.email) {
        userApi
          .forgotPassword({ email: this.state.email, host: mainUrl })
          .then((response) => {
            this.context.setmessage(
              'success',
              'Password reset successfully, check email for further instructions.'
            );
            this.props.history.push('/login');
          })
          .catch((error) => {
            if (error.message) {
              this.context.setmessage('error', error.message);
            } else {
              this.context.setmessage('error', 'Error reseting password');
            }
          });
      }
    }
  }

  handleChange(event) {
    const form = event.target.name;
    const state = {};
    state[form] = event.target.value;

    this.setState(state);
  }

  render() {
    return (
      <div>
        <div className="login">
          <div className="login-card">
            <div className="register-header">Forgot password</div>

            <div className="login-body">
              <form onSubmit={this.handleFormSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input
                    required
                    value={this.state.email}
                    name="email"
                    onChange={this.handleChange}
                    type="email"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  />
                </div>

                {this.props.children}
                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-primary umami--click--login-button"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="login-request">
              <p> go to login page</p>
              <Link className="umami--click--login-link" to="/login">
                login
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const getCurrentHost = () => {
  const httpPrefix = window.location.href.split('://')[0];
  const rawUrl = window.location.href.split('://')[1];
  return `${httpPrefix}://${rawUrl.split('/')[0]}`;
};
