import React from "react";
import { FaChevronRight } from "react-icons/fa";

// FaTrashAlt
export const MissingInvoicesList = ({invoicesPage}) => {
  return (
    <div className="list-group mb-4">
      <div className="list-group-item list-group-item-action activee">
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mb-1">Asset 1 <FaChevronRight /> House 1: David</h5>
          <small>Sun May 01 2022</small>
        </div>
        <p className="mb-1">Some placeholder content in a paragraph.</p>
        {/*<small>And some small print.</small>*/}
      </div>
    </div>
  )

}
