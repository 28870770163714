import React, { ReactElement } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../../../../RouteWithSubRoutes';

interface Props {
  routes: any[];
}

export default function CompanyContainer({ routes }: Props): ReactElement {
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} exact />
      ))}
      <Redirect from="/" to="/companies" />
    </Switch>
  );
}
