import React, {ReactElement, useEffect, useState} from 'react';
import DashboardPage2 from '../../../lib/page2/DashboardPage2';
import { StyledBreadcrumb, StyledPageNav } from '../../../lib/styled';
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem';
import DashboardGraphsPage from '../overview/DashboardGraphsPage';
import {assetsApi} from "../../../utils/assets-api";
import NewInvoicesTable from "../overview/NewInvoicesTable";
import NewTenantsTable from "../overview/NewTenantsTable";

interface Props {
  routes: any[];
}

export default function OverviewPage({ routes }: Props): ReactElement {
  const [invoicesPage, setInvoicesPage] = useState<any>()

  useEffect(() => {
    fetchLatestInvoices()
  }, [])

  const fetchLatestInvoices = (paginationParams = {}) => {
    assetsApi.fetchAllInvoices(paginationParams).then((invoicesPageResponse) => {
      setInvoicesPage(invoicesPageResponse)
    })
  }

  return (
    <DashboardPage2 hideTitle={true}>
      <StyledPageNav>
        <StyledBreadcrumb>
          <BreadcrumbItem active title={'overview'} />
        </StyledBreadcrumb>
        {/*<StyledPageNavRight>*/}
        {/*	<button className="btn btn-primary btn-sm">Create Expense</button>*/}
        {/*</StyledPageNavRight>*/}
      </StyledPageNav>

      <DashboardGraphsPage />



      <div className="row">
        <div className="col-6 col-xs-12">
          <NewInvoicesTable invoices={invoicesPage ? invoicesPage.items : []} />
        </div>
        <div className="col-6 col-xs-12">
          <NewTenantsTable invoices={[]} />
        </div>

      </div>

    </DashboardPage2>
  );
}
