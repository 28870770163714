import React, { ReactElement } from 'react'
import TrWithLink from '../../../lib/Table/TrWithLink'

export default function UploadInstanceTable({ items }): ReactElement {
  return (
    <table className="table thead-dark table-sm table-responsive-md">
      <thead className="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Upload Date</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        {items?.map((item, i) => (
          <TrWithLink key={i} link={`/upload-instances/${item.id}`}>
            <th>{i + 1}</th>
            <td>{new Date(item.uploaded_date).toDateString()}</td>
            <td>{item.status}</td>
          </TrWithLink>
        ))}
      </tbody>
    </table>
  )
}
