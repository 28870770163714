import React from 'react'
import DatePicker from 'react-datepicker'
import PageSection from '../../../lib/dashboard-page/PageSection'
import AssestIncomeBarGraph from './AssestIncomeBarGraph'
import { assetsApi } from '../../../utils/assets-api'
import { AssetResponse, convertSearchToRouteParams } from '../accountPage/helpers'
import { assetsToIds } from '../reportsPage/utils'
import { getPreviousYear, setGALocation } from './utils'
import WhiteContentSection from '../../../lib/Layout/WhiteContentSection'
import { InvoiceDateRangeSummaryBarGraph } from '../../../lib/PageLayouts/InvoiceDateRangeSummaryPage'
import { StyledPageFilterNav } from '../../../lib/styled'
import { Link, withRouter } from 'react-router-dom'
import OneDateRange from '../../components/OneDateRange'
import { dateToString } from '../../shared/utils/dateUtils'
import { PaginationUtil } from '../../shared/utils/PaginationUtil'

interface State {
  assets: AssetResponse[]
  startDate: Date
  endDate: Date
  assetsIds: number[]
  assetsIncomeSummaries: any[]
  expectedIncome: number
  actualIncome: number
  invoicesDateRangeSummary: any[]
}

class DashboardGraphsPage extends React.Component<any, State> {
  paginationUtil
  constructor(props) {
    super(props)
    this.state = {
      assets: [],
      startDate: getPreviousYear(new Date()),
      endDate: new Date(),
      assetsIds: [],
      assetsIncomeSummaries: [],
      invoicesDateRangeSummary: [],
      expectedIncome: 0,
      actualIncome: 0,
    }
  }

  componentDidUpdate(prevProps) {
    // console.log('componentDidUpdate', prevProps)
    // // Typical usage (don't forget to compare props):
    if (this.props.location.search !== prevProps.location.search) {
      // this.fetchData(this.props.userID);
      const { startDate, endDate } = convertSearchToRouteParams(this.props.history.location.search) as any
      // const endDate = convertSearchToRouteParams(this.props.history.location.search).endDate

      console.log('componentDidUpdate.search', convertSearchToRouteParams(this.props.history.location.search))
      // fetchAllInvoices(convertSearchToRouteParams(this.props.history.location.search));

      if (this.state.assetsIds && startDate && endDate) {
        this.fetchAssetIncomeData(this.state.assetsIds, startDate, endDate)
        this.fetchInvoicesDateRangeSummary(this.state.assetsIds, new Date(startDate), new Date(endDate))
      }
    }
  }

  componentDidMount() {
    setGALocation()
    this.paginationUtil = new PaginationUtil(this.props.history)
    assetsApi.getAssets().then((assets) => {
      const assetsIds = assetsToIds(assets)
      if (assets.length) {
        this.setState({ assets })
        this.setState({ assetsIds })
        this.fetchAssetIncomeData(assetsIds, this.state.startDate, this.state.endDate)
      }

      this.fetchInvoicesDateRangeSummary(assetsIds)
    })
  }

  fetchInvoicesDateRangeSummary(assetsIds: number[], startDate= getPreviousYear(new Date()), endDate= new Date()) {
    assetsApi
      .fetchInvoicesDateRangeSummary(assetsIds, startDate, endDate)
      .then((invoicesDateRangeSummaryResponse: any[]) => {
        this.setState({
          invoicesDateRangeSummary: invoicesDateRangeSummaryResponse,
        })
      })
  }

  handleDateRange = (dateRange: [any, any]) => {
    console.log('handleDateRange', dateRange)
    const dateRangeParams = { startDate: null, endDate: null }
    if (dateRange.length) {
      const [startDate, endDate] = dateRange
      if (startDate) {
        dateRangeParams['startDate'] = dateToString(startDate)
      }
      if (endDate) {
        dateRangeParams['endDate'] = dateToString(endDate)
      }
      console.log('dateRangeParams.handleDateRange', dateRangeParams)

      this.paginationUtil.updatePageParams(dateRangeParams)
    }
  }

  handleDateChange(date: Date) {
    this.setState({ startDate: date })
    this.fetchAssetIncomeData(this.state.assetsIds, date, getPreviousYear(date))
  }

  fetchAssetIncomeData = (assetsIds, startDate, endDate) => {
    assetsApi.fetchIncomeReport(assetsIds, startDate, endDate).then((assetsGraphDataResponse: any) => {
      const assetsIncomeSummaries = assetsGraphDataResponse.data
      this.setState({ assetsIncomeSummaries })
      this.assetsGraphDataToIncome(assetsIncomeSummaries)
    })
  }

  assetsGraphDataToIncome(assetsIncomeSummaries) {
    const income = {
      expectedIncome: 0,
      actualIncome: 0,
    }

    assetsIncomeSummaries.forEach((assetsIncomeSummary) => {
      income.expectedIncome += assetsIncomeSummary.expected_income
      income.actualIncome += assetsIncomeSummary.actual_income
    })

    this.setState({ ...income })
  }

  render() {
    return (
      <>
        <StyledPageFilterNav>
          <OneDateRange onChange={this.handleDateRange} />
        </StyledPageFilterNav>
        {/*<PageSection>*/}
        {/*  <div className="d-flex flex-column" style={{height: '100%'}}>*/}
        {/*    <div className="select-date d-flex flex-column align-self-end">*/}
        {/*      <label>start date</label>*/}
        {/*      <DatePicker*/}
        {/*        id="startDate"*/}
        {/*        dateFormat="MM/yyyy"*/}
        {/*        showMonthYearPicker*/}
        {/*        className="form-control"*/}
        {/*        selected={this.state.startDate}*/}
        {/*        onChange={(date) => this.handleDateChange(date)}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="income d-md-flex justify-content-between mt-4 pb-4">*/}
        {/*      <div id="expected-income">*/}
        {/*        <h4>*/}
        {/*          {this.state.expectedIncome.toLocaleString('en-KE', {*/}
        {/*            style: 'currency',*/}
        {/*            currency: 'Ksh',*/}
        {/*          })}{' '}*/}
        {/*        </h4>*/}
        {/*        <label>Expected Income</label>*/}
        {/*      </div>*/}
        {/*      <div id="actual-income">*/}
        {/*        <h4>*/}
        {/*          {this.state.actualIncome.toLocaleString('en-KE', {*/}
        {/*            style: 'currency',*/}
        {/*            currency: 'ksh',*/}
        {/*          })}{' '}*/}
        {/*        </h4>*/}
        {/*        <label>Actual Income</label>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</PageSection>*/}


        {/*<div className="d-md-flex justify-content-between">*/}
        {/*<div className="col">*/}
        {/*  expected rent amount*/}
        {/*  KSH 841,500.00*/}
        {/*  /!*<PaBtCard/>*!/*/}
        {/*</div>*/}

        {/*        <div className="col">*/}
        {/*  actual rent amount*/}
        {/*</div>*/}

        {/*        <div className="col">*/}
        {/*  expected expenses amount*/}
        {/*</div>*/}

        {/*        <div className="col">*/}
        {/*  actual expenses amount*/}
        {/*</div>*/}

        {/*</div>*/}




        <PageSection sectionName="Expected and Actual Income">
          {this.state.assetsIncomeSummaries.length && (
            <AssestIncomeBarGraph assetsIncomeSummaries={this.state.assetsIncomeSummaries} />
          )}
        </PageSection>













        {/*<PageSection sectionName="Rent and Expenses">*/}
        {/*<div style={{*/}
        {/*  width: '100%',*/}
        {/*  height: '60px',*/}
        {/*  position: 'relative'*/}
        {/*}}>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*    width: '100%',*/}
        {/*    overflow: 'auto',*/}
        {/*    whiteSpace: 'nowrap',*/}
        {/*      background: 'white',*/}
        {/*    position: 'absolute'*/}
        {/*  }}*/}
        {/*  >*/}
        {/*    /!*<div style={{width: '300px',  height: '50vh', background: 'white' }}>*!/*/}
        {/*    /!*  <InvoiceDateRangeSummaryBarGraph invoicesDateRangeSummary={this.state.invoicesDateRangeSummary} />*!/*/}

        {/*    /!*</div>*!/*/}


        {/*    /!*<div style={{width: '200px',  height: '40px', background: 'lightpink' }}>*!/*/}

        {/*    /!*</div>*!/*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*</PageSection>*/}
      </>
    )
  }
}

export default withRouter(DashboardGraphsPage)
