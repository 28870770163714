import React, {ReactElement, useEffect, useState} from 'react';
import './sidebar.css';
import {Link, useLocation} from 'react-router-dom';
import {
  StyledSidebarItem,
  StyledSidebarItemIcon,
  StyledSidebarItemName,
} from '../styled';


import { FaAngleRight, FaAngleDown } from 'react-icons/all'


export interface SidebarProps {
  children: any;
  onClick?: () => void;
  showSidebar?: boolean;
  dashboardRoutes?: any[];
  style?: any;
}

export default function Sidebar(props: SidebarProps): ReactElement {
  const location = useLocation();

  const [openedSidebar, setOpenedSidebar] = useState({})
  const [sideBarRoutes, setSideBarRoutes] = useState([])

  useEffect(() => {
    const tempRoutes = props.dashboardRoutes.filter((route) => !!route.sidebarName).map(route => {
      const tempSubRoutes = route.routes ? route.routes.filter((subRoute) => !!subRoute.subRouteName) : []
      return {...route, routes: tempSubRoutes}
    })
    setSideBarRoutes(tempRoutes)
  }, [props.dashboardRoutes])

  function isActiverRoute(location, path) {
    const pathname = location.pathname.split('/')
    const pathHistories = path.split('/')
    return pathname.indexOf(pathHistories[1]) >= 0
  }

  function openSubRoutes(sidebarName, toggle) {
    const tempOpenedSidebar = Object.assign({}, openedSidebar)
    tempOpenedSidebar[sidebarName] = toggle
    setOpenedSidebar(tempOpenedSidebar)
  }

  return (
    <div>
      {props.showSidebar && (
        <div className="sidebar" style={props.style}>
          <div className="align-sidebar"></div>
          <ul>
            {sideBarRoutes
              .filter((route) => !!route.sidebarName)
              .map((route, i) => (
                <SidebarItem
                  key={i}
                  keyIndex={i}
                  path={route.path}
                  subRoutes={route.routes}
                  sidebarName={route.sidebarName}
                  isActiverRoute={isActiverRoute(location, route.path)}
                  IconComponent={route.IconComponent}
                  openSubRoutes={openSubRoutes}
                  subRoutesOpened={openedSidebar[route.sidebarName]}
                />
              ))}

          </ul>
        </div>
      )}
    </div>
  );
}

export interface SidebarItemProps {
  keyIndex: number;
  path: string;
  subRoutes: any[];
  sidebarName: any;
  isActiverRoute?: boolean;
  IconComponent?: any;
  openSubRoutes?: any;
  subRoutesOpened?: boolean;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  keyIndex,
  path,
  subRoutes,
  sidebarName,
  isActiverRoute,
  openSubRoutes,
  IconComponent,
  subRoutesOpened,
}) => {
  return (
    <div key={keyIndex}>
      <StyledSidebarItem active={isActiverRoute} data-cy={path}>
        <StyledSidebarItemIcon>
          {IconComponent ? <IconComponent /> : null}
        </StyledSidebarItemIcon>
        <div>
          <Link style={{ color: 'white' }} to={path}>
            <StyledSidebarItemName>{sidebarName}</StyledSidebarItemName>
          </Link>
          {subRoutesOpened && (

            <ul>
              {subRoutes && subRoutes.map((subRoute, index) => (
                <li key={index}>{subRoute.subRouteName}</li>

              ))}
            </ul>
          )}

        </div>
          {subRoutes && subRoutes.length > 0 && (
            <div  style={{marginLeft: 'auto'}} className="p-2">
              {subRoutesOpened ? (
                <FaAngleDown  onClick={() => openSubRoutes(sidebarName, false)}  />
              ) :(
                <FaAngleRight onClick={() => openSubRoutes(sidebarName, true)} />
              )}
            </div>
          )}
      </StyledSidebarItem>
    </div>
  );
};
