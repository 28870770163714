import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface Props {
  onCloseModal: (boolean) => void;
  contract: any;
}

export default class DeleteTenantModel extends Component<Props, unknown> {
  render() {
    return (
      <div>
        <Modal.Dialog>
          <div
            style={{ padding: '20px' }}
            className="d-flex justify-content-between"
          >
            <div> Create Token </div>
            <div>
              <button
                className="btn btn-secondary"
                onClick={() => this.props.onCloseModal(false)}
              >
                {' '}
                Close
              </button>
            </div>
          </div>
          <Modal.Body>
            <p>{`are you sure you want to remove tenant ${this.props.contract.tenant.full_names}.`}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() =>
                this.props.onCloseModal(this.props.contract.tenant.id)
              }
              className="umami--click--delete-tenant-button"
              variant="primary"
            >
              {' '}
              Remove Tenant{' '}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    );
  }
}
