import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import { ChangePasswordRequest } from '../../utils/accounts-api';

interface Props {
  onSubmit?: (changePasswordRequest: ChangePasswordRequest) => void;
  handleChange?: (changePasswordRequest: ChangePasswordRequest) => void;
}

interface State {
  oldPassword?: string;
  newPassword?: string;
  newPasswordRepeat?: string;
}

export default class ChangePasswordForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const form = event.target.name;
    const state = Object.assign({}, this.state);
    state[form] = event.target.value;
    this.setState(state);

    if (this.props.handleChange) {
      this.props.handleChange(state);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state);
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>Old password</label>
        <FormControl
          value={this.state.oldPassword}
          name="oldPassword"
          onChange={this.handleChange}
          type="password"
        />

        <label>New password</label>
        <FormControl
          value={this.state.newPassword}
          name="newPassword"
          onChange={this.handleChange}
          type="password"
        />

        <label>New password repeat</label>
        <FormControl
          value={this.state.newPasswordRepeat}
          name="newPasswordRepeat"
          onChange={this.handleChange}
          type="password"
        />
      </form>
    );
  }
}
