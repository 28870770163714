import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { StyledBreadcrumb, StyledPageNav } from '../../../lib/styled'
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'
import PageSection from '../../../lib/dashboard-page/PageSection'
import { assetsApi } from '../../../utils/assets-api'
import KeyValueContainer, { KeyValueItem } from '../../../lib/KeyValuePair/KeyValueContainer'
import { PaymentsInvoiceItemTableForm } from './PaymentsInvoiceItemTableForm'
import { invoicesApi } from '../../../utils/invoices-api'
import { ExpensesInvoiceItemTableForm } from './ExpensesInvoiceItemTableForm'
import { InvoiceItemTypeEnum } from '../../../lib/enum/InvoiceItemTypeEnum'
import MessagesContext from '../../../utils/MessagesContext'
import { catchError } from '../../../utils/api'
import { Menu, MenuItem } from '@szhsin/react-menu'
import { FaAngleDown } from 'react-icons/fa'

export default function InvoiceDetailPage(props): ReactElement {
  const [invoiceDetail, setInvoiceDetail] = useState<any>()
  const [expenses, setExpenses] = useState<any[]>([])
  const [expenseInvoiceItems, setExpenseInvoiceItems] = useState<any[]>([])
  const [paymentInvoiceItems, setPaymentInvoiceItems] = useState<any[]>([])
  const invoiceId = JSON.parse(props.match.params.id)
  const { setmessage } = useContext(MessagesContext)

  const getInvoiceDetails = () => {
    assetsApi.getInvoiceDetails(invoiceId).then((invoiceDetailResponse) => {
      setInvoiceDetail(invoiceDetailResponse)
      setPaymentInvoiceItems(
        invoiceDetailResponse.invoiceItems.filter((item) => item.type === InvoiceItemTypeEnum.RENT),
      )
      setExpenseInvoiceItems(
        invoiceDetailResponse.invoiceItems
          .filter((item) => item.type === InvoiceItemTypeEnum.EXPENSE)
          .concat(
            invoiceDetailResponse.invoiceItems
              .filter(
                // hack
                (item) => item.type === InvoiceItemTypeEnum.RENT,
              )
              .map((item) => ({
                ...item,
                expense_id: InvoiceItemTypeEnum.RENT,
              })),
          ),
      )
      getExpensesByAssetId(invoiceDetailResponse.contract.house.asset.id)
    })
  }

  useEffect(() => {
    getInvoiceDetails() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getExpensesByAssetId = (assetId) => {
    assetsApi.getExpensesByAssetId(assetId).then(({ items }) => {
      setExpenses(items)
    })
  }

  const submitInvoice = (selectedInvoice) => {
    invoicesApi
      .submitInvoice(selectedInvoice.contract_id, selectedInvoice.id)
      .then((response) => {
        getInvoiceDetails()
      })
      .catch((error) => catchError(error, setmessage))
  }

  const deleteInvoice = (selectedInvoice) => {
    invoicesApi
      .deleteInvoice(selectedInvoice.contract_id, selectedInvoice.id)
      .then((response) => {
        getInvoiceDetails()
      })
      .catch((error) => catchError(error, setmessage))
  }

  const onPaymentsSubmit = (event) => {
    if (invoiceDetail) {
      console.log('event', event)

      const newItems = event.payments
        .filter((payment) => payment.newItem)
        .map((payment) =>
          createInvoicePayment(
            invoiceDetail.contract.id,
            payment.amount,
            payment.type,
            payment.payment_code,
            payment.date_payed,
          ),
        )
      Promise.all(newItems).then((responses) => {
        getInvoiceDetails()
      })
    }
  }

  const onExpenseInvoiceItemSubmit = (event) => {
    console.log('event', event)
    if (invoiceDetail) {
      const newItems = event.expenses.filter((expense) => expense.newItem)
      const newExpenseItems = newItems.filter((expense) => expense.expense_id !== InvoiceItemTypeEnum.RENT)

      const newRentItems = newItems.filter((expense) => expense.expense_id === InvoiceItemTypeEnum.RENT)
      console.log('newRentItems', newRentItems)
      console.log('newExpenseItems', newExpenseItems)
      // debugger
      const expenseItemsRequest = newExpenseItems.map((expense) =>
        createExpenseInvoiceItem(
          invoiceDetail.contract.id,
          JSON.parse(expense.expense_id),
          invoiceDetail.id,
          expense.amount,
        ),
      )
      const rentItemsRequest = newRentItems.map((expense) =>
        createRentInvoiceItem(invoiceDetail.contract.id, invoiceDetail.id, expense.amount),
      )
      Promise.all([...expenseItemsRequest, ...rentItemsRequest]).then((responses) => {
        getInvoiceDetails()
      })
    }
  }

  const createExpenseInvoiceItem = async (contractId, expenseId, invoiceDetailId, amount) => {
    return await invoicesApi.createExpenseInvoiceItem(contractId, expenseId, invoiceDetail.id, JSON.parse(amount)).then(
      (response) => {
        console.log('response', response)
      },
      (error) => {
        console.log('error', error)
      },
    )
  }

  const createInvoicePayment = async (contractId, rent, type, paymentCode, datePayed) => {
    // dict(rent=5000, type="mpesa", paymentCode="payment_code", paymentDate=example_start_date)

    return await invoicesApi
      .createInvoicePayment(invoiceId, contractId, type, paymentCode, datePayed, JSON.parse(rent))
      .then(
        (response) => {
          console.log('response', response)
        },
        (error) => {
          console.log('error', error)
        },
      )
  }

  const deleteInvoiceItem = async (contractId, invoiceId, invoiceItemId) => {
    // dict(rent=5000, type="mpesa", paymentCode="payment_code", paymentDate=example_start_date)

    return await invoicesApi.deleteInvoiceItem(contractId, invoiceId, invoiceItemId).then(
      (response) => {
        console.log('response', response)
      },
      (error) => {
        console.log('error', error)
      },
    )
  }

  const deleteInvoicePayment = async (contractId, invoiceId, paymentId) => {
    console.log('response', contractId)
    console.log('response', invoiceId)
    console.log('response', paymentId)
    return await invoicesApi.deleteInvoicePayment(contractId, invoiceId, paymentId).then(
      (response) => {
        console.log('response', response)
      },
      (error) => {
        console.log('error', error)
      },
    )
  }
  const createRentInvoiceItem = async (contractId, invoiceDetailId, amount) => {
    return await invoicesApi.createPaymentInvoiceItem(contractId, invoiceDetail.id, JSON.parse(amount)).then(
      (response) => {
        console.log('response', response)
      },
      (error) => {
        console.log('error', error)
      },
    )
  }

  return (
    <DashboardPage2 hideTitle={true}>
      <StyledPageNav>
        <StyledBreadcrumb>
          <BreadcrumbItem url={'/invoices'} title={'invoices'} />
          <BreadcrumbItem active title={new Date(invoiceDetail?.invoice_date).toDateString()} />
        </StyledBreadcrumb>
      </StyledPageNav>

      <PageSection
        style={{ padding: '0 1rem 1rem 1rem' }}
        sectionName={
          <Menu
            menuButton={
              <div>
                Invoice
                <FaAngleDown />
              </div>
            }
          >
            <MenuItem onClick={() => deleteInvoice(invoiceDetail)}>Delete</MenuItem>
          </Menu>
        }
        rightHeader={
          <p>
            <span className={`badge badge-${invoiceDetail?.status === 'SUBMITTED' ? 'primary' : 'secondary'}`}>
              {invoiceDetail?.status}
            </span>
          </p>
        }
      >
        <div className="w-50 mb-4">
          {invoiceDetail ? (
            <KeyValueContainer>
              <KeyValueItem keyName="Asset" valueName={invoiceDetail.contract?.house.asset.name} />
              <KeyValueItem keyName="House" valueName={invoiceDetail.contract?.house.house_number} />
              <KeyValueItem keyName="Tenant" valueName={invoiceDetail.contract?.tenant.full_names} />
              <KeyValueItem keyName="Contract amount" valueName={invoiceDetail.contract?.amount} />
            </KeyValueContainer>
          ) : null}
        </div>
        {/*/invoices/contracts/{contract_id}/invoices/{invoice.get("id")}/invoice-items*/}
        <div className="card p-2 mb-4">
          <ExpensesInvoiceItemTableForm
            formSubmitted={invoiceDetail?.status === 'SUBMITTED'}
            onSubmit={onExpenseInvoiceItemSubmit}
            onDeleteInvoiceItem={(item) => deleteInvoiceItem(invoiceDetail.contract.id, item.invoice_id, item.id)}
            expenses={expenses}
            tableInitialValues={expenseInvoiceItems}
          />
        </div>
        {invoiceDetail?.payments ? (
          <div className="card p-2">
            <PaymentsInvoiceItemTableForm
              formSubmitted={invoiceDetail.status === 'SUBMITTED'}
              onSubmit={onPaymentsSubmit}
              onDeleteInvoicePayment={(item) =>
                deleteInvoicePayment(invoiceDetail.contract.id, item.invoice_id, item.id)
              }
              tableInitialValues={invoiceDetail.payments}
            />
          </div>
        ) : null}
        <div className="d-flex flex-row-reverse p-3">
          {!(invoiceDetail?.status === 'SUBMITTED') ? (
            <button
              data-cy="submit-invoice"
              className="btn btn-primary"
              type="button"
              onClick={() => submitInvoice(invoiceDetail)}
            >
              Submit invoice
            </button>
          ) : null}
        </div>
        {/*<PaymentsEditableTable />*/}
      </PageSection>
    </DashboardPage2>
  )
}
