import { AxiosInstance } from 'axios'
import { AuthApi } from './api'
import { baseAxios } from './api.config'
import { InvoiceResponse } from '../dashboard/pages/accountPage/helpers'
import { InvoiceItemTypeEnum } from '../lib/enum/InvoiceItemTypeEnum'

export class InvoicesApi extends AuthApi {
  public constructor(baseAxios?: AxiosInstance) {
    super(baseAxios)
  }

  public createPaymentInvoiceItem(contractId, invoiceId, amount): Promise<InvoiceResponse> {
    return this.post<string, any, InvoiceResponse>(
      `/invoices/contracts/${contractId}/invoices/${invoiceId}/invoice-items`,
      {
        amount: amount,
        invoice_item_type: InvoiceItemTypeEnum.RENT,
      },
    )
  }

  public createExpenseInvoiceItem(contractId, expenseId, invoiceId, amount): Promise<InvoiceResponse> {
    return this.post<string, any, InvoiceResponse>(
      `/invoices/contracts/${contractId}/invoices/${invoiceId}/invoice-items`,
      {
        amount: amount,
        invoice_item_type: 'expense_invoice_item',
        expense_id: expenseId,
      },
    )
  }

  public submitInvoice(contractId, invoiceId): Promise<InvoiceResponse> {
    return this.post<string, any, InvoiceResponse>(`/invoices/contracts/${contractId}/invoices/${invoiceId}/submit`, {})
  }

  public deleteInvoice(contractId, invoiceId): Promise<InvoiceResponse> {
    return this.post<string, any, InvoiceResponse>(`/invoices/contracts/${contractId}/invoices/${invoiceId}/delete`, {})
  }

  public deleteInvoiceItem(contractId, invoiceId, invoiceItemId): Promise<InvoiceResponse> {
    return this.delete<string, InvoiceResponse>(
      `/invoices/contracts/${contractId}/invoices/${invoiceId}/invoice-items/${invoiceItemId}`,
      {},
    )
  }

  public deleteInvoicePayment(contractId, invoiceId, paymentId): Promise<InvoiceResponse> {
    return this.delete<string, InvoiceResponse>(
      `/invoices/contracts/${contractId}/invoices/${invoiceId}/payments/${paymentId}`,
      {},
    )
  }

  public createInvoicePayment(invoiceId, contractId, type, paymentCode, paymentDate, amount): Promise<InvoiceResponse> {
    // invoices/contracts/{contract_id}/invoices/{new_invoice_id}/payments
    // dict(rent=5000, type="mpesa", paymentCode="payment_code", paymentDate=example_start_date)
    const request = {
      rent: amount,
      type,
      paymentCode,
      paymentDate,
    }
    console.log('requests', request)
    return this.post<string, any, InvoiceResponse>(
      `/invoices/contracts/${contractId}/invoices/${invoiceId}/payments`,
      request,
    )
  }
}

export const invoicesApi = new InvoicesApi(baseAxios)
