import React from 'react';
import { Field, Form } from 'react-final-form';
import { SFormErrors } from '../forms/css/variables.styles';
import { required } from '../forms/validators';
import { ModalActions } from './Modal';

interface Props {
  hideModal: () => void;
  onSubmit: (value: any) => void;
}

const AccountForm = ({ hideModal, onSubmit }: Props) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form
          {...Form}
          onSubmit={handleSubmit}
          style={{ padding: 0, width: '100%' }}
        >
          <div className="form-row">
            <Field name="firstName" validate={required}>
              {({ input, meta }) => (
                <div className="form-group col-md-6">
                  <label htmlFor="firstName">First name</label>
                  <input {...input} type="text" className="form-control" />
                  <SFormErrors>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </SFormErrors>
                </div>
              )}
            </Field>
            <Field name="lastName" validate={required}>
              {({ input, meta }) => (
                <div className="form-group  col-md-6">
                  <label htmlFor="lastName">Last name</label>
                  <input {...input} type="text" className="form-control" />
                  <SFormErrors>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </SFormErrors>
                </div>
              )}
            </Field>
          </div>

          <div className="form-row">
            <Field name="email" validate={required}>
              {({ input, meta }) => (
                <div className="form-group">
                  <label htmlFor="email">email</label>
                  <input {...input} type="email" className="form-control" />
                  <SFormErrors>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </SFormErrors>
                </div>
              )}
            </Field>
          </div>

          <ModalActions className="justify-content-between">
            <button
              style={{ marginRight: '10px' }}
              onClick={hideModal}
              className="btn btn-secondary mr-auto"
            >
              close
            </button>
            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </ModalActions>
        </form>
      )}
    />
  );
};

export default AccountForm;
