export function convertToCSV(objArray) {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
  let str = ''

  for (let i = 0; i < array.length; i++) {
    let line = ''
    for (const index in array[i]) {
      if (line !== '') line += ','

      line += array[i][index]
    }

    str += line + '\r\n'
  }

  return str
}

export function exportCSVFile(headers, itemsFormatted, fileTitle) {
  if (headers) {
    itemsFormatted.unshift(headers)
  }

  // Convert Object to JSON
  const jsonObject = JSON.stringify(itemsFormatted)

  const csv = convertToCSV(jsonObject)

  const exportedFilenmae = fileTitle + '.csv' || 'export.csv'

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

  const link = document.createElement('a')
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', exportedFilenmae)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export function validatePhoneNumber(phoneNumber: string): boolean {
  // check if string is a number with no letters
  return isStringNumber(phoneNumber) && phoneNumber.length === 12
}

export function isStringNumber(number: string): boolean {
  return /^-?\d+$/.test(number)
}
