import React, { Component } from 'react';
import DashboardPage from '../../../../lib/dashboard-page/DashboardPage';
import { supportApi } from '../../../../utils/support-api';
import { PackageTokenResponse } from '../../accountPage/helpers';
import PackageTokenModal from '../../../shared/PackageTokenModal';

interface State {
  tokens?: PackageTokenResponse[];
  showPackageTokenModal: boolean;
  selected_token?: string;
}

export default class PackageTokenListPage extends Component<unknown, State> {
  constructor(props) {
    super(props);
    this.state = {
      showPackageTokenModal: false,
    };
    this.openTokenModal = this.openTokenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidMount() {
    supportApi.getAllTokens().then((tokens: PackageTokenResponse[]) => {
      this.setState({ tokens: tokens });
    });
  }

  openTokenModal(token) {
    this.setState({ showPackageTokenModal: true, selected_token: token });
  }

  onCloseModal() {
    this.setState({ showPackageTokenModal: false });
  }

  render() {
    const packageTokenModal =
      this.state.showPackageTokenModal && this.state.selected_token ? (
        <PackageTokenModal
          onCloseModal={this.onCloseModal}
          token={this.state.selected_token}
        />
      ) : undefined;
    return (
      <DashboardPage pageName={'Tokens'}>
        {packageTokenModal}
        <div style={{ overflow: 'scroll' }}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Package</th>
                <th scope="col">Price</th>
                <th scope="col">Token Expiry Date</th>
                <th scope="col">status</th>
              </tr>
            </thead>
            <tbody>
              {this.state.tokens?.map((packageToken: any, index) => (
                <tr
                  style={{ color: '#007bff', cursor: 'pointer' }}
                  onClick={() => this.openTokenModal(packageToken.token)}
                  key={index}
                >
                  <th scope="row">{index + 1}</th>
                  <td>{packageToken.package?.name}</td>
                  <td>{packageToken.package?.price}</td>
                  <td>{packageToken.token_expiry_date}</td>
                  <td>{packageToken.status}</td>
                  <td>{packageToken.company?.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </DashboardPage>
    );
  }
}
