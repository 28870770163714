import React, { Component } from 'react'
import Main from './Main'
import './dashboard.css'
import setAuthorizationToken from '../utils/setAuthorizationToken'
import { getDashboardRoutes, CompanySummaryResponse, getUserFromLocalStorage } from './pages/accountPage/helpers'
import Sidebar from '../lib/sidebar/Sidebar'
import { accountsApi } from '../utils/accounts-api'
import { Header } from '../stories/Header'
import {agentsJsonapiService} from "../utils/agents-jsonapi-service";

interface Props {
  location: any
  history: any
}

interface State {
  companySummary?: CompanySummaryResponse
  user?: any
  daysToExpire?: number
  showSidebar: boolean
  dashboardRoutes: any[];
}

class Dashboard extends Component<Props, State> {
  constructor(props) {
    super(props)
    const routesWithoutFlags = getDashboardRoutes().filter(ff => !ff.requiredFeatureFlags || ff.requiredFeatureFlags.length === 0)
    this.state = {
      user: getUserFromLocalStorage(),
      showSidebar: true,
      dashboardRoutes: routesWithoutFlags
    }
  }

  componentDidMount() {
    accountsApi.getCompanySummary().then((companySummary: CompanySummaryResponse) => {
      // todo create reusable function
      const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
      const expiryDate = new Date(companySummary.companyPackage?.expiry_date)
      const diffDays = Math.round(Math.abs((expiryDate.getTime() - new Date().getTime()) / oneDay))
      this.setState({
        companySummary: companySummary,
        daysToExpire: diffDays,
      })
    })

    this.fetchDashboardFeatureFlags()
  }

  fetchDashboardFeatureFlags = ()=> {

    // const featureFlags = getDashboardRoutes()
    const featureFlags = Array.from(new Set(getDashboardRoutes().filter(ff => !!ff.requiredFeatureFlags).flatMap(ff => ff.requiredFeatureFlags)))
    // a = featureFlags.flatMap(ff => ff.requiredFeatureFlags)
    // a = featureFlags.filter(ff => !!ff.requiredFeatureFlags).flatMap(ff => ff.requiredFeatureFlags)
    // const unique = myArray.filter((v, i, a) => a.indexOf(v) === i);
    // Array.from(new Set(featureFlags.filter(ff => !!ff.requiredFeatureFlags).flatMap(ff => ff.requiredFeatureFlags)))
    // Array.from(arrayLike object)
    // eslint-disable-next-line
    // debugger
    agentsJsonapiService.getDashboardFeatureFlags(featureFlags).then((response: any) => {

      // this.setState({dashboardRoutes: getDashboardRoutes()})
      const flagsStrings = response.filter( flag => flag.value).map( flag => flag.name)
      // todo after we get ff we store in context or just recreate the dashboard routes
      const newDashboardRoutes = getDashboardRoutes().filter(ff => !ff.requiredFeatureFlags || (!!ff.requiredFeatureFlags.find(flag => flagsStrings.includes(flag))))
      this.setState({dashboardRoutes: newDashboardRoutes})
    })
  }

  toggleSidebar = () => {
    const sidebarState = this.state.showSidebar
    this.setState({ showSidebar: !sidebarState })
  }

  goHome() {
    this.props.history.push(getDashboardRoutes()[0].path)
  }

  logOut() {
    setAuthorizationToken(false)
    document.location.href = '/#/login'
    return
  }

  render() {
    return (
      <div className="d-flex justify-content-between">
        <Sidebar showSidebar={this.state.showSidebar} dashboardRoutes={this.state.dashboardRoutes}>

        </Sidebar>
        <main style={{width: '100%', height: '100vh'}} className="main-section">
          <Header
            user={this.state.user}
            daysToExpire={this.state.daysToExpire}
            onHome={this.goHome}
            onLogout={this.logOut}
            toggleSidebar={this.toggleSidebar}
          />
          <Main companySummary={this.state.companySummary} routes={this.state.dashboardRoutes} />
        </main>
      </div>
    )
  }
}

export default Dashboard
