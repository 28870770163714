import React, { Component } from 'react';
import { CompanyRequest } from '../dashboard/pages/accountPage/helpers';

interface Props {
  formChanges: (formData: any) => void;
  companyRequest?: CompanyRequest;
}

interface State {
  name?: string;
  location?: string;
  token?: number;
  freeVersion?: boolean;
}

export default class CompanyForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const form = event.target.name;
    const state = this.state;
    state[form] = event.target.value;

    if (event.target.type === 'checkbox') {
      state[form] = +event.target.checked;
    } else {
      state[form] = event.target.value;
    }

    this.setState(state);
    this.props.formChanges(state);
  }

  render() {
    return (
      <div>
        <div className="form-group">
          <label htmlFor="name">Company name</label>
          <input
            value={this.props.companyRequest?.name}
            name="name"
            onChange={this.handleChange}
            type="text"
            className="form-control"
            id="name"
            aria-describedby="namehelp"
            placeholder="company name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="location">Location</label>
          <input
            value={this.props.companyRequest?.location}
            name="location"
            onChange={this.handleChange}
            type="text"
            className="form-control"
            id="location"
            placeholder="location"
          />
        </div>
        <div className="form-group form-check">
          <input
            name="freeVersion"
            onChange={this.handleChange}
            type="checkbox"
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor="token">
            Free version
          </label>
        </div>

        {!this.state.freeVersion && (
          <div className="form-group">
            <label htmlFor="token">Package token</label>
            <input
              value={this.props.companyRequest?.token}
              name="token"
              onChange={this.handleChange}
              type="text"
              className="form-control"
              id="token"
              placeholder="token"
            />
          </div>
        )}
      </div>
    );
  }
}
