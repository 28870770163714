import React, { ReactElement } from 'react'
import { Redirect, Switch, useLocation } from 'react-router-dom'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'
import RouteWithSubRoutes from '../../../RouteWithSubRoutes'
import { CustomRoute } from '../../dashboard-routes'
import { StyledBreadcrumb, StyledPageNav } from '../../../lib/styled'
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'

export const AssetsTab = () => {
  return <div>AssetsTab</div>
}

interface Props {
  routes: CustomRoute[]
}

export default function AssetContainer({ routes }: Props): ReactElement {
  const location = useLocation()
  const breadcrumbUrls = location.pathname.split('/')

  return (
    <DashboardPage2 hideTitle>
      <StyledPageNav>
        <StyledBreadcrumb>
          {breadcrumbUrls.map((breadcrumbUrl, i) => (
            <BreadcrumbItem
              active={location.pathname === '/assets'}
              url={breadcrumbUrl}
              title={breadcrumbUrl}
              key={i}
            />
          ))}
        </StyledBreadcrumb>
      </StyledPageNav>

      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} exact />
        ))}
        <Redirect from="/" to={routes[0].path} />
      </Switch>
    </DashboardPage2>
  )
}
