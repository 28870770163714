import { AxiosInstance, AxiosResponse } from 'axios';
import { AuthApi } from './api';
import { baseAxios } from './api.config';
import {
  CompanyResponse,
  CompanyDetailResponse,
  PackageResponse,
  PackageTokenResponse,
  PackageTokenRequest,
} from '../dashboard/pages/accountPage/helpers';

export class SupportApi extends AuthApi {
  public constructor(baseAxios?: AxiosInstance) {
    super(baseAxios);
  }

  getAllCompanies(): Promise<CompanyResponse[]> {
    return this.get<CompanyResponse[]>(`/ops/companies`).then(this.success);
  }

  findCompanyDetail(id: number): Promise<CompanyDetailResponse> {
    return this.get<CompanyDetailResponse>(`/ops/companies/${id}`).then(
      this.success
    );
  }

  getAllTokens(): Promise<PackageTokenResponse[]> {
    return this.get<PackageTokenResponse[]>(`/ops/package-tokens`).then(
      this.success
    );
  }

  getAllPackages(): Promise<PackageResponse[]> {
    return this.get<PackageResponse[]>(`/ops/packages`).then(this.success);
  }

  getPackageDetail(packageNumber: number): Promise<PackageResponse> {
    return this.get<PackageResponse>(`/ops/packages/${packageNumber}`).then(
      this.success
    );
  }

  getPackageCompanies(packageNumber: number): Promise<CompanyResponse[]> {
    return this.get<CompanyResponse[]>(
      `/ops/packages/${packageNumber}/companies`
    ).then(this.success);
  }

  createToken(packageId: number): Promise<PackageTokenResponse> {
    const packageToken = { package_id: packageId } as PackageTokenRequest;
    return this.post<
      string,
      PackageTokenRequest,
      AxiosResponse<PackageTokenResponse>
    >(`/ops/package-tokens`, packageToken).then(this.success);
  }
}

export const supportApi = new SupportApi(baseAxios);
