

const dateFormat = (dateString, format = 'MMMM Do YYYY, h:mm:ss a') => {
  if (dateString) {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }
}

export default dateFormat
