import React, { Component } from 'react';
import './login.css';
import LoginForm from './LoginForm';
import { userApi } from '../utils/user-api';
import { Link } from 'react-router-dom';
import MessagesContext from '../utils/MessagesContext';

interface Props {
  history: any[];
}

interface State {
  showLoginOptions?: boolean;
  userPayload?: { access_token: string; accounts: any[]; user: any };
  loginRequest?: any;
}

class Login extends Component<Props, State> {
  static contextType = MessagesContext;

  constructor(props) {
    super(props);
    this.state = {
      showLoginOptions: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.goToDashboard = this.goToDashboard.bind(this);
    this.goToSupport = this.goToSupport.bind(this);
  }

  handleFormSubmit(formData) {
    userApi.userLogin(formData).then(
      (userPayload: { access_token: string; accounts: any[]; user: any }) => {
        this.setState({ userPayload: userPayload });
        if (
          userPayload.user.internal_user &&
          userPayload.accounts.length === 0
        ) {
          this.goToSupport();
          return;
        }
        if (userPayload.user.internal_user && userPayload.accounts.length > 0) {
          this.setState({
            showLoginOptions: true,
            loginRequest: {
              ...formData,
              companyRef: userPayload.accounts[0].company.id,
            },
          });
          return;
        }

        if (userPayload.accounts.length > 0) {
          this.setState({
            loginRequest: {
              ...formData,
              companyRef: userPayload.accounts[0].company.id,
            },
          });
          this.goToDashboard();
        }
      },
      (error) => {
        if (error.message) {
          this.context.setmessage('error', error.message);
        } else {
          this.context.setmessage('error', 'Network error');
        }
      }
    );
  }

  goToDashboard() {
    userApi
      .accountLogin(this.state.loginRequest)
      .then((accountPayload: any) => {
        localStorage.setItem('token', accountPayload.access_token);
        localStorage.setItem('user', JSON.stringify(accountPayload));
        this.props.history.push('/');
      });
  }

  goToSupport() {
    if (this.state.userPayload) {
      localStorage.setItem('token', this.state.userPayload?.access_token);
      localStorage.setItem('user', JSON.stringify(this.state.userPayload));
      localStorage.setItem('supportRender', JSON.stringify(true));

      this.props.history.push('/');
    }
  }

  render() {
    return (
      <div>
        <div className="login">
          <div className="login-card">
            <div className="register-header">Login to Pro Assets</div>
            <LoginForm submitForm={this.handleFormSubmit}>
              {this.state.showLoginOptions ? (
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: '8px 0' }}
                >
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={this.goToDashboard}
                  >
                    Dashboard
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={this.goToSupport}
                  >
                    Support
                  </button>
                </div>
              ) : null}
            </LoginForm>

            <div className="login-request">
              <p> Need an account? </p>
              <Link className="umami--click--register-link" to="/register">
                signup
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
