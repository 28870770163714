import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { required } from '../forms/validators';
import { SFormErrors } from '../forms/css/variables.styles';
import {
  AssetResponse,
  HouseMinimumContractResponse,
  MissingPaymentResponse,
  MissingPaymentsMap,
  PaymentFormRequest,
} from '../../dashboard/pages/accountPage/helpers';
import { housesApi } from '../../utils/HousesApi';
import PaymentModalForm from './PaymentModalForm';
import { assetsApi } from '../../utils/assets-api';
import MessagesContext from '../../utils/MessagesContext';
import { dateToDateString } from '../utils/date.utils';
import { PaymentDateTypeEnum } from '../enum/PaymentDateType.enum';

export interface PaymentFormProps {
  hideModal: () => void;
  paymentFormRequest?: any;
  assets?: AssetResponse[];
  selectedAssetId?: number;
}

export interface PaymentFormState {
  houses?: any[];
  missingPayments?: [];
  selectedAsset?: AssetResponse;
  earliestMissingPayment?: MissingPaymentResponse;
  selectedPaymentDate?: Date;
  selectedDatePayed?: Date;
  initialFormData?: { amount: number; startDate: any };
  selectedHouse?: HouseMinimumContractResponse;
}

export class PaymentForm extends Component<PaymentFormProps, PaymentFormState> {
  static contextType = MessagesContext;
  constructor(props) {
    super(props);
    this.state = {
      selectedPaymentDate: new Date(),
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onHouseChange = this.onHouseChange.bind(this);
  }

  onChange(event, assets?: AssetResponse[]) {
    const value = event.target.value;
    const selectedAsset =
      assets && assets.find((asset) => asset.id === parseInt(value));
    if (selectedAsset) {
      this.setState({
        houses: selectedAsset.houses,
        selectedAsset: selectedAsset,
      });
    }
    return selectedAsset;
  }

  onSubmit(event) {
    const paymentRequest: PaymentFormRequest = {
      rent: event.amount,
      type: event.type,
      paymentCode: event.paymentCode,
      paymentDate: event.startDate.split('T')[0],
      datePayed: event.datePayed.split('T')[0],
      smsNotification: Boolean(parseInt(event.smsNotification)),
    };

    if (this.state.selectedHouse && this.state.selectedHouse.currentContract) {
      assetsApi
        .createPayment(
          event.asset,
          this.state.selectedHouse.id,
          this.state.selectedHouse.currentContract.id,
          paymentRequest
        )
        .then(() => {
          this.context.setmessage('success', `payment created successfully`);
          this.props.hideModal();
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            for (const error of Object.values(response.data.errors)) {
              this.context.setmessage('error', error);
            }
          } else if (response.data.message) {
            this.context.setmessage('error', response.data.message);
          }
        });
    }
  }

  onHouseChange(event, houses?: HouseMinimumContractResponse[]) {
    const value = event.target.value;

    const selectedHouse =
      houses && houses.find((asset) => asset.id === parseInt(value));

    this.setState({
      earliestMissingPayment: undefined,
      selectedHouse: undefined,
      selectedPaymentDate: undefined,
      selectedDatePayed: undefined,
      initialFormData: undefined,
    });

    const currentDate = new Date();
    if (this.state.selectedAsset && selectedHouse) {
      housesApi
        .getAllHouseMissingPayments(
          this.state.selectedAsset.id,
          selectedHouse.id,
          selectedHouse.currentContract?.id,
          dateToDateString(currentDate)
        )
        .then((missingPaymentsMap: MissingPaymentsMap) => {
          const sortedPayments = Object.keys(missingPaymentsMap).sort(function (
            a,
            b
          ) {
            return new Date(a).getTime() - new Date(b).getTime();
          });

          if (sortedPayments.length) {
            const earliestDate = new Date(sortedPayments[0]);

            const earliestMissingPayment =
              missingPaymentsMap[sortedPayments[0]];
            const initialFormData = {
              amount: earliestMissingPayment.remainingAmount,
              startDate: earliestDate.toISOString().split('T')[0],
              includeDates: sortedPayments.map(
                (sortedPayment) => new Date(sortedPayment)
              ),
              contractDate: selectedHouse.currentContract?.start_date,
            };

            this.setState({
              earliestMissingPayment: earliestMissingPayment,
              selectedHouse: selectedHouse,
              selectedPaymentDate: new Date(sortedPayments[0]),
              selectedDatePayed: new Date(sortedPayments[0]),
              initialFormData: initialFormData,
            });
          } else {
            // todo allow make payment when not in arrears
          }
        });
    }

    return value;
  }

  handleDateChange(format, date) {
    if (format === PaymentDateTypeEnum.StartDate) {
      this.setState({
        selectedPaymentDate: date,
      });
    } else if (format === PaymentDateTypeEnum.DatePayed) {
      this.setState({
        selectedDatePayed: date,
      });
    }
    return true;
  }

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        render={({ handleSubmit, form, submitting }) => (
          <form
            {...Form}
            onSubmit={handleSubmit}
            style={{ padding: 0, width: '100%' }}
          >
            <div className="form-row">
              <Field
                name="asset"
                validate={required}
                initialValue={this.props.selectedAssetId}
              >
                {({ input, meta }) => (
                  <div className="form-group  col-md-6">
                    <label htmlFor="type">Property</label>
                    <select
                      {...input}
                      id="assets"
                      className="form-control"
                      onChange={(event) =>
                        this.onChange(event, this.props.assets) &&
                        input.onChange(event)
                      }
                      disabled={!!this.props.selectedAssetId}
                    >
                      <option></option>
                      {this.props.assets?.map((asset: AssetResponse, i) => (
                        <option key={i} value={asset.id}>
                          {asset.name} (
                          {
                            asset.houses.filter(
                              (hous) => !!hous.currentContract
                            ).length
                          }
                          /{asset.houses.length})
                        </option>
                      ))}
                    </select>
                    <SFormErrors>
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </SFormErrors>
                  </div>
                )}
              </Field>

              <Field name="house" validate={required}>
                {({ input, meta }) => (
                  <div className="form-group  col-md-6">
                    <label htmlFor="house">House</label>
                    <select
                      {...input}
                      id="type"
                      className="form-control"
                      onChange={(event) =>
                        this.onHouseChange(event, this.state.houses) &&
                        input.onChange(event)
                      }
                    >
                      <option></option>
                      {this.state.houses?.map((house, i) =>
                        house.currentContract ? (
                          <option key={i} value={house.id}>
                            {house.house_number}
                          </option>
                        ) : (
                          <option key={i} disabled value={house.id}>
                            {house.house_number} (vacant)
                          </option>
                        )
                      )}
                    </select>
                    <SFormErrors>
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </SFormErrors>
                  </div>
                )}
              </Field>
            </div>

            {this.state.initialFormData && (
              <>
                <hr />
                <PaymentModalForm initialData={this.state.initialFormData} />
              </>
            )}
            {this.state.selectedHouse?.currentContract?.tenant.phone_number ? (
              <div className="form-row">
                <p>
                  sms will be send to:
                  <strong>
                    {
                      this.state.selectedHouse.currentContract?.tenant
                        .phone_number
                    }
                  </strong>
                </p>

                <Field name="smsNotification" validate={required}>
                  {({ input, meta }) => (
                    <div className="form-group col-md-6">
                      <label htmlFor="smsNotification">Sms notification</label>
                      <select
                        {...input}
                        id="smsNotification"
                        className="form-control"
                      >
                        <option></option>
                        <option value={0}>No</option>
                        <option value={1}>Yes</option>
                      </select>
                      <SFormErrors>
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </SFormErrors>
                    </div>
                  )}
                </Field>
              </div>
            ) : null}
            <div className="buttons d-flex">
              <button
                style={{ marginRight: '10px' }}
                onClick={this.props.hideModal}
                className="btn btn-secondary mr-auto"
              >
                close
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={submitting}
              >
                Submit
              </button>
            </div>
          </form>
        )}
      />
    );
  }
}
