import { invoicesApi } from '../../../utils/invoices-api'

export const createRentInvoiceItem = async (contractId, invoiceDetailId, amount) => {
  return await invoicesApi.createPaymentInvoiceItem(contractId, invoiceDetailId, JSON.parse(amount)).then(
    (response) => {
      console.log('response', response)
    },
    (error) => {
      console.log('error', error)
    },
  )
}
