import React, { Component } from 'react';
import DashboardPage from '../../../lib/dashboard-page/DashboardPage';
import PaymentForm from './PaymentForm';
import { assetsApi } from '../../../utils/assets-api';
import {
  HouseResponse,
  PaymentResponse,
  PaymentFormRequest,
} from '../accountPage/helpers';
import MessagesContext from '../../../utils/MessagesContext';
import { setGALocation } from '../overview/utils';

interface Props {
  history: any;
}

interface State {
  houseId: number;
  assetId: number;
  house?: HouseResponse;
  missingPayments?: ContractMissingPaymentsResponse;
  initialPaymentValue?: any;
}

export interface ContractMissingPaymentsResponse {
  [index: string]: any[];
}

export default class CreatePaymentPage extends Component<Props, State> {
  static contextType = MessagesContext;
  constructor(props) {
    super(props);
    this.state = {
      houseId: JSON.parse(props.match.params.houseId),
      assetId: JSON.parse(props.match.params.assetId),
    };
    this.createPayment = this.createPayment.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    setGALocation();
    assetsApi
      .findAssetHouse(this.state.assetId, this.state.houseId)
      .then((houseResponse: HouseResponse) => {
        this.setState({ house: houseResponse });
        if (houseResponse.currentContract) {
          this.findMissingPaymentsAndSetMinDate(
            this.state.assetId,
            this.state.houseId,
            houseResponse.currentContract.id
          );
        }
      });
  }

  findMissingPaymentsAndSetMinDate = (assetId, houseId, contractId) => {
    console.log(assetId);
    assetsApi
      .findContractMissingPayments(assetId, houseId, contractId)
      .then((missingPaymentsResponse: ContractMissingPaymentsResponse) => {
        const minDate = Object.keys(missingPaymentsResponse).sort(
          (a, b) => new Date(a).getTime() - new Date(b).getTime()
        );

        if (minDate.length > 0) {
          this.setState({
            missingPayments: missingPaymentsResponse,
            initialPaymentValue: missingPaymentsResponse[minDate[0]],
          });
        }
      });
  };

  createPayment(paymentRequest: PaymentFormRequest) {
    const house = this.state.house;

    if (house && house.currentContract) {
      assetsApi
        .createPayment(
          this.state.assetId,
          house.id,
          house.currentContract.id,
          paymentRequest
        )
        .then((paymentResponse: PaymentResponse) => {
          this.context.setmessage('success', 'payment created successfully');
          this.goBack();
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            for (const error of Object.values(response.data.errors)) {
              this.context.setmessage('error', error);
            }
          } else if (response.data.message) {
            this.context.setmessage('error', response.data.message);
          }
        });
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <DashboardPage pageName={'Create Payment'}>
        <p style={{ paddingTop: '10px' }}>
          Creat payment for house:{' '}
          <strong> {this.state.house?.house_number} </strong>tenant:{' '}
          <strong>{`${this.state.house?.currentContract?.tenant.full_names}`}</strong>{' '}
        </p>

        <div className="d-flex">
          <div className=" mb-6">
            {this.state.house ? (
              <PaymentForm
                createPayment={this.createPayment}
                contract={this.state.house?.currentContract}
                initialPaymentValue={this.state.initialPaymentValue}
              />
            ) : null}
          </div>
        </div>
      </DashboardPage>
    );
  }
}
