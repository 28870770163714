import { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'
import { useState, useCallback, useMemo, useEffect } from 'react'
import setAuthorizationToken from './setAuthorizationToken'

export class Api {
  [x: string]: any
  public constructor(baseAxios?: AxiosInstance) {
    this.api = baseAxios

    this.getUri = this.getUri.bind(this)
    this.request = this.request.bind(this)
    this.get = this.get.bind(this)
    this.delete = this.delete.bind(this)
    this.head = this.head.bind(this)
    this.post = this.post.bind(this)
    this.put = this.put.bind(this)
    this.patch = this.patch.bind(this)
  }

  public getUri(config?: AxiosRequestConfig): string {
    return this.api.api.getUri(config)
  }

  /**
   * Generic request.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP axios response payload.
   * @memberof Api
   *
   */
  public request<T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.api.api.request(config)
  }

  /**
   * HTTP GET method, used to fetch data `statusCode`: 200.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   * @memberof Api
   */
  public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.request(url, config).catch(this.error)
  }

  /**
   * HTTP DELETE method, `statusCode`: 204 No Content.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public delete<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.delete(url, config)
  }

  /**
   * HTTP HEAD method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public head<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.head(url, config)
  }

  /**
   * HTTP POST method `statusCode`: 201 Created.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   *
   *
   * return this.post<InvoiceResponse, InvoiceRequest>(
   *		`/invoices/contracts/${contractId}/invoices`,
   *		{
   *			invoice_date: invoiceDate,
   *		}
   *	).then(this.success);
   *
   *
   *
   */
  public post<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.api.request(url, { method: 'POST', data: data, ...config })
  }

  /**
   * HTTP PUT method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public put<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.api.put(url, data, config)
  }

  /**
   * HTTP PATCH method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public patch<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.api.patch(url, data, config)
  }

  /**
   *
   * @template T - type.
   * @param {import("axios").AxiosResponse<T>} response - axios response.
   * @returns {T} - expected object.
   * @memberof Api
   */
  public success<T>(response: AxiosResponse<T>): T {
    return response.data
  }

  public error(error: AxiosError<Error>) {
    if (error.response) {
      throw error.response.data
    }
    throw error
  }
}

export class AuthApi extends Api {
  public constructor(baseAxios?: AxiosInstance) {
    super(baseAxios)

    this.api.interceptors.request.use(
      (param: AxiosRequestConfig) => {
        const token = localStorage.getItem('token')
        if (!token) {
          return param
        } else {
          const headers = {
            ...param.headers,
            Authorization: `Bearer ${token}`,
          }
          return { ...param, headers }
        }
      },
      (error) => {
        // console.log('errorr', error)
        return this.error
      },
    )

    this.api.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error) => {
        // console.log('items', error);
        if (error.response && error.response.status === 401) {
          setAuthorizationToken(false)
          document.location.href = '/#/login'
        }
        // console.log('items', error);
        return Promise.reject(error)
      },
    )
  }
}

export const useAxiosLoader = (ax: any) => {
  const [counter, setCounter] = useState(0)
  const inc = useCallback(() => setCounter((counter) => counter + 1), [setCounter]) // add to counter
  const dec = useCallback(() => setCounter((counter) => counter - 1), [setCounter]) // remove from counter

  const interceptors = useMemo(
    () => ({
      // eslint-disable-next-line
      request: (config: AxiosRequestConfig) => (inc(), config), // eslint-disable-next-line
      response: (response: any) => (dec(), response), // eslint-disable-next-line
      error: (error: any) => (dec(), Promise.reject(error)), // eslint-disable-next-line
    }),
    [inc, dec],
  ) // create the interceptors

  useEffect(() => {
    const reqInterceptor = ax.interceptors.request.use(interceptors.request, interceptors.error)
    const resInterceptor = ax.interceptors.response.use(interceptors.response, interceptors.error)
    return () => {
      ax.interceptors.request.eject(reqInterceptor)
      ax.interceptors.response.eject(resInterceptor)
    }
  }, [ax.interceptors.request, ax.interceptors.response, interceptors])

  return counter > 0
}

export function catchError(error: AxiosError<Error>, setmessage) {
  console.log('items', error.response)

  if (error.response.data && error.response.data.message) {
    setmessage('error', error.response.data.message)
  }
}
