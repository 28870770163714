import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { StyledBreadcrumb, StyledPageNav } from '../../../lib/styled'
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'
import PageSection from '../../../lib/dashboard-page/PageSection'
import { Form } from 'react-final-form'
import DatePicker from 'react-datepicker'
import { assetsApi } from '../../../utils/assets-api'
import MessagesContext from '../../../utils/MessagesContext'
import { useHistory } from 'react-router-dom'
import { convertSearchToRouteParams } from '../accountPage/helpers'
import { InvoiceItemTypeEnum } from '../../../lib/enum/InvoiceItemTypeEnum'
import { CreateExpensesInvoiceItemTableForm } from './CreateExpensesInvoiceItemTableForm'
import arrayMutators from 'final-form-arrays'
import { CreatePaymentsInvoiceItemTableForm } from './CreatePaymentsInvoiceItemTableForm'
import { createRentInvoiceItem } from './utils'

interface CreateInvoicePageUrlParams {
  asset?: string
  contract?: string
  selectedDate?: string
  selectedRent?: string
}

export default function CreateInvoicePage(): ReactElement {
  const [assetsHousesMultiSelect, setAssetsHousesMultiSelect] = useState<any>([])
  const [selectedAsset, setSelectedAsset] = useState<any>()
  const [selectedContractId, setSelectedContractId] = useState<any>()
  const [selectedDate, setSelectedDate] = useState<any>()
  const { setmessage } = useContext(MessagesContext)
  const history = useHistory()
  const [newInvoiceItems, setNewInvoiceItems] = useState<any>()

  const pageUrlParams: CreateInvoicePageUrlParams = convertSearchToRouteParams(
    history.location.search,
  ) as unknown as CreateInvoicePageUrlParams
  const [formState, setFormState] = useState<any>({ name: '', selectedDate: new Date() })
  const [selectedAssetHousesMultiSelect, setSelectedAssetHousesMultiSelect] = useState<any>()

  const handleSubmit = (event: any) => {
    console.log('handleSubmit', event)
  }

  const handleAssetChange = (event: any) => {
    console.log('handleChange', event)
    setFormState({ ...formState, name: event.target.value })
    fetchContractsForAsset(assetsHousesMultiSelect.find((ahm) => ahm.id === parseInt(event.target.value)))
  }

  const handleHouseChange = (event: any) => {
    console.log('handleChange', event)
    setFormState({ ...formState, contract: event.target.value })
  }

  const handleDateChange = (event: any) => {
    console.log('handleChange', event)
    console.log('formState', formState)
    setFormState({ ...formState, selectedDate: event })
  }

  // useEffect(() => {
  //   assetsApi.v2GetAssets().then(({ items }) => {
  //     setAssetsHousesMultiSelect(items)
  //   })
  // }, [])

  useEffect(() => {
    setInitialData(convertSearchToRouteParams(history.location.search))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search])

  // useEffect(() => {
  //   fetchContractsForAsset(selectedAsset)
  // }, [selectedAsset])

  const setInitialData = (data) => {
    console.log('data', data)
    const tempFormState = {}
    if (data.asset) {
      setSelectedAsset(data.asset)
      tempFormState['name'] = data.asset

      fetchContractsForAsset({ id: data.asset })
      // todo fetch asset detail and display
    }
    if (data.contract) {
      tempFormState['contract'] = data.contract
      // setFormState({ ...formState, contract: data.contract });
      // setSelectedContractId(data.contract)
    }
    if (data.selectedDate) {
      tempFormState['selectedDate'] = new Date(data.selectedDate)
      setSelectedDate(new Date(data.selectedDate))
    }

    setFormState({ ...formState, ...tempFormState })
    assetsApi.v2GetAssets().then(({ items }) => {
      setAssetsHousesMultiSelect(items)
    })
  }

  const [selectedHouseInput, setSelectedHouseInput] = useState<any>()

  const fetchContractsForAsset = (asset) => {
    console.log('asset', asset)
    if (!asset) {
      return
    }
    assetsApi.v2GetContractsByAsset(asset.id).then(({ items }) => {
      setSelectedAssetHousesMultiSelect(items)
    })
  }

  const createNewInvoice = (contractId, invoiceDate) => {
    assetsApi.createNewInvoice(contractId, invoiceDate).then(
      (invoiceResponse) => {
        history.push(invoiceResponse.id.toString())
      },
      ({ response }) => {
        if (response.data.message) {
          setmessage(response.data.message)
        } else {
          console.error(response)
        }
      },
    )
  }

  const onSubmit = (formValues) => {
    // const selectedAsset = assetsHousesMultiSelect.find(
    // 	(ahm) => ahm.id === parseInt(formValues.selectedAsset)
    // );

    createNewInvoice(selectedHouseInput.id, formValues.invoiceDate)
  }

  const onSubmittt = (formValues) => {
    // const selectedAsset = assetsHousesMultiSelect.find(
    // 	(ahm) => ahm.id === parseInt(formValues.selectedAsset)
    // );
    console.log('formValues', formValues, formState)
    const newRentItems = formValues.expenses.filter((expense) => expense.expense_id === InvoiceItemTypeEnum.RENT)
    assetsApi.createNewInvoice(formState.contract, formState.selectedDate).then(
      (invoiceResponse) => {
        newRentItems.forEach((expense) => createRentInvoiceItem(formState.contract, invoiceResponse.id, expense.amount))
        history.push(invoiceResponse.id.toString())
      },
      ({ response }) => {
        if (response.data.message) {
          setmessage(response.data.message)
        } else {
          console.error(response)
        }
      },
    )
  }

  const onExpenseInvoiceItemSubmit = (event) => {
    console.log('event', event)
    if (event) {
      const newItems = event.expenses.filter((expense) => expense.newItem)
      const newExpenseItems = newItems.filter((expense) => expense.expense_id !== InvoiceItemTypeEnum.RENT)

      const newRentItems = newItems.filter((expense) => expense.expense_id === InvoiceItemTypeEnum.RENT)
      console.log('newRentItems', newRentItems)
      console.log('newExpenseItems', newExpenseItems)
    }
  }

  return (
    <DashboardPage2 hideTitle={true}>
      <StyledPageNav>
        <StyledBreadcrumb>
          <BreadcrumbItem url={'/invoices'} title={'invoices'} />
          <BreadcrumbItem active title={'create'} />
        </StyledBreadcrumb>
      </StyledPageNav>

      <PageSection>
        <div>
          <div className="mt-3 mb-3">New Invoice</div>
          <Form
            onSubmit={(values) => onSubmit(values)}
            mutators={{
              // potentially other mutators could be merged here
              ...arrayMutators,
            }}
            initialValues={{
              expenses: [{ expense_id: InvoiceItemTypeEnum.RENT, amount: parseFloat(pageUrlParams.selectedRent) }],
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form className="form p-0">
                <div className="d-flex">
                  <div className="form-group p-2">
                    <label> Asset </label>
                    {/*<input className="form-control" type="text" value={formState.name} onChange={handleAssetChange} />*/}
                    <select className="form-control" value={formState.name} onChange={handleAssetChange}>
                      <option />
                      {assetsHousesMultiSelect.map((asset, index) => (
                        <option key={index} value={asset.id}>
                          {asset.name} ({asset.houses.length})
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group p-2">
                    <label> Contract </label>
                    {/*<input className="form-control" type="text" value={formState.name} onChange={handlehouseChange} />*/}
                    <select className="form-control" value={formState.contract} onChange={handleHouseChange}>
                      <option />
                      {selectedAssetHousesMultiSelect?.map((assetHouses, index) => (
                        <option key={index} value={assetHouses.id}>
                          {assetHouses.tenant.full_names} ({assetHouses.house.house_number})
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group p-2">
                    <label> Date </label>
                    {/*<input className="form-control" type="text" value={formState.name} onChange={handleDateChange} />*/}
                    <DatePicker
                      className="form-control"
                      selected={formState.selectedDate}
                      onChange={handleDateChange}
                    />
                  </div>
                </div>

                <div className="card p-2 mb-4">
                  <CreateExpensesInvoiceItemTableForm
                    onSubmit={onExpenseInvoiceItemSubmit}
                    expenses={[]}
                    tableInitialValues={
                      pageUrlParams.selectedRent
                        ? [{ expense_id: InvoiceItemTypeEnum.RENT, amount: parseFloat(pageUrlParams.selectedRent) }]
                        : []
                    }
                  />
                </div>

                <div className="d-flex flex-row-reverse p-3">
                  <button className="btn btn-primary" type="button" onClick={() => onSubmittt(values)}>
                    Create invoice
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </PageSection>
    </DashboardPage2>
  )
}
