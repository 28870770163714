import React, { Component } from 'react'
import DashboardPage from '../../../lib/dashboard-page/DashboardPage'
import { assetsApi } from '../../../utils/assets-api'
import { AssetResponse, PaymentResponse, PageParams, getAllRouteParams, updatePageParams } from '../accountPage/helpers'
import DatePicker from 'react-datepicker'
import dateFormat from '../../shared/helpers/dateFormat'
import moneyFormat from '../../shared/helpers/moneyFormat'
import { exportCSVFile } from '../../shared/helpers/helpers'
import qs from 'qs'
import SimplePagination from '../../components/SimplePagination'
import { PaginationResponse } from '../assetPage/HousesTable'
import { FaSms } from 'react-icons/fa'
import { assetsToIds } from './utils'
import { setGALocation } from '../overview/utils'

interface Props {
  location
  history
}

interface State {
  assets?: AssetResponse[]
  payments?: PaymentResponse[]
  startDate: Date
  selectedAssetId?: any
  pagination?: PaginationResponse
}

export default class ReportsPage extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      startDate: new Date(),
    }

    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.fetchPayments = this.fetchPayments.bind(this)
    this.downloadCSVFile = this.downloadCSVFile.bind(this)
  }

  componentDidMount() {
    setGALocation()
    const newQueryString = qs.stringify(updatePageParams(this.props.location, { page: 1, size: 10 }))
    this.props.history.replace({ search: newQueryString })

    assetsApi.getAssets().then((assets) => {
      this.setState({ assets })
      if (assets.length) {
        this.fetchPayments(
          assets.map((asset) => asset.id),
          this.state.startDate,
          1,
          10,
        )
      }
    })

    const queries: PageParams = getAllRouteParams(this.props.location)
    if (!queries.page || !queries.size) {
      const newQueryString = qs.stringify(updatePageParams(this.props.location, { page: 1, size: 10 }))
      this.props.history.replace({ search: newQueryString })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const queries: PageParams = getAllRouteParams(this.props.location)
      if (queries.page && queries.size && this.state.selectedAssetId) {
        this.fetchPayments([this.state.selectedAssetId], this.state.startDate, queries.page, queries.size)
      }
    }
  }

  fetchPayments(assetIds: number[], startDate: Date, page, size) {
    assetsApi.findPayments(assetIds, startDate, page, size).then((paymentsPage: any) => {
      this.setState({
        payments: paymentsPage.items,
        pagination: paymentsPage.pagination,
      })
    })
  }

  handleDateChange(date: Date) {
    this.setState({ startDate: date })
    const queries: PageParams = getAllRouteParams(this.props.location)
    if (this.state.assets && (this.state.selectedAssetId === 'All' || !this.state.selectedAssetId)) {
      this.fetchPayments(assetsToIds(this.state.assets), date, queries.page, queries.size)
      return
    }
    if (this.state.selectedAssetId) {
      this.fetchPayments([this.state.selectedAssetId], date, queries.page, queries.size)
    }
  }

  downloadCSVFile() {
    const fileTitle = `reports-${this.state.startDate}`
    const headers = {
      asset: 'Asset'.replace(/,/g, ''), // remove commas to avoid errors
      house: 'House',
      paymentFor: 'Payment Date',
      paymentType: 'Payment Type',
      amount: 'Amount',
    }

    const itemsFormatted: any[] = []

    this.state.payments?.forEach((payment: PaymentResponse) => {
      itemsFormatted.push({
        asset: payment.house?.asset.name,
        house: payment.house?.house_number,
        paymentFor: payment.payment_date,
        paymentType: payment.type,
        amount: payment.rent,
      })
    })

    exportCSVFile(headers, itemsFormatted, fileTitle)
  }

  handleChange(event) {
    const queries: PageParams = getAllRouteParams(this.props.location)

    const selectedAssetId = event.target.value
    if (this.state.assets && selectedAssetId === 'All') {
      this.setState({ selectedAssetId: selectedAssetId })

      this.fetchPayments(assetsToIds(this.state.assets), this.state.startDate, queries.page, queries.size)
    } else {
      this.setState({ selectedAssetId: selectedAssetId })
      this.fetchPayments([selectedAssetId], this.state.startDate, queries.page, queries.size)
    }
  }

  updatePaginationParams(queryParams: any) {
    const newQueryString = qs.stringify(updatePageParams(this.props.location, queryParams))
    this.props.history.replace({ search: newQueryString })
  }

  render() {
    return (
      <DashboardPage pageName={'Reports'}>
        <div className="form-row">
          <div className="d-flex">
            <div className="form-group align-self-end">
              <button type="button" className="btn btn-secondary" onClick={this.downloadCSVFile}>
                Download csv
              </button>
            </div>
          </div>

          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
            className="form-group pr-2"
          >
            <label htmlFor="assets-selector">assets</label>
            <select
              onChange={this.handleChange}
              value={this.state.selectedAssetId}
              className="form-control umami--click--select-report-asset-button"
              id="assets-selector"
            >
              <option>All</option>
              {this.state.assets?.map((asset, i) => (
                <option value={asset.id} key={i}>
                  {asset.name}
                </option>
              ))}
            </select>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }} className="form-group report-datepicker">
            <label>start date</label>
            <DatePicker
              id="startDate"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="form-control"
              selected={this.state.startDate}
              onChange={(date) => this.handleDateChange(date)}
            />
          </div>
        </div>

        <div style={{ overflow: 'scroll' }}>
          <table className="table thead-dark table-sm ">
            <thead>
              <tr>
                <th>#</th>
                <th>Asset</th>
                <th>House</th>
                <th>Payment For</th>
                <th>Type</th>
                <th>Payment Code</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {this.state.payments?.map((payment, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{payment.house?.asset.name}</td>
                  <td>{payment.house?.house_number}</td>
                  <td>{dateFormat(payment.payment_date, 'MMMM Do YYYY')}</td>
                  <td>{payment.type}</td>
                  <td>{payment.payment_code}</td>
                  <td>
                    {moneyFormat(payment.rent)}{' '}
                    {payment.sms_event_id ? <FaSms size={20} color="grey" style={{ marginLeft: '10px' }} /> : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <SimplePagination
            goToNext={(nextPage) => this.updatePaginationParams({ page: nextPage })}
            goToPrev={(prevPage) => this.updatePaginationParams({ page: prevPage })}
            changePageSize={(page) => this.updatePaginationParams({ size: page })}
            pagination={this.state.pagination}
          />
        </div>
      </DashboardPage>
    )
  }
}
