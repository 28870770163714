import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { CompanyResponse } from '../pages/accountPage/helpers';

interface Props {
  companies?: CompanyResponse[];
}

export default function CompaniesTable({ companies }: Props): ReactElement {
  return (
    <div style={{ overflow: 'scroll' }}>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Location</th>
          </tr>
        </thead>
        <tbody>
          {companies?.map((company, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>
                <Link to={`/companies/${company.id}`}>{company.name}</Link>
              </td>
              <td>
                <Link to={`/companies/${company.id}`}>{company.location}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
