import React from 'react';
import './button.css';

export enum ButtonTypeEnum {
  'PRIMARY' = 'PRIMARY',
  'SECONDARY' = 'SECONDARY',
  'SUCCESS' = 'SUCCESS',
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  INFO = 'INFO',
  LIGHT = 'LIGHT',
  DARK = 'DARK',
  LINK = 'LINK',
}
export interface ButtonProps {
  buttonType?: string;
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'sm' | 'md' | 'lg';
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
  primary = false,
  size = 'medium',
  backgroundColor,
  label,
  buttonType,
  ...props
}) => {
  const selectedType = buttonType
    ? ButtonTypeEnum[buttonType]
    : ButtonTypeEnum['PRIMARY'];
  return (
    <button
      type="button"
      className={[
        `btn-${size}`,
        'btn',
        `btn-${selectedType.toLowerCase()}`,
      ].join(' ')}
      style={{ backgroundColor }}
      {...props}
    >
      {label}
    </button>
  );
};
