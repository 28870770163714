import React, { Component } from 'react';

interface Props {
  submitForm: (event: any) => void;
}
interface State {
  password?: string;
  repeatPassword?: string;
}

export default class ResetPasswordForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const form = event.target.name;
    const state = {};
    state[form] = event.target.value;

    this.setState(state);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.submitForm(this.state);
  }

  render() {
    return (
      <div className="login-body">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              value={this.state.password}
              name="password"
              onChange={this.handleChange}
              type="password"
              className="form-control"
              aria-describedby="password"
              placeholder="password"
            />
          </div>
          <div className="form-group">
            <label htmlFor="repeatPassword">Repeat password</label>
            <input
              value={this.state.repeatPassword}
              name="repeatPassword"
              onChange={this.handleChange}
              type="password"
              className="form-control"
              placeholder="repeat password"
            />
          </div>

          {this.props.children}
          <button
            type="submit"
            className="btn btn-primary umami--click--login-button"
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}
