import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PageSection from '../../../lib/dashboard-page/PageSection';
import KeyValueContainer, {
  KeyValueItem,
} from '../../../lib/KeyValuePair/KeyValueContainer';
import DashboardPage2 from '../../../lib/page2/DashboardPage2';
import { assetsApi } from '../../../utils/assets-api';
import MessagesContext from '../../../utils/MessagesContext';
import {
  AssetResponse,
  assetDetailNavLinks,
  filterCorrectTabLinks,
  NavLinkInterface,
} from '../accountPage/helpers';
import { PaPageRoute } from './PaPageRoute';
import { TabsContainer } from '../../../lib/tabs/tabs.stories';
import { setGALocation } from '../overview/utils';
import { StyledBreadcrumb, StyledPageNavBetween } from '../../../lib/styled';
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem';

interface Props {
  history: any[];
  location;
  routes;
}

interface State {
  asset?: AssetResponse;
  asset_id: number;
  startDate: Date;
  assetDetailNavLinks: NavLinkInterface[];
}

export default class AssetDetail extends Component<Props, State> {
  static contextType = MessagesContext;
  customPageRoute: PaPageRoute;

  constructor(props) {
    super(props);
    this.state = {
      asset_id: JSON.parse(props.match.params.id),
      startDate: new Date(),
      assetDetailNavLinks: assetDetailNavLinks.map((navLink) => ({
        to: `/asset/${JSON.parse(props.match.params.id)}/${navLink.to}`,
        title: navLink.title,
      })),
    };
    this.customPageRoute = new PaPageRoute(this.props);
  }

  componentDidMount() {
    setGALocation();
    assetsApi.getAssetWithSummary(this.state.asset_id).then((assetResponse) => {
      this.setState({ asset: assetResponse });
    });
  }

  handleChange = (event) => {
    const selectedAssetId = event.target.value;
    this.setState({ asset_id: selectedAssetId });
  };

  render() {
    return (
      <DashboardPage2 hideTitle>
        <StyledPageNavBetween>
          <StyledBreadcrumb>
            <BreadcrumbItem url={'/assets'} title={'assets'} />
            <BreadcrumbItem
              url={`/assets/${this.state.asset_id}`}
              active
              title={this.state.asset?.name || ''}
            />
          </StyledBreadcrumb>
          <Link
            to={`/houses/create?asset=${this.state.asset_id}`}
            data-cy="create-house"
          >
            create house
          </Link>
        </StyledPageNavBetween>

        <PageSection
          sectionName="details"
          sectionNameDataCy="houseDetails"
          rightHeader={
            <Link
              to={`/asset/${this.state.asset_id}/edit`}
              className="btn btn-primary"
            >
              Edit
            </Link>
          }
          style={{ margin: 0 }}
        >
          <div className="d-md-flex">
            <KeyValueContainer>
              <KeyValueItem
                keyName="Name"
                valueName={this.state.asset?.name}
                dataCy="assetName"
              />
              <KeyValueItem
                keyName="Location"
                valueName={this.state.asset?.location}
              />
              <KeyValueItem
                keyName="All houses"
                valueName={
                  this.state.asset
                    ? `${this.state.asset?.occupied_houses}/${this.state.asset?.all_houses}`
                    : ''
                }
              />
            </KeyValueContainer>

            <KeyValueContainer title={''}>
              <KeyValueItem keyName="" valueName={''} />
            </KeyValueContainer>
          </div>
        </PageSection>
        <TabsContainer
          navLinks={this.state.assetDetailNavLinks}
          routes={filterCorrectTabLinks(this.props.routes)}
          defaultRedirect={`/asset/${this.state.asset_id}/houses`}
          style={{ margin: '8px 0 16px' }}
        />
      </DashboardPage2>
    );
  }
}
