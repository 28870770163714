import React, { ReactElement } from 'react';

export interface DashboardPage2Props
  extends React.ButtonHTMLAttributes<HTMLElement> {
  children: any;
  pageName?: string;
  pageButtons?: any;
  hideTitle?: boolean;
}

export default function DashboardPage2({
  children,
  pageName,
  pageButtons,
  hideTitle,
}: DashboardPage2Props): ReactElement {
  return (
    <div style={{ padding: '20px'}}>
      {!hideTitle && (
        <div style={cardHeader} className="d-md-flex justify-content-between">
          <div className="align-self-center font-weight-bold">{pageName}</div>
          <div>{pageButtons}</div>
        </div>
      )}

        {children}
    </div>
  );
}

export const cardHeader = {
  padding: '0.5em',
  fontSize: '18px',
  color: 'black',
  minHeight: '51px',
};

interface PageNavProps {
  children: any;
}

export const PageNav = (props: PageNavProps) => {
  return (
    <nav
      className="d-flex justify-content-between"
      style={{ flex: 1 }}
      {...props}
    >
      {props.children}
    </nav>
  );
};
