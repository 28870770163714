import React, { ReactElement } from 'react';
import InvoiceTable from "../invoices/InvoiceTable";

interface Props {
  invoices?: any[];
}

export default function NewInvoicesTable({ invoices }: Props): ReactElement {
  return (
    <div>
      <h5>Invoices </h5>
      <InvoiceTable invoices={invoices} />
    </div>
  )

}
