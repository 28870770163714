import React, { Component } from 'react';
import DashboardPage from '../../../../lib/dashboard-page/DashboardPage';
import { supportApi } from '../../../../utils/support-api';
import { PackageResponse, CompanyResponse } from '../../accountPage/helpers';
import ConfirmationModal from '../../../shared/ConfirmationModal';
import CompaniesTable from '../../../shared/CompaniesTable';
import MessagesContext from '../../../../utils/MessagesContext';

interface Props {
  history: any;
}

interface State {
  packageDetail?: PackageResponse;
  package_id: number;
  showConfirmationModal: boolean;
  companies?: CompanyResponse[];
}

export default class PackageDetailPage extends Component<Props, State> {
  static contextType = MessagesContext;
  constructor(props) {
    super(props);
    this.state = {
      package_id: JSON.parse(props.match.params.id),
      showConfirmationModal: false,
    };

    this.createTokenModal = this.createTokenModal.bind(this);
    this.onCloseConfirmationModal = this.onCloseConfirmationModal.bind(this);
  }
  componentDidMount() {
    supportApi
      .getPackageDetail(this.state.package_id)
      .then((packageDetail: PackageResponse) => {
        this.setState({ packageDetail: packageDetail });
        supportApi
          .getPackageCompanies(this.state.package_id)
          .then((companies: CompanyResponse[]) => {
            this.setState({ companies: companies });
          });
      });
  }

  createTokenModal(packageId: number) {
    this.setState({ showConfirmationModal: true });
  }

  onCloseConfirmationModal(confirmation) {
    this.setState({ showConfirmationModal: false });

    if (confirmation) {
      this.createToken();
    }
  }

  createToken() {
    supportApi.createToken(this.state.package_id).then((tokenResponse) => {
      this.context.setmessage(
        'success',
        `token created successfully successfully`
      );
    });
  }

  render() {
    const createTokenConfirmationModal = this.state.showConfirmationModal ? (
      <ConfirmationModal
        onCloseConfirmationModal={this.onCloseConfirmationModal}
      />
    ) : undefined;
    return (
      <DashboardPage
        pageName={this.state.packageDetail?.name}
        pageButtons={
          <button
            onClick={() => this.createTokenModal(1)}
            type="button"
            className="btn"
          >
            Create Token
          </button>
        }
      >
        {createTokenConfirmationModal}
        <div className="d-md-flex">
          <div className="col">
            <h4>Agency Details</h4>
            <p>
              <span className="text-label">Name: </span>
              <span id="asset-name">{this.state.packageDetail?.name}</span>
            </p>
            <p>
              <span className="text-label">houses: </span>
              {this.state.packageDetail?.max_houses}
            </p>
            <p>
              <span className="text-label">sms quota: </span>
              {this.state.packageDetail?.sms_quota}
            </p>
            <p>
              <span className="text-label">accounts: </span>
              {this.state.packageDetail?.max_accounts}
            </p>
            <p>
              <span className="text-label">status: </span>
              {this.state.packageDetail?.status}
            </p>
            <p>
              <span className="text-label">price: </span>
              {this.state.packageDetail?.price}
            </p>
          </div>
        </div>

        <CompaniesTable companies={this.state.companies} />
      </DashboardPage>
    );
  }
}
