import React from 'react';
import { Route, withRouter } from 'react-router-dom';

const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      render={(props: any) => (
        <route.component {...props} routes={route.routes} key={props.key} />
      )}
    />
  );
};

export default withRouter(RouteWithSubRoutes);
