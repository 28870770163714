import React, { Component } from 'react'
import dateFormat from '../../shared/helpers/dateFormat'
import DeleteTenantModel from './DeleteTenantModel'
import { assetsApi } from '../../../utils/assets-api'
import MessagesContext from '../../../utils/MessagesContext'
import moneyFormat from '../../shared/helpers/moneyFormat'
import { PaymentResponse, HouseResponse, updatePageParams, PageParams, getAllRouteParams } from '../accountPage/helpers'
import { PaginationResponse } from '../assetPage/HousesTable'
import qs from 'qs'
import { FaSms } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import KeyValueContainer, { KeyValueItem } from '../../../lib/KeyValuePair/KeyValueContainer'
import { setGALocation } from '../overview/utils'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'
import PageSection from '../../../lib/dashboard-page/PageSection'
import { StyledBreadcrumb, StyledPageNavBetween } from '../../../lib/styled'
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'
import InvoiceTable from '../invoices/InvoiceTable'
import DatatablePagination from '../invoices/DatatablePagination'

interface Props {
  getHouses: (query: string) => void
  createMessage: () => void
  getAssetDetails: () => void

  match: any
  history: any
  fetching: boolean
  location
}

interface State {
  house?: HouseResponse
  house_id: number
  payments?: PaymentResponse[]
  assetId: number
  showDeleteTenantModal: boolean
  pagination?: PaginationResponse
  invoicesPage?: any
}

class HouseDetailPage extends Component<Props, State> {
  static contextType = MessagesContext
  constructor(props) {
    super(props)
    this.state = {
      house_id: JSON.parse(props.match.params.houseId),
      assetId: JSON.parse(props.match.params.assetId),
      showDeleteTenantModal: false,
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)
    this.openDeleteTenantModal = this.openDeleteTenantModal.bind(this)
    this.getPayments = this.getPayments.bind(this)
    this.goToEditPage = this.goToEditPage.bind(this)
  }

  openDeleteTenantModal() {
    this.setState({ showDeleteTenantModal: true })
  }

  onCloseModal(tenantId) {
    this.setState({ showDeleteTenantModal: false })
    if (tenantId) {
      assetsApi.deleteHouse(this.state.assetId, this.state.house_id, tenantId).then(() => {
        this.context.setmessage('success', 'tenant deleted successfully')
        const queries: PageParams = getAllRouteParams(this.props.location)

        this.getHouseDetails(this.state.house_id, queries.page, queries.size)
      })
    }
  }

  componentDidMount() {
    setGALocation()
    // when history params are updated componentDidUpdate will be called next
    const newQueryString = qs.stringify(updatePageParams(this.props.location, { page: 1, size: 10 }))
    this.props.history.replace({ search: newQueryString })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const queries: PageParams = getAllRouteParams(this.props.location)
      if (queries.page && queries.size) {
        this.getHouseDetails(this.state.house_id, queries.page, queries.size)
      }
    }
  }

  getHouseDetails(house_id, page, size) {
    assetsApi.findAssetHouse(this.state.assetId, house_id).then((houseResponse: HouseResponse) => {
      this.setState({ house: houseResponse })
      if (houseResponse.currentContract) {
        this.fetchAllInvoices(houseResponse.currentContract.id)
      }
    })
  }

  fetchAllInvoices = (currentContractId) => {
    assetsApi.fetchInvoicesByContract(currentContractId).then((invoicesPageResponse) => {
      this.setState({ invoicesPage: invoicesPageResponse })
    })
  }

  getPayments(assetId: number, houseId: number, contractId: number, page, size) {
    assetsApi.getPayments(assetId, houseId, contractId, page, size).then(({ items, pagination }) => {
      this.setState({ payments: items, pagination: pagination })
    })
  }

  toggleModal(modalState) {
    this.setState(modalState)
  }

  goToEditPage() {
    this.props.history.push(`/houses/${this.state.assetId}/${this.state.house_id}/edit`)
  }

  // todo remove this with  switching inside the template rather than in new function
  switchModels(occupancy) {
    if (occupancy === 'empty') {
      return (
        <Link
          data-cy="edit-house"
          className="btn btn-link"
          to={`/houses/${this.state.assetId}/${this.state.house_id}/edit`}
        >
          Edit house
        </Link>
      )
    }
    if (occupancy === 'occupied') {
      return (
        <>
          <button className="btn btn-link" onClick={() => this.openDeleteTenantModal()}>
            Remove tenant
          </button>
          <Link className="btn btn-link" to={`/houses/${this.state.assetId}/${this.state.house_id}/edit`}>
            Edit house
          </Link>
        </>
      )
    }
    return null
  }

  updatePaginationParams(queryParams: any) {
    const newQueryString = qs.stringify(updatePageParams(this.props.location, queryParams))
    this.props.history.replace({ search: newQueryString })
  }

  render() {
    const deleteTenant =
      this.state.showDeleteTenantModal && this.state.house?.currentContract ? (
        <DeleteTenantModel onCloseModal={this.onCloseModal} contract={this.state.house.currentContract} />
      ) : undefined

    return (
      <DashboardPage2 hideTitle>
        {deleteTenant}

        <StyledPageNavBetween>
          <StyledBreadcrumb>
            <BreadcrumbItem url={'/assets'} title={'assets'} />
            <BreadcrumbItem url={`/asset/${this.state.assetId}`} title={this.state.house?.asset?.name || ''} />
            <BreadcrumbItem
              url={`/houses/${this.state.assetId}/${this.state.house?.id}`}
              title={this.state.house?.house_number || ''}
            />
            <BreadcrumbItem active title={this.state.house?.currentContract?.tenant.full_names || ''} />
          </StyledBreadcrumb>
          <div>{this.switchModels(this.state.house?.occupancy)}</div>
        </StyledPageNavBetween>

        <PageSection>
          <div className="d-md-flex" style={{ padding: '10px 5px 0 0' }}>
            <KeyValueContainer title={'House details'}>
              <KeyValueItem keyName="Asset" valueName={this.state.house?.asset.name} />
              <KeyValueItem keyName="House name" valueName={this.state.house?.house_number} />
              <KeyValueItem keyName="Location" valueName={this.state.house?.asset.location} />
              <KeyValueItem keyName="Status" valueName={this.state.house?.occupancy} />
            </KeyValueContainer>

            <KeyValueContainer title={'Current contract'}>
              <KeyValueItem keyName="Tenant" valueName={this.state.house?.currentContract?.tenant?.full_names} />
              <KeyValueItem keyName="Phone" valueName={this.state.house?.currentContract?.tenant.phone_number} />
              <KeyValueItem
                keyName="Rent"
                valueName={
                  <>
                    <strong>{this.state.house?.currentContract?.rent}</strong>{' '}
                    {this.state.house?.currentContract?.period}
                  </>
                }
              />
              <KeyValueItem
                keyName="Start Date"
                valueName={dateFormat(this.state.house?.currentContract?.start_date, 'MM DD YYYY')}
              />
            </KeyValueContainer>
            {/*</PageSection>*/}
          </div>

          <hr />

          <div style={{ marginBottom: '20px' }} className="d-flex justify-content-between">
            <div className="align-self-center">
              <h5 className="font-weight-bold">Invoices</h5>
            </div>
            {this.state.house?.currentContract ? (
              <div>
                <Link
                  className="nav-link"
                  to={`/invoices/create?asset=${this.state.assetId}&contract=${this.state.house.currentContract.id}`}
                  data-cy="create-payment-button"
                >
                  create invoice
                </Link>
              </div>
            ) : null}
          </div>

          <InvoiceTable invoices={this.state.invoicesPage?.items} />
          {this.state.invoicesPage ? <DatatablePagination pagination={this.state.invoicesPage.pagination} /> : null}
        </PageSection>
      </DashboardPage2>
    )
  }
}

export default HouseDetailPage
