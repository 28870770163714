import { AxiosInstance } from 'axios';
import { AuthApi } from './api';
import { baseAxios } from './api.config';


export class AgentsJsonapiService extends AuthApi {
  public constructor(baseAxios?: AxiosInstance) {
    super(baseAxios);
  }

  public getDashboardFeatureFlags(featureFlags: string[]): Promise<any> {
      // ?filter[name]=expenses
    const filter = [{
        "name":"name",
        "op":"in",
        "val":featureFlags
      }]

    return this.get<any>(`/agent/feature-flags`, {params: {filter: JSON.stringify(filter)}, headers: {"Accept": "application/vnd.api+json"}})
      .then(this.success)
      .then((response) => response.data.map(raw => raw.attributes));
  }
}

export const agentsJsonapiService = new AgentsJsonapiService(baseAxios);
