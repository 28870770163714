import Dashboard from './dashboard/Dashboard';
import Login from './authentication/Login';
import RegisterWizard from './authentication/RegisterWizard';
import Tnc from './authentication/Tnc';
import ResetPassword from './authentication/ResetPassword';
import ForgotPassword from './authentication/ForgotPassword';
import CompleteRegistration from './authentication/CompleteRegistration';

const routes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/register',
    component: RegisterWizard,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/complete-registration',
    component: CompleteRegistration,
  },
  {
    path: '/tnc',
    component: Tnc,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/',
    component: Dashboard,
  },
];

export default routes;
