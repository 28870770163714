import React, { ReactElement } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PageSection from '../../../lib/dashboard-page/PageSection'
import { assetsApi } from '../../../utils/assets-api'
import { ExpensesDatatable } from '../expensesMod/ExpensesDatatable'
import * as H from 'history'
import { convertSearchToRouteParams } from '../accountPage/helpers'

interface Param {
  id: string
}

export interface Pagination {
  currentPage: string
  hasNext: boolean
  hasPrev: boolean
  next: number
  nextPage: number
  page: number
  pages: number
  prev: number
  prevPage: number
  size: number
  totalElements: number
}

export interface Expense {
  asset_id: number
  created_at: string
  expected_unit_amount: string
  id: number
  is_recurring: boolean
  is_required: boolean
  name: string
  status: string
  updated_at: string
}

export interface ExpensesResponse {
  pagination: Pagination
  items: any
}

export default function AssetExpensesTabsPage(props: unknown): ReactElement {
  const { id }: Param = useParams()
  const [expenses, setExpenses] = useState<ExpensesResponse>()
  const history: H.History = useHistory()

  useEffect(() => {
    const assetList = [Number(id)]
    getExpenses(assetList, convertSearchToRouteParams(history.location.search))
  }, [id, history.location.search])

  const getExpenses = (assetIds, paginationParams = {}) => {
    assetsApi.getExpenses(assetIds, paginationParams).then((response) => {
      setExpenses(response)
    })
  }

  return <PageSection>{expenses ? <ExpensesDatatable expensesPage={expenses} /> : null}</PageSection>
}
