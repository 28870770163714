import React, { ReactElement, useState } from 'react'
import DatePicker from 'react-datepicker'
import { PaginationUtil } from '../shared/utils/PaginationUtil'
import * as H from 'history'
import { useHistory } from 'react-router-dom'
import { dateToString } from '../shared/utils/dateUtils'
import { endOfMonth } from '../../utils/dates'

interface Props {
  onChange?: (dateRange: any) => void
  startDate?: any
  endDate?: any
}

export default function ParamDateRange(props: Props): ReactElement {
  const history: H.History = useHistory()
  const paginationUtil = new PaginationUtil(history)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const onChange = (dateRange) => {
    const [startDate, endDate] = [dateRange[0], dateRange[1] ? endOfMonth(dateRange[1]) : dateRange[1]]
    setDateRange([startDate, endDate])
    setDateRange([dateRange[0], dateRange[1] ? endOfMonth(dateRange[1]) : dateRange[1]])
    const dateRangeParams = { startDate: null, endDate: null }
    if (startDate) {
      dateRangeParams['startDate'] = dateToString(startDate)
    }
    if (endDate) {
      dateRangeParams['endDate'] = dateToString(endDate)
    }
    console.log('dateRangeParams.handleDateRange', dateRangeParams)

    paginationUtil.updatePageParams(dateRangeParams)
    // props.onChange(dates)
  }

  // const onChange = (dates) => {
  //   console.log('onChange', dates)
  //   setDateRange(dates);
  //   props.onChange(dates)
  // };

  return (
    <nav aria-label="Page" className="d-flex">
      <DatePicker
        className="form-control"
        selectsRange={true}
        showMonthYearPicker
        dateFormat="MM/yyyy"
        startDate={props.startDate}
        endDate={props.endDate}
        onChange={(update) => {
          onChange(update)
        }}
        // isClearable={true}
      />
    </nav>
  )
}
