import React, { ReactElement } from 'react'
import TrWithLink from '../../../lib/Table/TrWithLink'
import { InvoiceItemTypeEnum } from '../../../lib/enum/InvoiceItemTypeEnum'

export default function InvoiceTable({ invoices }): ReactElement {
  return (
    <div className="table-responsive bg-white">
      <table className="table thead-dark table-sm">
        <thead className="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Created</th>
          <th scope="col">Status</th>
          <th scope="col">Tenant</th>
          <th scope="col">Amount</th>
          <th scope="col">Expenses</th>
          <th scope="col">Rent</th>
          <th scope="col">Payments</th>
          <th scope="col">House</th>
        </tr>
        </thead>
        <tbody>
        {invoices?.map((invoice, i) => (
          <TrWithLink key={i} link={`/invoices/${invoice.id}`}>
            <th>{i + 1}</th>
            <td>{new Date(invoice.invoice_date).toDateString()}</td>
            <td>{invoice.status}</td>
            <td>{invoice.contract.tenant.full_names}</td>
            <td>{invoice.contract.amount}</td>
            <td>
              {invoice.invoiceItems
                .filter((item) => item.type === InvoiceItemTypeEnum.EXPENSE)
                .reduce((total, item) => total + JSON.parse(item.amount), 0)}
            </td>
            <td>
              {invoice.invoiceItems
                .filter((item) => item.type === InvoiceItemTypeEnum.RENT)
                .reduce((total, item) => total + JSON.parse(item.amount), 0)}
            </td>
            <td>{invoice.payments.reduce((total, item) => total + JSON.parse(item.amount), 0)}</td>
            <td>{invoice.contract.house.house_number}</td>
          </TrWithLink>
        ))}
        </tbody>
      </table>


    </div>
  )
}
