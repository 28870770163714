import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export interface BreadcrumbItemProps {
  active?: boolean
  title: string
  url?: string
  iconComponent?: any
}
export function BreadcrumbItem({ active, title, url, iconComponent }: BreadcrumbItemProps) {
  return (
    <li style={{ padding: '0', margin: '0' }}>
      {!active && !!url ? (
        <div style={{ display: 'flex' }}>
          <div style={{ padding: '8px 0' }}>
            <Link to={url}>{title}</Link>

            {iconComponent}
          </div>
          <div style={{ padding: '8px' }}>
            <FaChevronRight />
          </div>
        </div>
      ) : (
        <div style={{ padding: '8px 0' }} className="font-weight-bold">
          {' '}
          {title}
          {iconComponent}
        </div>
      )}
    </li>
  )
}
