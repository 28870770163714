import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as H from 'history'
import { PaginationUtil } from '../../shared/utils/PaginationUtil'
import { convertSearchToRouteParams } from '../accountPage/helpers'
import { assetsApi } from '../../../utils/assets-api'
import { dateToString } from '../../shared/utils/dateUtils'
import DashboardPage2 from '../../../lib/page2/DashboardPage2'
import { StyledBreadcrumb, StyledPageFilterNav, StyledPageNav, StyledPageNavRight } from '../../../lib/styled'
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem'
import PageSection from '../../../lib/dashboard-page/PageSection'
import { SECTION_PADDING } from '../../../lib/Table/cssVariables'
import TrWithLink from '../../../lib/Table/TrWithLink'
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import ParamDateRange from '../../components/ParamDateRange'
import {MissingInvoicesTable} from "./MissingInvoices/MissingInvoicesTable";
import {MissingInvoicesList} from "./MissingInvoices/MissingInvoicesList";

interface Props {
  routes: any[]
}

export default function MissingInvoicesPage({ routes }: Props): ReactElement {
  const history: H.History = useHistory()
  const paginationUtil = new PaginationUtil(history)
  const [invoicesPage, setInvoicesPage] = useState<any>()
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDateDate] = useState<any>()

  useEffect(() => {
    initiateParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchAllMissingInvoices(convertSearchToRouteParams(history.location.search))
    updateStartEndDates(convertSearchToRouteParams(history.location.search))
  }, [history.location.search])

  const updateStartEndDates = (paginationParams) => {
    // const paginationParams: any = convertSearchToRouteParams(history.location.search)
    setStartDate(paginationParams.startDate ? new Date(paginationParams.startDate) : null)
    setEndDateDate(paginationParams.endDate ? new Date(paginationParams.endDate) : null)
    // if (!paginationParams.startDate && !paginationParams.endDate) {
    //   paginationUtil.updatePageParams({startDate: new Date(), endDate: new Date()})
    //
    // }
  }

  const initiateParams = () => {
    const paginationParams: any = convertSearchToRouteParams(history.location.search)

    if (!paginationParams.startDate && !paginationParams.endDate) {
      paginationUtil.updatePageParams({
        startDate: new Date(),
        endDate: new Date(),
      })
    }
  }

  const fetchAllMissingInvoices = (paginationParams) => {
    // setStartDate(new Date(paginationParams.startDate))
    // setEndDateDate(new Date(paginationParams.endDate))

    if (!!paginationParams.startDate && paginationParams.endDate) {
      // setStartDate(new Date(paginationParams.startDate))
      // setEndDateDate(new Date(paginationParams.endDate))
      assetsApi
        .fetchAllMissingInvoices(paginationParams.startDate, paginationParams.endDate)
        .then((invoicesPageResponse) => {
          setInvoicesPage(invoicesPageResponse)
        })
    }
    // else if (paginationParams.startDate && !paginationParams.endDate) {
    //   setStartDate(new Date(paginationParams.startDate))
    //   // setEndDateDate(null)
    // } else if (paginationParams.endDate && !paginationParams.startDate) {
    //   setEndDateDate(new Date(paginationParams.endDate))
    //   // setStartDate(null)
    // } else if (!paginationParams.endDate && !paginationParams.startDate) {

    // setStartDate(null)
    //     setEndDateDate(null)
    //     }
  }

  // const updatePaginationParams = (paginationParams = {}) => {
  //   paginationUtil.updatePaginationParams(paginationParams);
  // };
  //
  // const handlePageSizeChange = (event) => {
  //   paginationUtil.updatePaginationParams({
  //     size: parseInt(event.target.value),
  //   });
  // };

  const handleDateRange = (dateRange: [any, any]) => {
    console.log('handleDateRange', dateRange)
    const dateRangeParams = { startDate: null, endDate: null }
    if (dateRange.length) {
      const [startDate, endDate] = dateRange
      if (startDate) {
        dateRangeParams['startDate'] = dateToString(startDate)
      }
      if (endDate) {
        dateRangeParams['endDate'] = dateToString(endDate)
      }
      console.log('dateRangeParams.handleDateRange', dateRangeParams)

      paginationUtil.updatePageParams(dateRangeParams)
    }
  }

  return (
    <DashboardPage2 hideTitle={true}>
      <StyledPageNav>
        <StyledBreadcrumb>
          <BreadcrumbItem url={'/invoices'} title={'invoices'} />
          <BreadcrumbItem active title={'missing invoices'} />
        </StyledBreadcrumb>
        <StyledPageNavRight>
          <Menu menuButton={<MenuButton className="btn btn-primary">Open menu</MenuButton>}>
            <MenuItem data-cy="create-invoice" href={'#/invoices/create'}>
              Create Invoice
            </MenuItem>
            {/*<MenuItem  href={'#/invoices/missing-invoices'}>Missing Invoices</MenuItem>*/}
          </Menu>
        </StyledPageNavRight>
      </StyledPageNav>

      <StyledPageFilterNav>
        <ParamDateRange startDate={startDate} endDate={endDate} onChange={handleDateRange} />
      </StyledPageFilterNav>

      <PageSection>
        <div>
          <div
            style={{
              padding: SECTION_PADDING,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className="d-flex justify-content-between"
          ></div>
          <MissingInvoicesList invoicesPage={invoicesPage} />
          <MissingInvoicesTable invoicesPage={invoicesPage} />

        </div>
      </PageSection>
    </DashboardPage2>
  )
}

