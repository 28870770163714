import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { ContractResponse, PaymentFormRequest } from '../accountPage/helpers';
import MessagesContext from '../../../utils/MessagesContext';

const paymentTypes = ['MPESA', 'Bank', 'other'];

interface PaymentFormProps {
  createPayment: (paymentRequest: PaymentFormRequest) => void;
  contract?: ContractResponse;
  initialPaymentValue?: any;
}

interface State {
  form?: any;
  paymentTypes: any[];
}

const PaymentForm = (props: PaymentFormProps) => {
  const { setmessage } = useContext(MessagesContext);
  const [paymentDate, setPaymentDate] = useState<Date>();
  const [formState, setFormState] = useState<any>({
    type: '',
    paymentTypes: paymentTypes,
  });

  useEffect(() => {
    if (props.initialPaymentValue) {
      const payDate = new Date(props.initialPaymentValue.paymentDate);
      setPaymentDate(payDate);
      const tempFormState = Object.assign({}, formState);

      tempFormState['rent'] = props.initialPaymentValue.remainingAmount;
      tempFormState['paymentDate'] = payDate;
      setFormState(tempFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialPaymentValue]);

  const handleDateChange = (dateFormat, date) => {
    const tempFormState = Object.assign({}, formState);

    tempFormState[dateFormat] = date;
    setFormState(tempFormState);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const paymentDate = formState.paymentDate;
    const datePayed = formState.datePayed;
    console.log(paymentDate, datePayed);
    if (paymentDate && datePayed) {
      const formattedPaymentDate =
        paymentDate.getFullYear() +
        '-' +
        (paymentDate.getMonth() + 1) +
        '-' +
        paymentDate.getDate();
      const formattedDatePayed =
        datePayed.getFullYear() +
        '-' +
        (datePayed.getMonth() + 1) +
        '-' +
        datePayed.getDate();

      const paymentRequest: PaymentFormRequest = {
        rent: formState.rent,
        type: formState.type,
        paymentCode: formState.paymentCode,
        paymentDate: formattedPaymentDate,
        datePayed: formattedDatePayed,
        smsNotification: Boolean(parseInt(formState.smsNotification)),
      };
      props.createPayment(paymentRequest);
    } else {
      setmessage('error', 'please fill all the fields');
    }
  };

  const handleChange = (event) => {
    const form = event.target.name;
    const value = event.target.value;
    const tempFormState = Object.assign({}, formState);

    tempFormState[form] = value;
    setFormState(tempFormState);
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: '0' }}>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="type">Payment details</label>
          <select
            id="type"
            className="form-control"
            value={formState.type}
            name="type"
            onChange={handleChange}
          >
            <option></option>
            {formState.paymentTypes.map((type, i) => (
              <option key={i} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="paymentCode">Reference number</label>
          <input
            type="text"
            className="form-control"
            value={formState.paymentCode}
            name="paymentCode"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <div>Payment date</div>
          <DatePicker
            id="startDate"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            className="form-control"
            selected={paymentDate}
            onChange={(date) => handleDateChange('paymentDate', date)}
            minDate={paymentDate}
          />
        </div>

        <div className="form-group col-md-6">
          <div>Date payed</div>
          <DatePicker
            id="datePayed"
            className="form-control"
            selected={formState.datePayed}
            onChange={(date) => handleDateChange('datePayed', date)}
            popperClassName="some-custom-class"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="rentAmount">Payment amount</label>
          <input
            className="form-control"
            id="rentAmount"
            value={formState.rent}
            name="rent"
            onChange={handleChange}
            type="number"
            placeholder="KES"
          />
        </div>
      </div>

      {props.contract?.tenant.phone_number ? (
        <div className="form-row">
          <p>
            sms will be send to:{' '}
            <strong>{props.contract?.tenant.phone_number}</strong>
          </p>
          <div className="form-group col-md-6">
            <label htmlFor="smsNotification">Sms notification</label>
            <select
              id="smsNotification"
              className="form-control"
              value={formState.smsNotification}
              name="smsNotification"
              onChange={handleChange}
            >
              <option></option>
              <option value={0}>No</option>
              <option value={1}>Yes</option>
            </select>
          </div>
        </div>
      ) : null}

      <div className="tenantFormButton">
        <button
          type="submit"
          className="btn btn-primary umami--click--create-payment-button"
        >
          Make Payment
        </button>
      </div>
    </form>
  );
};

export default PaymentForm;
