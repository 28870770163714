import React, { ReactElement } from 'react'

export interface PageSectionProps {
  children: any
  sectionName?: any
  rightHeader?: any
  sectionNameDataCy?: string
  style?: any
}

export default function PageSection(props: PageSectionProps): ReactElement {
  return (
    <div
      className="col"
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 5px 0px, rgba(0, 0, 0, 0.15) 0px 1px 5px 0px',
        background: 'white',
        marginBottom: '20px',
        borderTop: '20px solid rgb(110, 124, 149)',
        ...props.style,
      }}
    >
      {props.sectionName || props.rightHeader ? (
        <div className="d-flex justify-content-between" style={{ padding: '1em 1rem 0 1rem' }}>
          <div className="h5 font-weight-bold" data-cy={props.sectionNameDataCy}>
            {props.sectionName}
          </div>
          <div>{props.rightHeader}</div>
        </div>
      ) : (
        <div style={{ padding: '1em 1rem 0 1rem' }} />
      )}

      {props.children}
    </div>
  )
}
