import React, { ReactElement, useEffect, useState } from 'react';

import {
  StyledBreadcrumb,
  StyledPageNav,
  StyledPageNavRight,
} from '../../../lib/styled';
import { BreadcrumbItem } from '../../../lib/breadcrumb/BreadcrumbItem';
import DashboardPage2 from '../../../lib/page2/DashboardPage2';
import PageSection from '../../../lib/dashboard-page/PageSection';
import { assetsApi } from '../../../utils/assets-api';
import { ExpensesResponse } from '../assetPage/AssetExpensesTabsPage';
import { convertSearchToRouteParams } from '../accountPage/helpers';
import * as H from 'history';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  routes: any[];
}

export default function CreateExpensesPage({ routes }: Props): ReactElement {
  const [expensesPage, setExpensesPage] = useState<ExpensesResponse>();
  const [assetIds, setAssetIds] = useState<number[]>();
  const history: H.History = useHistory();

  useEffect(() => {
    assetsApi.getAssets().then((assets) => {
      const assetId = assets.map((asset) => asset.id);
      setAssetIds(assetId);
    });
  }, []);

  useEffect(() => {
    if (assetIds) {
      getExpenses(
        assetIds,
        convertSearchToRouteParams(history.location.search)
      );
    }
  }, [assetIds, history.location.search]);

  const getExpenses = (assetIds, paginationParams = {}) => {
    assetsApi.getExpenses(assetIds, paginationParams).then((response) => {
      setExpensesPage(response);
    });
  };

  return (
    <DashboardPage2 hideTitle={true}>
      <StyledPageNav>
        <StyledBreadcrumb>
          <BreadcrumbItem url={'/expenses'} title={'expenses'} />
          <BreadcrumbItem active title={'create'} />
        </StyledBreadcrumb>

        <StyledPageNavRight>
          <Link className="btn btn-primary btn-sm" to="/expenses/create">
          Create Expense
              </Link>
        </StyledPageNavRight>
      </StyledPageNav>

      <PageSection>
        page content

      </PageSection>
    </DashboardPage2>
  );
}
