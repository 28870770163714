import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'

interface Props {
  onCloseModal: (boolean) => void
  token: string
}

export default class PackageTokenModal extends Component<Props, never> {
  render() {
    return (
      <div>
        <Modal.Dialog>
          <div style={{ padding: '20px' }} className="d-flex justify-content-between">
            <div>Create Token</div>
            <div>
              <button className="btn btn-secondary" onClick={() => this.props.onCloseModal(false)}>
                {' '}
                Close
              </button>
            </div>
          </div>
          <Modal.Body>
            <p>{this.props.token}</p>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    )
  }
}
