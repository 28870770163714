import React from 'react'
import { Switch } from 'react-router-dom'
import routes from './routes'
import RouteWithSubRoutes from './RouteWithSubRoutes'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Loader from './shared/Loader'
import { useAxiosLoader } from './utils/api'
import { baseAxios } from './utils/api.config'
import AlertContainer from './dashboard/shared/alert/AlertContainer'
import { MessagesProvider } from './utils/MessagesContext'
import ReactGA from 'react-ga'
import { HashRouter } from 'react-router-dom'
import { environment } from '../environments/environment'

function App() {
  const loading = useAxiosLoader(baseAxios)

  // document.title = environment.sentryEnvironment

  ReactGA.initialize(environment.gAnalyticsTag, { testMode: isLocalhost() })
  return (
    <HashRouter>
      <MessagesProvider>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>

        {loading ? <Loader toggle={true} /> : ''}
        <AlertContainer />
      </MessagesProvider>
    </HashRouter>
  )
}

export default App

const isLocalhost = () => {
  return ['localhost', '0.0.0.0', '127.0.0.1'].includes(window.location.hostname)
}
