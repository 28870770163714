import React, { Component } from 'react';
import { CompanyResponse } from '../../accountPage/helpers';
import { supportApi } from '../../../../utils/support-api';
import CompaniesTable from '../../../shared/CompaniesTable';
import DashboardPage from '../../../../lib/dashboard-page/DashboardPage';

interface State {
  companies?: CompanyResponse[];
}

class CompanyListPage extends Component<unknown, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    supportApi.getAllCompanies().then((companies: CompanyResponse[]) => {
      this.setState({ companies: companies });
    });
  }

  render() {
    return (
      <DashboardPage pageName={'Companies'}>
        <form>
          <div className="form-group row">
            <div className="col-sm-11">
              <input
                type="text"
                className="form-control"
                id="companiesSearch"
              />
            </div>
            <button type="submit" className="col-sm-1 btn btn-primary">
              search
            </button>
          </div>
        </form>

        <CompaniesTable companies={this.state.companies} />
      </DashboardPage>
    );
  }
}

export default CompanyListPage;
