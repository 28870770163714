import * as qs from 'qs'
import { PageParams, PaginationParamsInterface } from '../accountPage/helpers'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from './AssetsPage'

// a class to hold param logics

/**
 * Only use on class based components
 *
 * @deprecated
 */
export class PaPageRoute {
  history: any
  location: any
  constructor(props) {
    this.history = props.history
    this.location = props.location
  }

  mergePageParams(queryParams: PageParams): PageParams {
    const queries: PageParams = this.getAllRouteParams()
    return { ...queries, ...queryParams }
  }

  updatePaginationParams({ page, size }: { page?: any; size?: any }) {
    const previousPageParams = this.getPageParams()
    if (page) {
      this.updatePageParams({ page: page, size: previousPageParams.size })
    } else if (size) {
      this.updatePageParams({ page: previousPageParams.page, size: size })
    }
  }

  updatePageParams(queryParams): void {
    const newQueryString = qs.stringify(this.mergePageParams(queryParams))
    this.history.push({ search: newQueryString })
  }

  replacePageParams(queryParams): void {
    this.history.replace({
      search: qs.stringify(this.mergePageParams(queryParams)),
    })
  }

  resetPaginationParams(): void {
    this.history.replace({
      search: qs.stringify(this.mergePageParams({ page: DEFAULT_PAGE, size: DEFAULT_PAGE_SIZE })),
    })
  }

  getAllRouteParams(location?: any) {
    return qs.parse(location ? location.search : this.location.search, {
      ignoreQueryPrefix: true,
    }) as unknown as PageParams
  }

  /**
   * @deprecated
   */
  getPageParams(location?: any): PaginationParamsInterface {
    const queries = qs.parse(location ? location.search : this.location.search, {
      ignoreQueryPrefix: true,
    }) as unknown as PageParams
    return {
      page: queries.page || DEFAULT_PAGE,
      size: queries.size || DEFAULT_PAGE_SIZE,
    }
  }

  getPageParams2(location: any): PaginationParamsInterface {
    const queries = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as unknown as PageParams
    return {
      page: queries.page || DEFAULT_PAGE,
      size: queries.size || DEFAULT_PAGE_SIZE,
    }
  }
}
