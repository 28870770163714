import React, { Component } from 'react';
import { supportApi } from '../../../../utils/support-api';
import { CompanyDetailResponse } from '../../accountPage/helpers';

interface State {
  companyDetail?: CompanyDetailResponse;
  asset_id: number;
}

export default class CompanyDetailPage extends Component<unknown, State> {
  constructor(props) {
    super(props);
    this.state = {
      asset_id: JSON.parse(props.match.params.id),
    };
  }

  componentDidMount() {
    supportApi
      .findCompanyDetail(this.state.asset_id)
      .then((companyDetail: CompanyDetailResponse) => {
        this.setState({ companyDetail: companyDetail });
      });
  }

  render() {
    return (
      <div style={{ padding: '20px' }}>
        <div className="Card-Title card-hed">Company Detail</div>
        <div
          style={{
            background: 'white',
            padding: '20px',
            boxShadow:
              '0 1px 5px 0px rgba(0,0,0,0.3), 0 1px 5px 0px rgba(0,0,0,0.15)',
          }}
        >
          <div className="d-md-flex">
            <div className="col">
              <h4>Agency Details</h4>
              <p>
                <span className="text-label">Name: </span>
                <span id="asset-name">
                  {this.state.companyDetail?.company.name}
                </span>
              </p>
              <p>
                <span className="text-label">location: </span>
                {this.state.companyDetail?.company.location}
              </p>
            </div>

            <div className="col">
              <h4>Utilization</h4>
              <p>
                <span className="text-label">Assets: </span>
                <span>{this.state.companyDetail?.summary?.assets_count}</span>
              </p>
              <p>
                <span className="text-label">All houses: </span>
                <span>{this.state.companyDetail?.summary?.houses_count}</span>
              </p>
              <p>
                <span className="text-label">Occupied houses: </span>
                <span>{this.state.companyDetail?.summary?.houses_count}</span>
              </p>
              <p>
                <span className="text-label">Accounts: </span>
                <span>{this.state.companyDetail?.summary?.accounts_count}</span>
              </p>
            </div>

            <div className="col">
              <h4>Package</h4>
              <p>
                <span className="text-label">Max houses: </span>
                <span>
                  {
                    this.state.companyDetail?.companyPackage?.package
                      ?.max_houses
                  }
                </span>
              </p>
              <p>
                <span className="text-label">Max Accounts: </span>
                <span>
                  {
                    this.state.companyDetail?.companyPackage?.package
                      ?.max_accounts
                  }
                </span>
              </p>
              <p>
                <span className="text-label">Price: </span>
                <span>
                  {this.state.companyDetail?.companyPackage?.package?.price}
                </span>
              </p>
              <p>
                <span className="text-label">Expiry Date: </span>
                <span>
                  {this.state.companyDetail?.companyPackage?.expiry_date}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
