export default (money) => {
  let formattedString;
  let moneyString = money.toString();
  if (typeof moneyString === 'string') {
    formattedString = moneyString;
  } else {
    formattedString = '';
  }
  // debugger;
  return (
    formattedString.replace(/[0-9](?=(?:[0-9]{3})+(?![0-9]))/g, '$&,') + ' kes'
  );
};
