import React, { ReactElement } from 'react';

export interface KeyValueContainerProps {
  children: any;
  title?: string;
  rightElement?: any;
}

export default function KeyValueContainer({
  children,
  title,
  rightElement,
}: KeyValueContainerProps): ReactElement {
  return (
    <div className="col">
      <div className="d-flex justify-content-between">
        <div className="h5">{title}</div>
        <div>{rightElement}</div>
      </div>

      <div>{children}</div>
    </div>
  );
}

interface KeyValueItemProps {
  keyName: string;
  dataCy?: string;
  valueName?: string | number | any;
}

export const KeyValueItem = ({
  keyName,
  valueName,
  dataCy,
}: KeyValueItemProps) => {
  return (
    <div
      className="d-flex justify-content-between"
      style={{ display: 'flex' }}
      data-cy={dataCy}
    >
      <div>{keyName}</div>
      <div>{valueName}</div>
    </div>
  );
};
