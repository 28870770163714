import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  link: string;
  children: any;
}

export default function TrWithLink({ link, children }: Props): ReactElement {
  const history = useHistory();
  return (
    <tr
      style={{ color: '#007bff', cursor: 'pointer' }}
      onClick={() => history.push(link)}
    >
      {children}
    </tr>
  );
}

export interface TdWithMenuProps {
  children: any;
  menuItems?: any[];
  text?: string;
}

// TODO in develop mode
export function TdWithMenu({ text, children }: TdWithMenuProps): ReactElement {
  return (
    <td
      style={{ color: '#007bff', cursor: 'pointer' }}
      id="dropdownMenu222"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {text}

      <div className="dropdown-menu" aria-labelledby="dropdownMenu222">
        {children}
      </div>
    </td>
  );
}

export interface TdWithLinkProps {
  children: any;
  link: string;
}

export function TdWithLink({ link, children }: TdWithLinkProps): ReactElement {
  const history = useHistory();
  return (
    <td onClick={() => history.push(link)}>
      <div style={{ color: '#007bff', cursor: 'pointer' }}>{children}</div>
    </td>
  );
}
