import React, { Component } from 'react';
import AssetForm from '../AssetForm';
import { assetsApi } from '../../../../utils/assets-api';
import MessagesContext from '../../../../utils/MessagesContext';
import { AssetResponse } from '../../accountPage/helpers';
import { setGALocation } from '../../overview/utils';
import DashboardPage2 from '../../../../lib/page2/DashboardPage2';
import { StyledBreadcrumb, StyledPageNavBetween } from '../../../../lib/styled';
import { BreadcrumbItem } from '../../../../lib/breadcrumb/BreadcrumbItem';
import PageSection from '../../../../lib/dashboard-page/PageSection';

interface Props {
  history: any[];
}
interface State {
  asset?: AssetResponse;
  asset_id: number;
}

export default class EditAssetPage extends Component<Props, State> {
  static contextType = MessagesContext;
  constructor(props) {
    super(props);
    this.state = {
      asset_id: JSON.parse(props.match.params.id),
    };
    this.updateAsset = this.updateAsset.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    setGALocation();
    assetsApi.getAsset(this.state.asset_id).then((assetResponse) => {
      this.setState({ asset: assetResponse });
    });
  }

  onSubmit(assetRequest) {
    for (const key in assetRequest) {
      if (assetRequest[key] === '') {
        this.context.setmessage('info', `${key} missing`);
        return;
      }
    }

    this.updateAsset(assetRequest);
  }

  updateAsset(assetRequest) {
    assetsApi
      .updateAsset(this.state.asset?.id, assetRequest)
      .then((assetResponse) => {
        this.context.setmessage(
          'success',
          `${assetResponse.name} asset updated successfully`
        );
        this.props.history.push(`/asset/${this.state.asset_id}`);
      });
  }

  render() {
    return (
      <DashboardPage2 hideTitle>
        <StyledPageNavBetween>
          <StyledBreadcrumb>
            <BreadcrumbItem url={'/assets'} title={'assets'} />
            <BreadcrumbItem
              url={`/asset/${this.state.asset_id}`}
              title={this.state.asset?.name || ''}
            />
            <BreadcrumbItem active title={'edit'} />
          </StyledBreadcrumb>
        </StyledPageNavBetween>
        <PageSection>
          {this.state.asset ? (
            <AssetForm onSubmit={this.onSubmit} asset={this.state.asset} />
          ) : null}
        </PageSection>
      </DashboardPage2>
    );
  }
}
